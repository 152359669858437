import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { EJBoardGameComponent } from "components/ej-board-game-component";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { gameLayers } from "game-layers";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoInitState } from "slingo/states/slingo-init-state";
import { RopeLinesComponentFactory } from "slotworks/components/factories/rope-lines-factory";
import { slotDefinition } from "slotworks/model/slot-definition";

export class EJInitState extends SlingoInitState {
    public onEnter(): void {
        gameLayers.BetBar.onSceneEnter.add(() => {
            const timeBg = gameLayers.BetBar.getSprite("bg_time");
            if (timeBg) {
                timeBg.visible = GamingRealms.wrapperConfig.getOperatorConfig().isClockEnabled();
            }

            const gameIdBg = gameLayers.BetBar.getSprite("bg_gameid");
            if (gameIdBg) {
                gameIdBg.visible = Boolean(slingoModel.read().gameInstanceId) && GamingRealms.wrapperConfig.getOperatorConfig().isClockEnabled();
            }
        });

        slingoModel.onUpdate.add(() => {
            const gameIdBg = gameLayers.BetBar.getSprite("bg_gameid");
            if (gameIdBg) {
                gameIdBg.visible = Boolean(slingoModel.read().gameInstanceId) && GamingRealms.wrapperConfig.getOperatorConfig().isClockEnabled();
            }
        });

        super.onEnter();
    }

    public onExit(): void {
        super.onExit();

        this.setupSpineBackgrounds();

        Layers.onEnterScene.add((layerId: string, sceneId: string) => this.applyTextPaddingFixes(layerId, sceneId));
        Layers.getAllLayers().forEach((layer) => this.applyTextPaddingFixes(layer.id, layer.getCurrentScene().name));

        // Do this here because we're sent the lines by the server in the init response
        Components.register(new RopeLinesComponentFactory(slotDefinition.lines.values, { centerOnEachSymbol: true }), null);

        if (!gameState.getCurrentGame().isRecovered) {
            Components.get(EJBoardGameComponent).setDefaultPrizePositions();
            Components.get(EJBoardGameComponent).moveJokerToPosition(0, 0).execute();
            Components.get(EJUICounterComponent).updateSlotSpinsCounter(0);
        }
    }

    protected applyTextPaddingFixes(layerId: string, sceneId: string) {
        Timer.setTimeout(() => {
            const layer = Layers.get(layerId);
            const contents = gameLayers[layerId].scene[sceneId]?.contents || {};
            for (const key in contents) {
                if (key) {
                    const text = layer.getText(key);
                    if (text) { text.style.padding = 20; }
                }
            }
        }, 0);
    }

    protected setupSpineBackgrounds() {
        gameLayers.Background.setScene("main").then(() => {
            gameLayers.Background.getSpine("background_landscape").play();
            gameLayers.Background.getSpine("background_portrait").play();
        });
    }

    protected showOnBoard(): Contract<any> {
        gameLayers.OnboardPages.onSceneEnter.add((scene: string) => {
            if (scene === "page_4") {
                const translations = Services.get(TranslationsService);
                const currency = Services.get(CurrencyService);

                const spinTxt = gameLayers.OnboardPages.getText("txt_spin");
                spinTxt.text = translations.get("slingo_buy_spin", { value: currency.format(46) });

                const collectTxt = gameLayers.OnboardPages.getText("txt_collect");
                collectTxt.text = translations.get("slingo_collect", { value: currency.format(60) });

                const totalWinValue = gameLayers.OnboardPages.getText("total_win_value");
                totalWinValue.text = currency.format(60);
            }
        });

        return super.showOnBoard();
    }
}
