import { Sprite } from "appworks/graphics/pixi/sprite";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Easing } from "appworks/utils/tween";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolSubcomponent } from "../symbol-subcomponent";

export class ZoomSymbolBehaviour extends AbstractSymbolBehaviour {

    public behaviourName: string = "zoom";

    protected sprite: Sprite;
    protected cancelGroup = new CancelGroup();
    protected originalScaleLandscape: number;
    protected originalScalePortrait: number;

    protected contract: Contract<void>;

    constructor(
        symbol: SymbolSubcomponent,
        protected pauseDuration: number = 500,
        protected animationDuration: number = 800,
        protected useHighlight: boolean = false,
        protected zoomAmount: number = 1.4,
        protected resetTo: number = 1
    ) {
        super(symbol);
    }

    public static() {
        super.static();
        this.symbol.setVisible(true);

        if (this.contract) {
            this.contract.cancel();
        }

        if (this.sprite) {
            this.sprite.destroy();
            this.sprite = null;
        }
    }

    public win(): Contract<void> {
        this.symbol.setVisible(false);
        this.sprite = this.createStatic();

        this.contract = new Sequence([
            () => this.zoomTo(this.zoomAmount),
            () => Contract.getTimeoutContract(this.pauseDuration),
            () => this.zoomTo(this.resetTo)
        ]);

        return this.contract;
    }

    public highlight(): Contract<void> {
        return (this.useHighlight) ? this.win() : super.highlight();
    }

    protected zoomTo(targetScale: number) {
        return new Contract<void>((resolve) => {
            const targetLandscape = targetScale * this.originalScaleLandscape;
            const targetPortrait = targetScale * this.originalScalePortrait;

            const tweenLandscape = this.cancelGroup.tween(this.sprite.landscape.scale);
            tweenLandscape.to({ x: targetLandscape, y: targetLandscape }, this.animationDuration);
            tweenLandscape.easing(Easing.Sinusoidal.Out);
            tweenLandscape.onComplete(() => resolve(null));
            tweenLandscape.start();

            const tweenPortrait = this.cancelGroup.tween(this.sprite.portrait.scale);
            tweenPortrait.to({ x: targetPortrait, y: targetPortrait }, this.animationDuration);
            tweenPortrait.easing(Easing.Sinusoidal.Out);
            tweenPortrait.start();
        });
    }

    protected createStatic() {
        if (this.sprite) {
            this.sprite.destroy();
        }

        const sprite = this.symbol.createStaticCopy();
        this.animationLayer.add(sprite);

        this.originalScaleLandscape = sprite.landscape.scale.x;
        this.originalScalePortrait = sprite.portrait.scale.x;

        return sprite;
    }
}
