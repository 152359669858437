import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";

export class EJSlotBonusIdleState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        uiFlags.set(UIFlag.IDLE, true);
        uiFlags.set(UIFlag.SPINNING, false);
    }

    public onSkip(cascaded?: boolean): void {
        uiFlags.set(UIFlag.IDLE, false);
        this.skip();
    }
}
