import { Gameplay } from "appworks/model/gameplay/gameplay";
import { InitRequestPayload } from "appworks/model/gameplay/requests/init-request-payload";
import { Services } from "appworks/services/services";
import { ValueList } from "appworks/utils/value-list";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoPayoutConfig, SlingoResponse, SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecordBuilder } from "./slingo-record-builder";
import { slingoModel } from "slingo/model/slingo-model";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { SlingoRecoveryWinResult } from "slingo/model/results/slingo-recovery-win-result";
import { deepClone } from "appworks/utils/collection-utils";
import { JurisdictionService } from "slotworks/services/jurisdiction/jurisdiction-service";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { GMRStakeConfig } from "gaming-realms/integration/gmr-schema";
import { ClientModel } from "appworks/state-machine/ClientModel";

export class SlingoInitRecordBuilder extends SlingoRecordBuilder {
    public id: string = "SlingoInitRecordBuilder";

    public filter(request: InitRequestPayload, response: any): boolean {
        return request instanceof InitRequestPayload;
    }

    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        const responseData = response.data as SlingoResponse;
        gameplay.isRecovered = Boolean(responseData.gameProgress);

        // Set SlingoModel values
        slingoModel.write({
            gameConfig: responseData.config.gameConfig,
            payoutConfig: this.processPayoutConfig(responseData.config.payoutConfig),
            stakeConfig: this.processStakeConfig(responseData.config.stakeConfig, ClientModel.hasStakes())
        });

        // Set stake values
        const stakeConfig = slingoModel.read().stakeConfig;
        Services.get(SlotBetService).setDefaults(
            new ValueList<number>(
                stakeConfig.amounts,
                stakeConfig.amounts[stakeConfig.defaultIndex],
                stakeConfig.lastStake || stakeConfig.amounts[stakeConfig.defaultIndex]
            ),
            new ValueList<number>([1], 1, 1)
        );

        // Set balance
        const balance = this.processBalances(responseData.balance);
        gameplay.startBalance = balance;
        gameplay.balance = balance;

        // Set currency and language
        const locale = GamingRealms.wrapperConfig.getOperatorParameters().getLocale();
        const currencyCode = responseData.config.stakeConfig.currencyCode;
        const isSocial = currencyCode === "COINS"; // use credits mode if social
        Services.get(CurrencyService).configure(locale, currencyCode, "symbol", isSocial, 100);

        // Set min spin time
        const minSpinTime = responseData.config.gameConfig.minimumSpinDuration;
        if (minSpinTime) {
            Services.get(JurisdictionService).setMinimumSpinTimes([{
                minBet: 0,
                delayMs: minSpinTime * 1000
            }]);
        }

        // Build record
        const record = super.build(gameplay, request, response)[0];
        record.hasChildren = true;

        // Recovery
        if (gameplay.isRecovered) {
            record.wager = Math.round(responseData.gameProgress.totalStake * 100);

            const recoveryWinResult = this.getRecoveryWinResult(responseData, record);
            record.cashWon += recoveryWinResult.cashWon;
            record.results.push(recoveryWinResult);
        }

        return [record];
    }

    protected processPayoutConfig(payoutConfig?: SlingoPayoutConfig): SlingoPayoutConfig | undefined {
        if (payoutConfig == null) {
            return undefined;
        }

        const config = deepClone(payoutConfig);
        config.patternPayouts.forEach((amt, index) => config.patternPayouts[index] = amt * 100);
        return config;
    }

    protected processStakeConfig(stakeConfig: GMRStakeConfig, useSocialStakes: boolean): GMRStakeConfig {
        const config = deepClone(stakeConfig);
        config.maximumStake *= 100;
        config.maximumTotalStake *= 100;
        config.lastStake *= 100;
        config.amounts = useSocialStakes ? ClientModel.getStakeFromGameHub().map(stake => stake* 100) : config.amounts.map(stake => stake * 100);
        return config;
    }

    // Would be nice if this was in a builder, but it needs to run after all other results have
    // already been added to the record
    protected getRecoveryWinResult(responseData: SlingoResponse, record: SlingoRecord): SlingoRecoveryWinResult {
        const result = new SlingoRecoveryWinResult();
        result.cashWon = (responseData.gameProgress.totalWin * 100) - record.cashWon;

        const ladderResult = record.getFirstResultOfType(SlingoLadderResult);
        if (record.state === SlingoState.CHOOSE_CELL && ladderResult.increase) {
            /**
             * We only add win to ladder results at the end of the spin, i.e. on the last pick,
             * because of how the server responses send a cumulative value. However if recovering to the
             * middle of a set of picks, that amount will already be included in totalWin. We need to
             * work out what's been won during picks so far, and deduct it. I hate this so much.
             */
            const ladderPayouts = slingoModel.read().payoutConfig.patternPayouts.map((payout) => payout*100);
            const previousLadderWin = ladderPayouts[ladderResult.total - ladderResult.increase - 1] ?? 0;
            const currentLadderWin = ladderPayouts[ladderResult.total - 1];
            result.cashWon -= (currentLadderWin - previousLadderWin);
        }

        return result;
    }
}
