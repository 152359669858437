import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { EJSoundEvent } from "ej-sound-events";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SoundNames } from "sounds-enum";

export function EJSoundSetup(): void {
    const sound = Services.get(SoundService);

    // Music
    sound.registerPlay(EJSoundEvent.game_started, { id: SoundNames.LYJ_MUS_BaseGame_Loop, music: true, loop: true, volume: 0.8 });
    sound.registerStop(EJSoundEvent.game_ended, [
        { id: SoundNames.LYJ_MUS_BaseGame_Loop },
        { id: SoundNames.LYJ_MUS_SlotBonus_Loop }
    ]);
    sound.registerPlay(EJSoundEvent.enter_bonus_slot, { id: SoundNames.LYJ_MUS_SlotBonus_Loop, music: true, loop: true });
    sound.registerStop(EJSoundEvent.enter_bonus_slot, { id: SoundNames.LYJ_MUS_BaseGame_Loop });
    sound.registerPlay(EJSoundEvent.enter_bonus_wheel, { id: SoundNames.LYJ_MUS_BonusWheel_Loop, music: true, loop: true });
    sound.registerStop(EJSoundEvent.exit_bonus_wheel, { id: SoundNames.LYJ_MUS_BonusWheel_Loop });

    // UI
    sound.registerPlay(SoundEvent.default_button_press, { id: SoundNames.LYJ_SFX_BaseGame_UI_Click });
    sound.registerPlay(SoundEvent.winnings_countup, { id: SoundNames.LYJ_SFX_BaseGame_Countup_Loop, loop: true });
    sound.registerStop(SoundEvent.winnings_countup_end, { id: SoundNames.LYJ_SFX_BaseGame_Countup_Loop });
    sound.registerPlay(SoundEvent.winnings_countup_end, { id: SoundNames.LYJ_SFX_BaseGame_Countup_End });
    sound.registerPlay(SoundEvent.big_win_countup_start, { id: SoundNames.LYJ_SFX_BaseGame_Countup_Loop, loop: true });
    sound.registerStop(SoundEvent.big_win_countup_end, { id: SoundNames.LYJ_SFX_BaseGame_Countup_Loop });
    sound.registerPlay(SoundEvent.big_win_countup_end, { id: SoundNames.LYJ_SFX_BaseGame_Countup_End });

    // Base game matrix
    sound.registerPlayArgs(SoundEvent.reel_N_spin_start, ["0"], { id: SoundNames.LYJ_SFX_BaseGame_ReelSpin_Loop, loop: true, volume: .2 });
    sound.registerPlay(SoundEvent.reel_any_spin_end, { id: SoundNames.LYJ_SFX_BaseGame_ReelSpin_Land, volume: .5 });
    sound.registerStop(SoundEvent.reel_any_skip_end, { id: SoundNames.LYJ_SFX_BaseGame_ReelSpin_Loop });
    sound.registerStopArgs(SoundEvent.reel_N_spin_end, ["4"], { id: SoundNames.LYJ_SFX_BaseGame_ReelSpin_Loop });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["J"], { id: SoundNames.LYJ_SFX_BaseGame_WildLand });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["SJ"], { id: SoundNames.LYJ_SFX_BaseGame_WildLandSuper });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["FS"], { id: SoundNames.LYJ_SFX_BaseGame_FreeSpin_Land, singleton: true });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["FS"], { id: SoundNames.LYJ_SFX_BaseGame_FreeSpin_Collect });
    sound.registerPlay(EJSoundEvent.freespin_collect, { id: SoundNames.LYJ_SFX_BaseGame_FreeSpin_End, volume: 0.7 });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["CL"], { id: SoundNames.LYJ_SFX_BaseGame_BonusSymbol_Land, singleton: true });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["S"], { id: SoundNames.LYJ_SFX_BaseGame_BonusSymbol_Land, singleton: true });
    sound.registerPlay(SoundEvent.reel_any_anticipate_start, { id: SoundNames.LYJ_SFX_BaseGame_Anticipation_Loop, loop: true });
    sound.registerStop(SoundEvent.reel_any_anticipate_end, { id: SoundNames.LYJ_SFX_BaseGame_Anticipation_Loop });

    // Trail
    sound.registerPlay(EJSoundEvent.dice_roll, { id: SoundNames.LYJ_SFX_BaseGame_DiceRoll });
    sound.registerPlay(EJSoundEvent.trail_win, { id: SoundNames.LYJ_SFX_BaseGame_CashPrize_Win });
    sound.registerPlayArgs(EJSoundEvent.footstep_N, ["1"], { id: SoundNames.LYJ_SFX_BaseGame_Leprechaun_Footstep01 });
    sound.registerPlayArgs(EJSoundEvent.footstep_N, ["2"], { id: SoundNames.LYJ_SFX_BaseGame_Leprechaun_Footstep02 });
    sound.registerPlayArgs(EJSoundEvent.footstep_N, ["3"], { id: SoundNames.LYJ_SFX_BaseGame_Leprechaun_Footstep03 });
    sound.registerPlayArgs(EJSoundEvent.footstep_N, ["4"], { id: SoundNames.LYJ_SFX_BaseGame_Leprechaun_Footstep04 });

    // Celebrations
    sound.registerPlay(SlingoSoundEvent.slingo, { id: SoundNames.LYJ_SFX_BaseGame_Slingo_Celebration });
    sound.registerPlayArgs(SoundEvent.enter_scene_LAYER_SCENE, ["CelebrationContent", "bigwin"], { id: SoundNames.LYJ_SFX_BigWin_Win_Start });
    sound.registerPlayArgs(SoundEvent.enter_scene_LAYER_SCENE, ["CelebrationContent", "bigwin"], { id: SoundNames.LYJ_SFX_BigWin_Win_Loop, music: true, loop: true });
    sound.registerStopArgs(SoundEvent.exit_scene_LAYER_SCENE, ["CelebrationContent", "bigwin"], { id: SoundNames.LYJ_SFX_BigWin_Win_Loop });
    sound.registerPlayArgs(SoundEvent.exit_scene_LAYER_SCENE, ["CelebrationContent", "bigwin"], { id: SoundNames.LYJ_SFX_BigWin_Terminator });

    // Slingo
    sound.registerPlay(SlingoSoundEvent.dab, { id: SoundNames.LYJ_SFX_BaseGame_NumberDab, singleton: true });
    sound.registerPlay(SlingoSoundEvent.attract_popup, { id: SoundNames.LYJ_SFX_BaseGame_Attract_Popup, volume: .6 });
    sound.registerPlay(SlingoSoundEvent.end_game_win, { id: SoundNames.LYJ_SFX_BaseGame_EndGame_Win });
    sound.registerPlay(SlingoSoundEvent.end_game_no_win, { id: SoundNames.LYJ_SFX_BaseGame_EndGame_NoWin });

    // Bonus Slot
    sound.registerPlay(SoundEvent.highlight_all_wins, { id: SoundNames.LYJ_SFX_SlotBonus_WinLines });
    sound.registerPlay(SoundEvent.symbol_any_win, { id: SoundNames.LYJ_SFX_SlotBonus_WinLine, volume: .5 });
    sound.registerPlay(EJSoundEvent.expand_wild, { id: SoundNames.LYJ_SFX_SlotBonus_Wild_Appear });

    // Bonus Wheel
    sound.registerPlay(EJSoundEvent.bonus_wheel_spin, { id: SoundNames.LYJ_SFX_BonusWheel_Spin });
    sound.registerPlay(EJSoundEvent.bonus_wheel_prize_win_start, { id: SoundNames.LYJ_SFX_BaseGame_EndGame_Win });
    sound.registerPlay(EJSoundEvent.bonus_wheel_prize_win, { id: SoundNames.LYJ_SFX_BonusWheel_Pulse, volume: .3 });
}
