import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { RequestPayload } from "appworks/model/gameplay/requests/request-payload";
import { builderManager } from "appworks/server/builders/builder-manager";
import { SlotReels, SlotResponse } from "slot/integration/slot-schema";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { HistoryRequestPayload } from "slotworks/model/gameplay/requests/history-request-payload";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { GMRRecordBuilder } from "../../../../gaming-realms/integration/builders/records/gmr-record-builder";
import { GamingRealms } from "gaming-realms/gaming-realms";

export class SlotSpinRecordBuilder extends GMRRecordBuilder {
    public id: string = "SlotSpinRecordBuilder";

    public filter(request: RequestPayload, response: any): boolean {
        return (request instanceof SpinRequestPayload || request instanceof HistoryRequestPayload) && response;
    }

    public build(gameplay: Gameplay, request: any, response: any): SpinRecord[] {
        super.build(gameplay, request, response);

        const responseData = response.data as SlotResponse;

        // Reduce start balance by stake if it hasn't been auto deducted
        if (!gameState.autoDeductStake && !GamingRealms.isHistory()) {
            gameState.getCurrentGame().startBalance -= responseData.gameProgress.totalStake * 100;
            Services.get(TransactionService).setBalance(gameState.getCurrentGame().startBalance);
        }

        // Update balance
        if (responseData.balance) {
            const balance = this.processBalances(responseData.balance);
            gameState.getCurrentGame().balance = balance;
        }

        const spinData = responseData.spins[0];
        const grid = this.getGrid(spinData.reels);
        const record = new SpinRecord();
        record.lastChild = true;
        record.hasChildren = false;
        record.cashWon = spinData.totalWin * 100;
        record.reelset = slotDefinition.reelsets.get(spinData.reelSet);
        record.grid = grid;
        record.wager = Services.get(SlotBetService).getTotalStake();

        if (response.data.gameProgress?.completionDate) {
            record.completionDate = response.data.gameProgress?.completionDate;
        }

        const resultData = [];
        const resultTypes = ["sequence", "scatter", "line", "adjacent"];
        if (spinData.payout) {
            resultTypes.forEach((type) => {
                if (spinData.payout[type]) {
                    resultData.push(...spinData.payout[type].map((v) => { return { data: v, type } }));
                }
            });
        }

        if (spinData.feature) {
            for (const feature in spinData.feature) {
                const featureData = spinData.feature[feature];
                resultData.push({ data: featureData, type: feature });
            }
        }

        resultData.forEach((payout) => {
            record.results.push(...builderManager.buildResults(record, payout.data, payout.type));
        });

        if (record.isBonusTriggered()) {
            record.hasChildren = true;
        }

        return [record];
    }

    // TODO: Promote and reuse
    protected getGrid(reels: any): string[][] {
        if (reels[0].symbols) {
            // Object array ({symbol:number}[])
            return reels.map((reel) => reel.symbols.map((symbol) => slotDefinition.symbolDefinitions[symbol].id));
        } else {
            // Flat array (number[])
            return reels.map((reel) => reel.map((symbol) => slotDefinition.symbolDefinitions[symbol].id));
        }
    }
}
