import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { EJSoundEvent } from "ej-sound-events";
import { gameLayers } from "game-layers";

export class EJEnterWheelBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        new Sequence([
            () => Contract.getTimeoutContract(1500),
            () => gameLayers.ScreenFade.setScene("black"),
            () => gameLayers.WheelBonus.setScene("wheelbonus"),
            () => Contract.wrap(() => {
                uiFlags.set(UIFlag.BONUS, true);
                Layers.setLayerOrder("wheelbonus");
                Components.get(EJUICounterComponent).updateWheelSpinsCounter();
                Services.get(SoundService).customEvent(EJSoundEvent.enter_bonus_wheel);
            }),
            () => gameLayers.ScreenFade.defaultScene()
        ]).then(() => {
            this.complete();
        });
    }
}
