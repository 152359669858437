import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { EJBoardGameComponent } from "components/ej-board-game-component";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { EJSoundEvent } from "ej-sound-events";
import { winSanity } from "ej-utils";
import { gameLayers } from "game-layers";
import { SlingoCompletionReason } from "slingo/integration/slingo-schema";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoGameOverState } from "slingo/states/slingo-game-over-state";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export class EJGameOverState extends SlingoGameOverState {
    public onEnter(cascadeSkip?: boolean): void {
        Services.get(SoundService).customEvent(EJSoundEvent.game_ended);

        winSanity();
        super.onEnter();
    }

    public onExit(): void {
        super.onExit();
        Components.get(EJBoardGameComponent).resetJoker().execute();
        Components.get(EJUICounterComponent).updateSlotSpinsCounter(0);
    }

    protected showWinSummary(): Contract<void> {
        return new Parallel([
            () => super.showWinSummary(),
            () => Contract.wrap(() => {
                const gameplay = gameState.getCurrentGame();
                const totalWin = gameplay.getTotalWin();

                const isBigWin = Components.get(BigWinComponent).isBigWin(totalWin, Services.get(SlotBetService).getTotalStake());
                const isFullHouse = gameplay.getLatestResultOfType(SlingoGameProgressResult).completionReason === SlingoCompletionReason.TICKET_MATCHED;

                gameLayers.Prompts.getSprite("rainbow").visible = isBigWin;
                gameLayers.Prompts.getSprite("frame").visible = !isFullHouse;
                gameLayers.Prompts.getSprite("fh_frame").visible = isFullHouse;
            })
        ]);
    }
}
