import { AbstractComponent } from "appworks/components/abstract-component";
import { gameState } from "appworks/model/game-state";
import { flashWhite } from "ej-utils";
import { gameLayers } from "game-layers";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";

export class EJUICounterComponent extends AbstractComponent {
    public updateSlotSpinsCounter(value: number) {
        const text = gameLayers.BetBar.getText("slot_spins_value");
        const str = value.toString();
        if (text && text.text !== str) {
            text.text = str;
            flashWhite(text, 250).execute();
        }
    }

    public updateWheelSpinsCounter() {
        const results = gameState.getCurrentGame().getCurrentRecord().getResultsOfType(EJBonusWheelResult);
        const numPlayed = Math.max(1, results.filter((res) => res.played).length);
        const numTotal = results.length;

        const text = gameLayers.WheelBonus.getText("spins_remaining_value");
        text.text = numPlayed + " / " + numTotal;
    }
}
