import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Layers } from "appworks/graphics/layers/layers";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { builderManager } from "appworks/server/builders/builder-manager";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { FadeTransition } from "appworks/transitions/fade-transition";
import { SwipeTransition, SwipeTransitionDirection } from "appworks/transitions/swipe-transition";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { UI } from "appworks/ui/ui";
import { Timer } from "appworks/utils/timer";
import { EJBoardGameComponent } from "components/ej-board-game-component";
import { EJBonusWheelComponent } from "components/ej-bonus-wheel-component";
import { EJDiceComponent } from "components/ej-dice-component";
import { EJExpandingWildComponent } from "components/ej-expanding-wild-component";
import { EJFreespinSparkComponent } from "components/ej-freespin-spark-component";
import { EJLadderComponent } from "components/ej-ladder-component";
import { EJReelHighlightComponent } from "components/ej-reel-highlight-component";
import { EJTicketMatrixComponent } from "components/ej-ticket-matrix-component";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { EJReelMatrixFactory } from "components/factories/ej-reel-matrix-factory";
import { gameLayers, LayerNames } from "game-layers";
import { GMRCollectRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-collect-request-builder";
import { GMRForceRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-force-request-builder";
import { GMRInitRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-init-request-builder";
import { SlotLineWinResultBuilder } from "slot/integration/builders/results/slot-line-win-result-builder";
import { EJInitRecordBuilder } from "integration/builders/records/ej-init-record-builder";
import { EJBoardGameResultBuilder } from "integration/builders/results/ej-board-game-result-builder";
import { EJBonusWheelResultBuilder } from "integration/builders/results/ej-bonus-wheel-result-builder";
import { EJSlotBonusResultBuilder } from "integration/builders/results/ej-slot-bonus-result-builder";
import { SlingoCelebrationComponent } from "slingo/components/slingo-celebration-component";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { GMRPreloaderComponent } from "gaming-realms/components/gmr-preloader-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoCollectRecordBuilder } from "slingo/integration/builders/records/slingo-collect-record-builder";
import { SlingoPickRecordBuilder } from "slingo/integration/builders/records/slingo-pick-record-builder";
import { SlingoPurchaseEntryRecordBuilder } from "slingo/integration/builders/records/slingo-purchase-entry-record-builder";
import { SlingoSpinRecordBuilder } from "slingo/integration/builders/records/slingo-spin-record-builder";
import { SlingoPickRequestBuilder } from "slingo/integration/builders/requests/slingo-pick-request-builder";
import { SlingoPurchaseEntryRequestBuilder } from "slingo/integration/builders/requests/slingo-purchase-entry-request-builder";
import { SlingoSpinRequestBuilder } from "slingo/integration/builders/requests/slingo-spin-request-builder";
import { SlingoGameProgressResultBuilder } from "slingo/integration/builders/results/slingo-game-progress-result-builder";
import { SlingoJokerResultBuilder } from "slingo/integration/builders/results/slingo-joker-result-builder";
import { SlingoLadderResultBuilder } from "slingo/integration/builders/results/slingo-ladder-result-builder";
import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { SlingoSetup } from "slingo/setup/slingo-setup";
import { BetBarComponent } from "slotworks/components/betbar/bet-bar-component";
import { CountMode } from "slotworks/components/bigwin/big-win-component";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { BigWinFactory } from "slotworks/components/factories/big-win-factory";
import { GenericComponentFactory } from "slotworks/components/factories/generic-component-factory";
import { IntegrationDebugger } from "slotworks/debug/integration-debugger/integration-debugger";
import { DebugMenu } from "slotworks/debug/menu/debug-menu";
import { SimpleForceList } from "slotworks/debug/menu/simple-force-list";
import { ForceTab } from "slotworks/debug/menu/tabs/force-tab";
import { FreespinRequestPayload } from "slotworks/model/gameplay/requests/freespin-request-payload";
import { EJBigWinTransition } from "transitions/ej-big-win-transition";
import { EJGameDefinitionSetup } from "./ej-game-definition-setup";
import { EJLayerSetup } from "./ej-layer-setup";
import { EJSoundSetup } from "./ej-sound-setup";
import { EJStateSetup } from "./ej-state-setup";
import { SlotScatterWinResultBuilder } from "slot/integration/builders/results/slot-scatter-win-result-builder";
import { SocialGMRAlertComponent } from "appworks/state-machine/social/components/social-gmr-alert-component";

export class EJSetup extends SlingoSetup {
    public setupCanvas(): void {
        super.setupCanvas();
        Services.get(CanvasService).cssCanvasEnabled = true;
    }

    public setupI18N(): void {
        super.setupI18N();

        const translations = Services.get(TranslationsService);
        for (let i = 0; i <= 12; i++) {
            const text = `${i} ${translations.get((i === 1) ? "slingo" : "slingos")}`;
            translations.set(`${i}_slingo${i === 1 ? "" : "s"}`, text);
        }

        translations.setAlias("footer_slingo_celebration_1", "lj_footer_slingo_celebration_1");
        translations.setAlias("footer_slingo_celebration_2", "lj_footer_slingo_celebration_2");
        translations.setAlias("footer_slingo_celebration_3", "lj_footer_slingo_celebration_3");
        translations.setAlias("footer_slingo_celebration_4", "lj_footer_slingo_celebration_4");
    }

    public setupGameDefinition(): void {
        EJGameDefinitionSetup();
    }

    public setupComponents() {
        SpineContainer.AUTO_UPDATE_SPEED = true;

        // Components.register(new GenericComponentFactory(GMRPreloaderComponent), null);
        Components.register(new GenericComponentFactory(PromptComponent), 0);

        Components.register(new GenericComponentFactory(SocialGMRAlertComponent), 0);

        Components.register(new GenericComponentFactory(BetBarComponent));
        Components.register(new GenericComponentFactory(CelebrateWinComponent));
        Components.register(new BigWinFactory(CountMode.LINEAR, false, [
            {
                threshold: 10,
                countTime: 3000,
                waitTime: 2000,
                scene: "bigWin"
            }
        ]));

        Components.register(new GenericComponentFactory(SlingoFooterComponent));
        Components.register(new GenericComponentFactory(SlingoCelebrationComponent, LayerNames.CelebrationContent));
        Components.register(new GenericComponentFactory(SlingoSpinsCounterComponent, LayerNames.SpinCounter));
        Components.register(new EJReelMatrixFactory(), 3);

        Components.register(new GenericComponentFactory(EJTicketMatrixComponent), 3);
        Components.register(new GenericComponentFactory(EJReelHighlightComponent));
        Components.register(new GenericComponentFactory(EJFreespinSparkComponent));
        Components.register(new GenericComponentFactory(EJExpandingWildComponent));
        Components.register(new GenericComponentFactory(EJBoardGameComponent));
        Components.register(new GenericComponentFactory(EJDiceComponent, LayerNames.BoardGame));
        Components.register(new GenericComponentFactory(EJUICounterComponent));
        Components.register(new GenericComponentFactory(EJBonusWheelComponent));
        Components.register(new GenericComponentFactory(EJLadderComponent));
    }

    public setupStates() {
        EJStateSetup();
    }

    public setupLayers(): void {
        EJLayerSetup();
    }

    public setupUI(): void {
        UI.layer(Layers.get("MenuButton"), [
            {
                scene: "slotbonus",
                show: new UIFlagState(UIFlag.FREE_SPINS),
                enable: UIFlagState.IDLE
            }
        ]);

        UI.layer(Layers.get("SpinButton"), [
            {
                scene: "slotbonus",
                show: new UIFlagState(UIFlag.FREE_SPINS),
                enable: UIFlagState.IDLE
            }
        ]);

        UI.layer(Layers.get("BetBar"), [
            {
                scene: "slotbonus",
                show: new UIFlagState(UIFlag.FREE_SPINS),
                enable: UIFlagState.IDLE
            },
            {
                scene: "wheelbonus",
                show: new UIFlagState(UIFlag.BONUS),
                enable: UIFlagState.IDLE
            }
        ]);

        // hack to disable lobby and deposit buttons in slot bonus
        Layers.get("Menus").onSceneEnter.add((scene: string) => {
            if (scene === "settings" && uiFlags.has(UIFlag.FREE_SPINS)) {
                Timer.setTimeout(() => {
                    gameLayers.Menus.getButton("lobby").setEnabled(false);
                    gameLayers.Menus.getButton("deposit").setEnabled(false);
                }, 1);
            }
        });

        super.setupUI();
    }

    public setupComms(): void {
        super.setupComms();

        builderManager.addRequestBuilder(new GMRForceRequestBuilder());
        builderManager.addRequestBuilder(new GMRInitRequestBuilder());
        builderManager.addRequestBuilder(new SlingoPurchaseEntryRequestBuilder());
        builderManager.addRequestBuilder(new SlingoSpinRequestBuilder());
        builderManager.addRequestBuilder(new SlingoPickRequestBuilder());
        builderManager.addRequestBuilder(new GMRCollectRequestBuilder());

        builderManager.addRecordBuilder(new EJInitRecordBuilder());
        builderManager.addRecordBuilder(new SlingoPurchaseEntryRecordBuilder());
        builderManager.addRecordBuilder(new SlingoSpinRecordBuilder());
        builderManager.addRecordBuilder(new SlingoPickRecordBuilder());
        builderManager.addRecordBuilder(new SlingoCollectRecordBuilder());

        builderManager.addResultBuilder(new SlingoReelSpinResultBuilder());
        builderManager.addResultBuilder(new SlingoJokerResultBuilder());
        builderManager.addResultBuilder(new SlingoLadderResultBuilder());
        builderManager.addResultBuilder(new SlingoGameProgressResultBuilder());
        builderManager.addResultBuilder(new EJSlotBonusResultBuilder());
        builderManager.addResultBuilder(new EJBoardGameResultBuilder());
        builderManager.addResultBuilder(new EJBonusWheelResultBuilder());

        builderManager.addResultBuilder(new SlotLineWinResultBuilder());
        builderManager.addResultBuilder(new SlotScatterWinResultBuilder());
    }

    public setupTransitions(): void {
        gameLayers.ScreenFade.setTransition(new FadeTransition(gameLayers.ScreenFade, 500));

        gameLayers.CelebrationContent.setTransitions("bigwin", new EJBigWinTransition(gameLayers.CelebrationContent));

        gameLayers.Prompts.setTransitions("bonusmoney", new FadeTransition(gameLayers.Prompts, 250)); // all prompts other than reality check, as that pauses the game
        gameLayers.Prompts.setTransitions("gamesummary_nowin", new FadeTransition(gameLayers.Prompts, 250));
        gameLayers.Prompts.setTransitions("gamesummary_win", new FadeTransition(gameLayers.Prompts, 250));
        gameLayers.Prompts.setTransitions("yes_no", new FadeTransition(gameLayers.Prompts, 250));
        gameLayers.Prompts.setTransitions("info", new FadeTransition(gameLayers.Prompts, 250));

        // gameLayers.SlingoLadder.setTransitions("base", new FadeTransition(gameLayers.SlingoLadder, 125));
        // gameLayers.SlingoLadder.setTransitions("paytable", new SwipeTransition(gameLayers.SlingoLadder, 375, SwipeTransitionDirection.UP));

        gameLayers.CollectBonusValues.setTransition(new FadeTransition(gameLayers.CollectBonusValues, 250));
    }

    public setupDebug(): void {
        super.setupDebug();

        IntegrationDebugger.addRequest("Slot Bonus - Spin", FreespinRequestPayload);

        // Forces
        const forceList = new SimpleForceList();
        forceList.setResults(new Map<string, any>([
            ["Free Spin x1", [51, 0, 0, 0, 0]],
            ["Free Spin x2", [51, 52, 0, 0, 0]],
            ["Free Spin x3", [51, 52, 50, 0, 0]],
            ["Trigger Bonus Wheel", [0, 0, 49, 48, 48]],
            ["Trigger x15 Slot Bonus", [48, 48, 48, 0, 0]],
            ["Full Wilds", [46, 46, 46, 46, 46]],
            ["Full Super Wilds", [45, 45, 45, 45, 45]],
            ["Bonus Wheel - Low Tier x2 win", [
                0, 0, 49, 48, 48, // base reels
                0, // ?
                3, // x2
                0, 0, 0, 0
            ]],
            ["Bonus Wheel - Middle Tier x10 win", [
                0, 0, 49, 48, 48, // base reels
                0, // ?
                6, // wheel tier upgrade
                3, // x10
                0, 0, 0, 0
            ]],
            ["Bonus Wheel - Top Tier x250 win", [
                0, 0, 49, 48, 48, // base reels
                0, // ?
                6, 6, // two wheel tier upgrades
                4, // x250
                0, 0, 0, 0
            ]]
        ]));
        DebugMenu.add(new ForceTab("Forces", forceList));
    }

    public setupSound(): void {
        EJSoundSetup();
    }
}
