import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { unique } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { EJExpandingWildComponent } from "components/ej-expanding-wild-component";
import { reject } from "lodash";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { AbstractMatrixComponent, DimType } from "slotworks/components/matrix/abstract-matrix-component";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { LoopWinsState } from "slotworks/state-machine/standard/states/loop-wins-state";

export class EJSlotBonusLoopWinsState extends LoopWinsState {
    public onEnter(): void {
        uiFlags.set(UIFlag.IDLE, true);
        super.onEnter();
    }

    protected showWin(win: SymbolWinResult): Contract<void> {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SpinRecord;

        const winReels = unique(win.positions.map((pos) => pos.x));
        const wildReels = this.getWildReels();

        const winningSymbols = Components.get(AbstractMatrixComponent).getSymbolsFromPositions(win.positions).filter(
            (symbol) => !wildReels.find((reel) => reel === symbol.gridPosition.x)
        );

        const currentCycle = this.recordCycleCount.get(record);

        if (record.cashWon / Services.get(SlotBetService).getTotalStake() >= Components.get(CelebrateWinComponent).config.totalWinThreshold) {
            // TODO: Refactor sound logic into CelebrateWinComponent
            Components.get(AbstractMatrixComponent).playSymbolSound(win.symbolType, win.matches.toString(), currentCycle.toString());
        }

        const remainingSymbols = reject(Components.get(AbstractMatrixComponent).getBaseGridSymbols(), winningSymbols);

        if (this.config.dimSymbols) {
            const hiddenSymbols = Components.get(AbstractMatrixComponent).getHiddenSymbols();
            Components.get(AbstractMatrixComponent).dimSymbols(remainingSymbols.concat(hiddenSymbols), DimType.Cycle, win).execute();
        }

        if (!winningSymbols.length) {
            return Contract.getTimeoutContract(1000);
        }

        const contract = this.cancelGroup.parallel([
            () => Components.get(AbstractMatrixComponent).showWinningSymbols(winningSymbols, win, currentCycle > 0),
            () => Contract.getTimeoutContract(this.config.minWinTime),
            ...winReels.map((reelIndex) => () => Components.get(EJExpandingWildComponent).winAnim(reelIndex))
        ]);

        if (this.config.maxWinTime < Infinity) {
            Timer.setTimeout(() => {
                contract.forceResolve();
            }, this.config.maxWinTime);
        }

        return contract;
    }

    protected getWildReels(): number[] {
        const reels: number[] = [];

        Components.get(MatrixComponent).getBaseGridSymbols().forEach((sym) => {
            if (sym.symbolDefinition.wild && reels.indexOf(sym.gridPosition.x) === -1) {
                reels.push(sym.gridPosition.x);
            }
        });

        return reels;
    }
}
