import { Components } from "appworks/components/components";
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { EJExpandingWildComponent } from "components/ej-expanding-wild-component";
import { gameLayers } from "game-layers";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { FreespinRequestPayload } from "slotworks/model/gameplay/requests/freespin-request-payload";
import { SpinState } from "slotworks/state-machine/standard/states/spin-state";

export class EJSlotBonusSpinState extends SpinState {
    protected skipButton: ButtonElement;
    protected skipQueued: boolean;

    public onEnter(): void {
        uiFlags.set(UIFlag.IDLE, false);
        uiFlags.set(UIFlag.NO_SKIP, true);
        uiFlags.set(UIFlag.SPINNING, true);

        gameLayers.SpinButton.hide();
        gameLayers.Skip.setScene("skip").then(() => {
            this.skipButton = gameLayers.Skip.getButton("skip");
            this.skipButton.once(ButtonEvent.CLICK.getPIXIEventString(), () => this.skipButtonPressed());
        });
        this.skipQueued = false;

        Components.get(SlingoSpinsCounterComponent).decrement().execute();
        Components.get(EJExpandingWildComponent).hideAll(125).execute();

        Services.get(TransactionService).setWinnings(0).execute();

        new Parallel([
            () => this.makeFakeRequest(),
            () => Components.get(MatrixComponent).startTransition()
        ]).then(() => {
            super.onEnter();

            if (this.skipQueued) {
                Components.get(MatrixComponent).skipTransition(gameState.getCurrentGame().getLatestRecord() as SpinRecord);
            }
        });
    }

    public onExit(): void {
        super.onExit();

        gameLayers.SpinButton.show();
        gameLayers.Skip.defaultScene().execute();
    }

    protected makeFakeRequest(): Contract {
        return new Sequence([
            () => commsManager.request(new FreespinRequestPayload()),
            () => Contract.wrap(() => gameState.getCurrentGame().setToLatestRecord())
        ]);
    }

    protected skipButtonPressed() {
        this.skipButton.setEnabled(false);

        if (uiFlags.has(UIFlag.NO_SKIP)) {
            this.skipQueued = true;
        } else {
            Components.get(MatrixComponent).skipTransition(gameState.getCurrentGame().getLatestRecord() as SpinRecord);
        }
    }
}
