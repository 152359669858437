import { AdjustmentFilter } from "@pixi/filter-adjustment";
import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { unique } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { HexToRGB } from "appworks/utils/math/color";
import { Filter } from "pixi.js";
import { HideAllLinesCommand } from "slotworks/commands/lines/hide-all-lines-command";
import { SetAllLinesEffects } from "slotworks/commands/lines/set-all-lines-effects";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { LinesComponent } from "slotworks/components/lines/lines-component";
import { AbstractMatrixComponent, DimType } from "slotworks/components/matrix/abstract-matrix-component";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { BaseAnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/base-animate-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { FreespinRecord } from "slotworks/model/gameplay/records/freespin-record";
import { FreespinWinResult } from "slotworks/model/gameplay/records/results/freespin-win-result";
import { LineResult } from "slotworks/model/gameplay/records/results/line-result";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { AutoplayService } from "slotworks/services/autoplay/autoplay-service";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export interface ShowWinLinesStateConfig {
    skippable: boolean;
    highlightSymbols: boolean;
    multilineOnly: boolean;
    winTickTime: number;
    skipWinTickupInAutoplay: boolean;
    endDelay: number;
    bigWins: boolean;
    bigWinStartDelay: number;
    // Continuously play symbol highlight on a loop, relying on other things (win celebration etc) to dictate whe nto exit the state
    continuousHighlight: boolean;
    // Instead of waiting for all symbols on a line to animate, a fixed delay is used between each line
    perLineDelay: number;
    // Adds a delay between each symbol animating
    perSymbolDelay: number;
    // Delay to add to the end of all wins highlighting (if they highlight)
    minDisplayTime: number;
    // After this time expires, skip the state
    maxDisplayTime?: number;
    // Wins below stake don't tick up, but should be delayed so autoplay doesn't run too fast
    lowWinEndDelay: number;
    // If true, animations which are already playing will not start again if they are told to play again
    overlapAnimations: boolean;
    // Adds tints / filters to lines
    enableLineEffects: boolean;
    // Adds tints / filters to symbol behaviours
    enableBehaviourEffects: boolean;
    applyEffectsToSymbolBehaviours: string[];
    defaultTintColor: number;
    lineColors: number[];
    resetSymbols: boolean;
    // If true, will always show totalwin celebration even if the won amount is less than the total bet
    showTotalWinBelowStake: boolean;
    // If true will run the win sequence in parallel, otherwise it will run in sequence.
    runWinSequenceInParallel: boolean;
    // Show the total win display in freespins as well as the base game.
    showTotalWinInFreespins: boolean;
    // If false, will not show lines that have cashWon <= 0 (i.e. line wins awarding respins)
    checkCashWonAboveZero: boolean;
    // Delay before completing after a big win
    bigWinCompleteDelay: number;
    /** Change this if using this state with a secondary matrix component */
    matrixComponentType: { new (...args: any[]): AbstractMatrixComponent };
}

export class ShowWinLinesState extends State {
    protected config: ShowWinLinesStateConfig = {
        skippable: true,
        highlightSymbols: true,
        multilineOnly: false,
        winTickTime: 750,
        skipWinTickupInAutoplay: false,
        lowWinEndDelay: 750,
        endDelay: 0,
        bigWins: false,
        bigWinStartDelay: 0,
        continuousHighlight: false,
        perLineDelay: 0,
        perSymbolDelay: 0,
        minDisplayTime: 0,
        maxDisplayTime: undefined,
        overlapAnimations: false,
        enableLineEffects: false,
        enableBehaviourEffects: false,
        applyEffectsToSymbolBehaviours: [
            "AnimateOver",
            "ColorFrame"
        ],
        defaultTintColor: 0xffffff,
        lineColors: [],
        resetSymbols: true,
        showTotalWinBelowStake: false,
        runWinSequenceInParallel: true,
        showTotalWinInFreespins: true,
        checkCashWonAboveZero: true,
        bigWinCompleteDelay: 0,
        matrixComponentType: AbstractMatrixComponent
    };

    protected contract: Contract<void>;

    protected bigWinPending: boolean;

    protected animatingBehaviours: AbstractSymbolBehaviour[] = [];

    constructor(config?: Partial<ShowWinLinesStateConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter() {
        if (this.config.maxDisplayTime !== undefined) {
            this.cancelGroup.timeoutContract(this.config.maxDisplayTime, () => Contract.wrap(() => {
                if (this.stateMachine.currentState.concrete === this) {
                    this.stateMachine.skip();
                }
            })).execute();
        }

        // Instances the highlight is skipped
        if (this.hasOneLine() && this.onlyShowOnMultipleWinLines()) {
            this.tickUpTotalWin(0);
            this.complete();
            return;
        }

        //  Complete win sequence
        this.contract = new Sequence<void>([
            () => this.winSequence(),
            () => Contract.getTimeoutContract(this.config.endDelay),
            () => Contract.wrap(() => {
                this.onDisplayComplete();
                this.complete();
            })
        ]);

        this.contract.execute();
    }

    public onSkip() {
        if (this.config.skippable) {
            if (!this.bigWinPending) {
                this.contract.cancel();
                this.onDisplayComplete();
                this.skip();
            }
        }
    }

    public onExit() {
        if (this.config.resetSymbols) {
            Components.get(this.config.matrixComponentType).resetSymbols();
        }
    }

    // The main win sequence, by default win value celebration and highlight (if enabled) happen in parallel
    protected winSequence(): Contract<void> {
        const raceFuncs: Array<() => Contract<any>> = [];

        // Win value celebration (big win, celeberateWin)
        raceFuncs.push(() => new Sequence([
            () => this.winValueCelebration(),
            () => Contract.wrap(() => this.tickUpTotalWin(this.config.winTickTime))
        ]));

        // Highlight symbols
        if (this.config.highlightSymbols) {
            raceFuncs.push(() => this.highlightWins());
        }

        const contracts: Array<() => Contract<void>> = [];

        if (this.config.runWinSequenceInParallel) {
            contracts.push(() => new Parallel<void>(raceFuncs));
        } else {
            contracts.push(() => new Sequence<void>(raceFuncs));
        }

        if (this.bigWinTriggered()) {
            contracts.push(() => Contract.getTimeoutContract(this.config.bigWinCompleteDelay));
        }

        return new Sequence<void>(contracts);
    }

    protected winValueCelebration() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.results;

        const win = this.getResultWin();

        if (this.bigWinTriggered()) {
            return this.bigWin();
        } else if (win > 0 && (this.config.showTotalWinBelowStake === false && win < Services.get(SlotBetService).getTotalStake())) {
            // Don't highlight wins < stake
            this.tickUpTotalWin(0);
            return Contract.getTimeoutContract(this.config.lowWinEndDelay);
        } else if (this.allWinsAreSameSymbol() && this.isAutospinsOrFreespins()) {
            const result = results[0] as LineResult;
            if (result) {
                if (uiFlags.has(UIFlag.FREE_SPINS) && !this.config.showTotalWinInFreespins) {
                    this.tickUpTotalWin(0);
                    return Contract.getTimeoutContract(this.config.lowWinEndDelay);
                } else {
                    return Components.get(CelebrateWinComponent).singleTotalWin(win, result);
                }
            } else {
                return Contract.empty();
            }
        } else if (uiFlags.has(UIFlag.FREE_SPINS) && !this.config.showTotalWinInFreespins) {
            this.tickUpTotalWin(0);
            return Contract.getTimeoutContract(this.config.lowWinEndDelay);
        } else {
            return Components.get(CelebrateWinComponent).totalWin(win);
        }
    }

    protected bigWinTriggered() {
        return this.config.bigWins && Components.get(BigWinComponent).isBigWin(this.getResultWin(), Services.get(SlotBetService).getTotalStake());
    }

    protected highlightWins(): Contract<void> {

        this.animatingBehaviours = [];

        const record = gameState.getCurrentGame().getCurrentRecord();

        const winQueue: Array<() => Contract<void>> = [];

        const colors = [...this.config.lineColors];

        const tweenColors = [];

        for (const result of this.getWinsToShow(record)) {
            const color = colors.shift();
            tweenColors.push(color);

            winQueue.push(() => this.highlightWin(result, color));
        }

        let lineAnimations: Array<() => Contract<void>> = [];

        if (winQueue.length) {
            lineAnimations = [
                () => Contract.wrap(() => {
                    if (record.cashWon / Services.get(SlotBetService).getTotalStake() >= Components.get(CelebrateWinComponent).config.totalWinThreshold) {
                        // TODO: Refactor sound logic into CelebrateWinComponent
                        this.playSymbolHighlightSounds();
                    }
                }),
                () => this.dimSymbols(),
                ...winQueue,
                () => Contract.getTimeoutContract(this.config.minDisplayTime)
            ];
        }

        let lineSequence: Contract<void>;
        if (this.config.perLineDelay) {
            lineSequence = new Sequence<void>(lineAnimations);
        } else {
            lineSequence = new Parallel<void>(lineAnimations);
        }

        if (this.config.continuousHighlight) {
            lineSequence.execute();
            return Contract.empty();
        }

        return lineSequence;
    }

    protected getWinsToShow(record: Record): SymbolWinResult[] {
        return record.getResultsOfType(SymbolWinResult).filter((result) => {
            const cashWonValid = result.cashWon > 0 || !this.config.checkCashWonAboveZero;
            return (cashWonValid || result.linkedResult instanceof FreespinWinResult);
        });
    }

    protected dimSymbols() {
        return Components.get(this.config.matrixComponentType).dimSymbols(Components.get(this.config.matrixComponentType).getAllSymbols(), DimType.Highlight);
    }

    protected highlightWin(result: SymbolWinResult, color: number) {
        const symbolsToAnimate = this.setupSymbolsToAnimate(result);

        Services.get(SoundService).event(SoundEvent.N_of_a_kind, result.ofAKind().toString());

        // Show line
        if (result instanceof LineResult) {
            const reverse = result.positions[0].x !== 0;
            Components.get(LinesComponent)?.highlight(result.line, reverse);

            if (!this.bigWinPending) {
                Services.get(SoundService).event(SoundEvent.win_line_N, result.line.toString());
            }
        }

        // Tint and filter effects
        const filters: Filter[] = this.getFilterEffects(color);
        if (this.config.enableLineEffects) {
            if (result instanceof LineResult) {
                if (filters.length) {
                    Components.get(LinesComponent)?.filter(result.line, filters);
                } else {
                    Components.get(LinesComponent)?.tint(result.line, color);
                }
            }
        }
        if (this.config.enableBehaviourEffects) {
            Components.get(this.config.matrixComponentType).setSymbolEffects(symbolsToAnimate, color, filters, this.config.applyEffectsToSymbolBehaviours);
        }

        const highlightSymbols = Components.get(this.config.matrixComponentType).highlightSymbols(symbolsToAnimate, result, !this.config.continuousHighlight);

        if (this.config.perLineDelay) {
            highlightSymbols.execute();
            return Contract.getTimeoutContract(this.config.perLineDelay);
        }

        return highlightSymbols;
    }

    // Get correct symbols to animate, already setup with delays etc
    protected setupSymbolsToAnimate(result: SymbolWinResult) {
        const symbols = Components.get(this.config.matrixComponentType).getSymbolsFromPositions(result.positions);
        const symbolsToAnimate: SymbolSubcomponent[] = [];

        let delay = 0;

        symbols.forEach((symbol) => {

            let shouldAnimate = false;

            if (this.config.overlapAnimations || this.noDelays()) {
                if (this.animatingBehaviours.indexOf(symbol.getFirstBehaviour()) === -1) {
                    shouldAnimate = true;
                }
            } else {
                shouldAnimate = !this.isSymbolAlreadyAnimating(symbol);
            }

            if (shouldAnimate) {
                symbolsToAnimate.push(symbol);
                this.setAnimationBehaviourDelay(symbol, delay);
            }

            delay += this.config.perSymbolDelay;
        });

        this.animatingBehaviours.push(...symbolsToAnimate.map((symbol) => symbol.getFirstBehaviour()));

        return symbolsToAnimate;
    }

    protected playSymbolHighlightSounds() {
        const winningSymbols = Components.get(this.config.matrixComponentType).getWinningSymbols(gameState.getCurrentGame().getCurrentRecord().results);
        const winningSymbolIDs = winningSymbols.map((symbol) => symbol.symbolId);
        const uniqueWinningSymbolIDs = unique(winningSymbolIDs);

        Services.get(SoundService).event(SoundEvent.highlight_all_wins);
        for (const symbolID of uniqueWinningSymbolIDs) {
            Services.get(SoundService).event(SoundEvent.symbol_ID_highlight, symbolID);
            let count = 0;
            for (const symbolToCount of winningSymbolIDs) {
                if (symbolID === symbolToCount) {
                    count++;
                }
            }
            Services.get(SoundService).event(SoundEvent.symbol_ID_MATCHES_highlight, symbolID, count.toString());
            Services.get(SoundService).event(SoundEvent.symbol_any_MATCHES_highlight, count.toString());
        }
    }

    protected onDisplayComplete() {

        if (this.config.enableBehaviourEffects) {
            const tintColor = this.config.defaultTintColor;
            Components.get(this.config.matrixComponentType).setAllSymbolEffects(tintColor, this.getFilterEffects(tintColor), this.config.applyEffectsToSymbolBehaviours);
        }
        if (this.config.enableLineEffects) {
            SetAllLinesEffects(this.config.defaultTintColor, this.getFilterEffects(this.config.defaultTintColor));
        }

        if (this.config.resetSymbols) {
            Components.get(this.config.matrixComponentType).resetSymbols();
        }
        HideAllLinesCommand().execute();
        Components.get(CelebrateWinComponent).hide();
        this.tickUpTotalWin(0);
    }

    protected tickUpTotalWin(time: number) {
        if (this.config.skipWinTickupInAutoplay && Services.get(AutoplayService).isAutoPlaying()) {
            time = 0;
        }

        const totalWin = this.getTotalWin();
        Services.get(TransactionService).setWinnings(this.getResultWin(), totalWin, time).execute();
    }

    protected allWinsAreSameSymbol() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const symbolTypes: string[] = [];
        for (const result of record.results) {
            if (result instanceof LineResult) {
                symbolTypes.push(result.symbolType);
            }
        }

        return symbolTypes.every((v) => v === symbolTypes[0]);
    }

    protected hasOneLine() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.results;
        const lines = [];

        for (const result of results) {
            if (result instanceof LineResult) {
                lines.push(result.line);
            }
        }

        return lines.length <= 1;
    }

    protected isAutospinsOrFreespins() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        return (record instanceof FreespinRecord) || Services.get(AutoplayService).isAutoPlaying();
    }

    protected onlyShowOnMultipleWinLines() {
        return !this.isAutospinsOrFreespins() && this.config.multilineOnly;
    }

    protected bigWin(): Contract<boolean> {

        const gameplay = gameState.getCurrentGame();

        let winnings: number;
        if (gameplay.isCurrentRecordRoot()) {
            winnings = gameState.getCurrentGame().getTotalWin();
        } else {
            winnings = this.getResultWin();
        }
        const stake = Services.get(SlotBetService).getTotalStake();

        uiFlags.set(UIFlag.NO_SKIP, true);

        this.bigWinPending = true;

        return new Sequence([
            () => Contract.getTimeoutContract(this.config.bigWinStartDelay),
            () => new Contract<boolean>((resolve) => {
                Components.get(BigWinComponent).showWin(winnings, stake).then((result) => {
                    this.bigWinPending = false;
                    uiFlags.set(UIFlag.NO_SKIP, false);
                    resolve(result);
                });
            })
        ]);
    }

    protected getFilterEffects(color: number): Filter[] {
        try {
            const rgb = HexToRGB(color);
            const max = 2;
            const filters = [new AdjustmentFilter({
                red: rgb.r / 255 * max,
                green: rgb.g / 255 * max,
                blue: rgb.b / 255 * max,
                saturation: 3
            })];

            return filters;
        } catch (e) {
            return [];
        }
    }

    protected setAnimationBehaviourDelay(symbol: SymbolSubcomponent, delay: number) {
        symbol.getBehavioursOfType(BaseAnimateSymbolBehaviour).forEach((behaviour) => {
            behaviour.delay = delay;
        });
    }

    protected isSymbolAlreadyAnimating(symbol: SymbolSubcomponent) {
        let alreadyAnimating = false;
        symbol.getBehavioursOfType(BaseAnimateSymbolBehaviour).forEach((behaviour) => {
            if (behaviour.isAnimating()) {
                alreadyAnimating = true;
            }
        });

        return alreadyAnimating;
    }

    protected getResultWin() {
        return gameState.getCurrentGame().getCurrentRecord().cashWon;
    }

    protected getTotalWin() {
        return gameState.getCurrentGame().getCurrentTotalWin();
    }

    protected noDelays() {
        return this.config.perSymbolDelay === 0 && this.config.perLineDelay === 0;
    }
}
