import { Components } from "appworks/components/components";
import { Contract } from "appworks/utils/contracts/contract";
import { LinesComponent } from "slotworks/components/lines/lines-component";

/** @deprecated Move to class function */
export function ShowLinesCommand(lines: number | number[]): Contract<void> {

    if (typeof lines === "number") {
        const linesArr = [];

        for (let i = 1; i <= lines; i++) {
            linesArr.push(i);
        }

        lines = linesArr;
    }

    for (const line of lines) {
        Components.get(LinesComponent)?.win(line);
    }

    return Contract.empty();
}
