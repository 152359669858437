import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { EJSlotBonusResult } from "model/results/ej-slot-bonus-result";

export class EJSlotBonusResultBuilder implements ResultBuilder {
    public filter(data: any, record?: Record, additionalData?: any): boolean {
        return data.slotsGame;
    }

    public build(record: Record, data: any, additionalData?: any): Result[] {
        record.hasChildren = true;

        const result = new EJSlotBonusResult("slot");
        result.data = data.slotsGame;

        result.data.stakePerLine *= 100;

        return [result];
    }
}
