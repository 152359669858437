import { logger } from "appworks/utils/logger";
import { Service } from "../service";

export class CurrencyService extends Service {

    public symbolDisplay: "symbol" | "code";
    public credits: boolean = false;
    public creditRatio: number = 1;

    protected currency: string = "GBP";
    protected locale: string = "en";
    protected decimalSeparator: string;
    protected useGrouping: boolean;

    protected configured: boolean;

    public init(): void {
        //
    }

    /**
     * Shortcut for configuring all currency options
     *
     * @param locale {string}
     * @param currency {string}
     * @param symbolDisplay {"symbol"|"code"}
     * @param credits {boolean}
     * @param creditRatio {number}
     */
    public configure(locale: string, currency: string, symbolDisplay: "symbol" | "code", credits: boolean = null, creditRatio: number = null, useGrouping: boolean = true) {
        if (this.configured) {
            logger.warn("WARNING: Services.get(CurrencyService).configure is being called twice");
        }
        this.configured = true;

        this.locale = locale;
        this.currency = currency;
        this.symbolDisplay = symbolDisplay;
        if (credits !== null) {
            this.credits = credits;
        }
        if (creditRatio) {
            this.creditRatio = creditRatio;
        }

        const n = 1.1;
        this.decimalSeparator = n.toLocaleString(this.locale).substring(1, 2);
        this.useGrouping = useGrouping;
    }

    /**
     * Formats a number to the currency as configured
     *
     * @param value {number}
     * @param trailingDecimals {boolean}
     * @param forceCreditMode {boolean}     Specify true to format as credits or false to format as currency, regardless of current configuration
     * @return {string}
     */
    public format(value: number, trailingDecimals: boolean = true, forceCreditMode?: boolean): string {

        const creditsValue = Math.floor(value / this.creditRatio);

        // Model always stores smallest unit (cents, pence etc), and currency should be displayed in pounds/dollars etc
        value /= 100;

        const format: Intl.NumberFormatOptions = {
        };

        const formatAsCredits = (this.credits || forceCreditMode === true) && forceCreditMode !== false;

        if (formatAsCredits) {
            format.style = "decimal";
            format.minimumFractionDigits = 0;
            format.maximumFractionDigits = 0;
            value = creditsValue;
        } else {
            format.style = "currency";
            format.minimumFractionDigits = 2;
            format.currency = this.currency;
            (format as any).currencyDisplay = this.symbolDisplay;
        }

        format.useGrouping = this.useGrouping;

        let formatted = value.toLocaleString(this.locale, format);

        // Final safeguard against floating point errors
        formatted = this.fixFloatingPointError(formatted);

        if (!trailingDecimals) {
            formatted = formatted.replace(`${this.decimalSeparator}00`, "");
        }

        return formatted;
    }

    public fixFloatingPointError(s: string) {
        const decimalPosition = s.indexOf(this.decimalSeparator);

        if (decimalPosition > -1) {
            const firstHalf = s.slice(0, decimalPosition + 3);
            const secondHalf = s.slice(decimalPosition + 3).replace(/\d/gi, "");
            s = firstHalf + secondHalf;
        }

        return s;
    }
}
