import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { EJBoardGameComponent } from "components/ej-board-game-component";
import { EJBoardGameResult } from "model/results/ej-board-game-result";
import { SlingoPurchaseEntryState } from "slingo/states/slingo-purchase-entry-state";

export class EJPurchaseEntryState extends SlingoPurchaseEntryState {
    public onExit(): void {
        const prizePositions = gameState.getCurrentGame().getLatestResultOfType(EJBoardGameResult)?.prizePositions;
        if (prizePositions) {
            Components.get(EJBoardGameComponent).updatePrizePositions(prizePositions).execute();
        }
        super.onExit();
    }
}
