import { Gameplay } from "appworks/model/gameplay/gameplay";
import { Point } from "appworks/utils/geom/point";
import { SlingoInitRecordBuilder } from "slingo/integration/builders/records/slingo-init-record-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { slotDefinition } from "slotworks/model/slot-definition";

export class EJInitRecordBuilder extends SlingoInitRecordBuilder {
    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        const configs = response.data.config;

        /** Parse Slot Bonus Config */
        // Reelstrip
        slotDefinition.reelsets.set("slotbonus", configs.slotsConfig.standardSpinReelSets[0].reels.map(
            (obj) => obj.symbols.map((num) => slotDefinition.symbolDefinitions[num].id)
        ));

        // Lines
        slotDefinition.lines.values = configs.slotsConfig.paylines.map((line: string) =>
            line.split("").map((y, x) => new Point(x, parseInt(y)))
        );

        return super.build(gameplay, request, response);
    }
}
