import { shuffle } from "appworks/utils/collection-utils";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SymbolConfig } from "slotworks/model/symbol-definition";

const slingoSymbolDefs: SymbolConfig[] = [];
const slotSymbolDefs: SymbolConfig[] = [];

export function EJGameDefinitionSetup() {
    slotDefinition.matrixGrid = [1, 1, 1, 1, 1];

    SymbolDefinitions();

    ReelSets();
}

function SymbolDefinitions() {
    for (let i = 1; i <= 75; i++) { slingoSymbolDefs.push({ id: "numbers/" + i }); }
    slingoSymbolDefs.push(
        { id: "blank", isBlank: true },
        { id: "J" },
        { id: "SJ" },
        { id: "FS" },
        { id: "CL", anticipation: 2, matchesMin: 3, reels: [0, 1, 2, 3, 4] },
        { id: "S", anticipation: 2, matchesMin: 3, reels: [0, 1, 2, 3, 4] }
    );

    slotSymbolDefs.push(
        { id: "WI", wild: true },
        { id: "H1" },
        { id: "H2" },
        { id: "M1" },
        { id: "M2" },
        { id: "L1" },
        { id: "L2" },
        { id: "L3" },
        { id: "L4" },
        { id: "SC", scatter: true }
    );

    slotDefinition.processSymbols([...slotSymbolDefs, ...slingoSymbolDefs]);
}

function ReelSets() {
    const numbers: string[] = [];
    for (let i = 1; i <= 75; i++) { numbers.push("numbers/" + i); }

    if (!slotDefinition.reelsets) { slotDefinition.reelsets = new Map(); }

    slotDefinition.reelsets.set("slingo", [
        [...shuffle(numbers)],
        [...shuffle(numbers)],
        [...shuffle(numbers)],
        [...shuffle(numbers)],
        [...shuffle(numbers)]]
    );
}
