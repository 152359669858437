import { AbstractComponent } from "appworks/components/abstract-component";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { Text } from "appworks/graphics/pixi/text";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { fade, fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { pulse, scaleIn } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { RandomRangeFloat } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { EJSoundEvent } from "ej-sound-events";
import { gameLayers } from "game-layers";

export class EJBonusWheelComponent extends AbstractComponent {
    public static PRIZES = [
        [
            {
                id: 1,
                wheelPos: 2,
                prize: "multiplier_02" // 0.2
            },
            {
                id: 2,
                wheelPos: 4,
                prize: "multiplier_05" // 0.5
            },
            {
                id: 3,
                wheelPos: 6,
                prize: "multiplier_1"
            },
            {
                id: 4,
                wheelPos: 1,
                prize: "multiplier_2"
            },
            {
                id: 5,
                wheelPos: 3,
                prize: "slots_2"
            },
            {
                id: 6,
                wheelPos: 5,
                prize: "slots_3"
            },
            {
                id: 7,
                wheelPos: 0,
                prize: "next_tier"
            }
        ],
        [
            {
                id: 1,
                wheelPos: 1,
                prize: "multiplier_2"
            },
            {
                id: 2,
                wheelPos: 2,
                prize: "multiplier_3"
            },
            {
                id: 3,
                wheelPos: 4,
                prize: "multiplier_5"
            },
            {
                id: 4,
                wheelPos: 6,
                prize: "multiplier_10"
            },
            {
                id: 5,
                wheelPos: 3,
                prize: "slots_6"
            },
            {
                id: 6,
                wheelPos: 5,
                prize: "slots_8"
            },
            {
                id: 7,
                wheelPos: 0,
                prize: "next_tier"
            }
        ],
        [
            {
                id: 1,
                wheelPos: 6,
                prize: "multiplier_10"
            },
            {
                id: 2,
                wheelPos: 4,
                prize: "multiplier_25"
            },
            {
                id: 3,
                wheelPos: 1,
                prize: "multiplier_50"
            },
            {
                id: 4,
                wheelPos: 3,
                prize: "multiplier_100"
            },
            {
                id: 5,
                wheelPos: 0,
                prize: "multiplier_250"
            },
            {
                id: 6,
                wheelPos: 2,
                prize: "slots_10"
            },
            {
                id: 7,
                wheelPos: 5,
                prize: "slots_20"
            }
        ]
    ];

    protected layer = gameLayers.WheelBonus;

    protected wheels: PIXIElement[];

    protected currentWheel: number;

    protected currentCashWin: number = 0;
    protected currentSlotWin: number = 0;

    public init(): void {
        gameLayers.WheelBonus.onSceneEnter.addOnce(() => this.init());

        if (this.layer.currentSceneIs("wheelbonus")) {
            this.wheels = [];

            for (let i = 0; i <= 2; i++) {
                const contents: PIXIElement[] = [];
                for (const elementName in this.layer.scene.wheelbonus.contents) {
                    if (elementName.includes(`wheel${i}`)) {
                        contents.unshift(this.layer.getSprite(elementName));
                    }
                }
                this.wheels.push(CenterPivot(contents));
            }

            this.reset(0).execute();

            this.currentCashWin = 0;
            this.currentSlotWin = 0;

            fadeOut([
                this.layer.getText("slot_spins_value"),
                this.layer.getSprite("slot_icon"),
                this.layer.getText("current_win_value_small")
            ], 0).execute();
        }
    }

    public spinToPrize(prize: string): Contract {
        const wheelPosition = EJBonusWheelComponent.PRIZES[this.currentWheel].find((prz) => prz.prize === prize).wheelPos;

        if (wheelPosition === undefined) {
            throw new Error(`Invalid prize ${prize} for wheel ${this.currentWheel}`);
        }

        const wheel = this.wheels[this.currentWheel];
        const overlay = CenterPivot(this.layer.getSprite(`wheel${this.currentWheel}_overlay`));
        wheel.rotation -= (Math.PI * 2) * 10;

        const segmentRotation = ((Math.PI * 2) / EJBonusWheelComponent.PRIZES[this.currentWheel].length);
        const targetRotation = segmentRotation * -wheelPosition;

        const sound = Services.get(SoundService);
        Timer.setTimeout(() => sound.customEvent(EJSoundEvent.bonus_wheel_spin), 800);

        const pulsePrize = (): Contract<void> => {
            return new Parallel([
                () => pulse(this.layer.getSprite(`wheel${this.currentWheel}_${prize}`), { x: 1.25, y: 1.25 }, 150),
                () => Contract.wrap(() => sound.customEvent(EJSoundEvent.bonus_wheel_prize_win)),
            ]);
        }

        return new Sequence([
            () => Contract.wrap(() => {
                if (this.currentWheel === 0) { this.showSpinStartText().execute(); }
            }),
            () => TweenContract.wrapTween(new Tween(wheel)
                .to({ rotation: wheel.rotation + (Math.PI * 2) }, 1000)
                .easing(Easing.Cubic.In)
                .onUpdate(() => { overlay.rotation = -wheel.rotation; })
                .delay(this.currentWheel === 0 ? 500 : 0)
            ),
            () => TweenContract.wrapTween(new Tween(wheel)
                .to({ rotation: targetRotation + RandomRangeFloat(-segmentRotation / 3, segmentRotation / 3) }, 10000)
                .easing(Easing.Cubic.Out)
                .onUpdate(() => { overlay.rotation = -wheel.rotation; })
            ),
            () => Contract.wrap(() => {
                new Tween(wheel)
                    .to({ rotation: targetRotation }, 1000)
                    .easing(Easing.Elastic.Out)
                    .onUpdate(() => { overlay.rotation = -wheel.rotation; })
                    .start();
            }),
            () => new Sequence([
                () => new Parallel([
                    () => Contract.wrap(() => sound.customEvent(EJSoundEvent.bonus_wheel_prize_win_start)),
                    () => Contract.getTimeoutContract(600)
                ]),
                () => pulsePrize(),
                () => pulsePrize(),
                () => pulsePrize(),
                () => pulsePrize(),
                () => pulsePrize()
            ])
        ]);
    }

    public switchWheel(wheel: number, duration: number = 500): Contract {
        this.currentWheel = wheel;
        const contracts: Array<() => Contract> = [];

        for (let i = 0; i <= 2; i++) {
            const indicator = this.layer.getSprite("indicator_" + i);
            const dim = this.layer.getSprite("dim_" + i);

            contracts.push(() => fade(this.wheels[i], this.wheels[i].alpha, (i >= this.currentWheel) ? 1 : 0, duration));
            contracts.push(() => fade(indicator, indicator.alpha, (i === this.currentWheel) ? 1 : 0, duration));
            contracts.push(() => fade(dim, dim.alpha, (i > this.currentWheel) ? 1 : 0, duration));
        }

        return new Parallel(contracts);
    }

    public reset(duration: number = 500): Contract {
        return new Parallel([
            () => Contract.wrap(() => {
                if (this.currentWheel > 0) {
                    new Tween(this.wheels[0]).to({ rotation: 0 }, duration).easing(Easing.Sinusoidal.Out).start();
                    new Tween(this.wheels[1]).to({ rotation: 0 }, duration).easing(Easing.Sinusoidal.Out).start();
                    new Tween(this.wheels[2]).to({ rotation: 0 }, duration).easing(Easing.Sinusoidal.Out).start();
                    new Tween(this.layer.getSprite(`wheel0_overlay`)).to({ rotation: 0 }, duration).easing(Easing.Sinusoidal.Out).start();
                    new Tween(this.layer.getSprite(`wheel1_overlay`)).to({ rotation: 0 }, duration).easing(Easing.Sinusoidal.Out).start();
                    new Tween(this.layer.getSprite(`wheel2_overlay`)).to({ rotation: 0 }, duration).easing(Easing.Sinusoidal.Out).start();
                }
            }),
            () => this.switchWheel(0, duration)
        ]);
    }

    public updateWinMeters(cashWin: number, slotWin: number): Contract {
        const cashWinText = this.layer.getText("current_win_value");
        const cashWinTextSmall = this.layer.getText("current_win_value_small");
        const slotWinText = this.layer.getText("slot_spins_value");

        if (cashWin !== this.currentCashWin) {
            const currencyService = Services.get(CurrencyService);

            return new Parallel([
                () => this.showWinParticle("cash"),
                () => TweenContract.wrapTween(new Tween(this)
                    .to({ currentCashWin: cashWin }, 1000)
                    .easing(Easing.Sinusoidal.InOut)
                    .onUpdate(() => {
                        cashWinText.text = currencyService.format(this.currentCashWin);
                        cashWinTextSmall.text = cashWinText.text + " + ";
                    })
                    .onComplete(() => {
                        this.currentCashWin = cashWin;
                        cashWinText.text = currencyService.format(this.currentCashWin);
                        cashWinTextSmall.text = cashWinText.text + " + ";
                    }))
            ]);
        } else if (slotWin !== this.currentSlotWin) {
            if (this.currentSlotWin === 0) {
                new Parallel([
                    () => fadeIn([
                        this.layer.getText("slot_spins_value"),
                        this.layer.getSprite("slot_icon"),
                        this.layer.getText("current_win_value_small")
                    ], 500, Easing.Cubic.In),
                    () => fadeOut([
                        this.layer.getText("current_win_value")
                    ], 500)
                ]).execute();
            }

            this.currentSlotWin = slotWin;
            slotWinText.text = slotWin.toString();

            return this.showWinParticle("slot");
        }
    }

    protected showWinParticle(winType: "slot" | "cash"): Contract {
        let targetText: Text;
        const particle = this.layer.getParticleContainer("spark");

        if (winType === "slot") {
            targetText = this.layer.getText("slot_spins_value");
        } else {
            targetText = [
                this.layer.getText("current_win_value"),
                this.layer.getText("current_win_value_small")
            ].find((txt) => txt.visible);
        }

        return new Sequence([
            () => Contract.wrap(() => {
                particle.landscape.x = targetText.landscape.getCenterPoint().x;
                particle.landscape.y = targetText.landscape.getCenterPoint().y;
                particle.portrait.x = targetText.portrait.getCenterPoint().x;
                particle.portrait.y = targetText.portrait.getCenterPoint().y;

                particle.emitter.emit = true;
            }),
            () => Contract.getTimeoutContract(500),
            () => Contract.wrap(() => {
                particle.emitter.emit = false;
            })
        ]);
    }

    protected showSpinStartText(): Contract {
        const text = this.layer.getText("spin_start_text");
        text.text = Services.get(TranslationsService).get("spin") + "\n" + this.layer.getText("spins_remaining_value").text;

        text.landscape.scale.set(0.5);
        text.portrait.scale.set(0.5);

        return new Parallel([
            () => scaleIn(text, 1500),
            () => new Sequence([
                () => fadeIn(text, 500),
                () => Contract.getTimeoutContract(500),
                () => fadeOut(text, 500)
            ])
        ]);
    }
}
