import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { EJBoardGameResult } from "model/results/ej-board-game-result";

export class EJBoardGameResultBuilder implements ResultBuilder {
    public filter(data: any, record?: Record, additionalData?: any): boolean {
        return Boolean(data.trailGame);
    }

    public build(record: Record, data: any, additionalData?: any): Result[] {
        const result = new EJBoardGameResult();

        const trailData = data.trailGame;

        if (trailData.payout) {
            for (const payout of trailData.payout) {
                if (payout.win) {
                    result.cashWon += payout.win * 100;
                }
            }
        }

        result.jokerPosition = trailData.jokerPositions[0];

        result.prizePositions = trailData.trail;

        return [result];
    }

}
