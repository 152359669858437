import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";

export class EJExitWheelBonusDecision extends Decision {
    public evaluate(): Exit {
        const results = gameState.getCurrentGame().getCurrentRecord().getResultsOfType(EJBonusWheelResult);
        if (results.length === 0 || results.every((result) => result.played)) {
            return Exit.True;
        }
        return Exit.False;
    }
}
