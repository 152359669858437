import { SocialEnterSlotBonusState } from "appworks/state-machine/social/social-enter-slot-bonus-state";
import { SocialEnterWheelBonusState } from "appworks/state-machine/social/social-enter-wheel-bonus-state";
import { SocialExitSlotBonusState } from "appworks/state-machine/social/social-exit-slot-bonus-state";
import { SocialExitWheelBonusState } from "appworks/state-machine/social/social-exit-wheel-bonus-state";
import { SocialGameOverState } from "appworks/state-machine/social/social-game-over-state";
import { SocialInitState } from "appworks/state-machine/social/social-init-state";
import { SocialPurchaseEntryState } from "appworks/state-machine/social/social-purchase-entry-state";
import { SocialPurchaseSpinOrCollectState } from "appworks/state-machine/social/social-purchase-spin-or-collect-state";
import { SocialRecoveryState } from "appworks/state-machine/social/social-recovery-state";
import { SocialRequestSpinState } from "appworks/state-machine/social/social-request-spin-state";
import { stateMachine } from "appworks/state-machine/state-machine";
import { SlingoFreeSpinsRemainingDecision } from "slingo/states/decisions/slingo-free-spins-remaining-decision";
import { SlingoGameInProgressDecision } from "slingo/states/decisions/slingo-game-in-progress-decision";
import { SlingoHasJokerDecision } from "slingo/states/decisions/slingo-has-joker-decision";
import { SlingoRecoveryIsBeforeFirstSpinDecision } from "slingo/states/decisions/slingo-recovery-is-before-first-spin-decision";
import { SlingoSpinPurchasedDecision } from "slingo/states/decisions/slingo-spin-purchased-decision";
import { SlingoStandardSpinsRemainingDecision } from "slingo/states/decisions/slingo-standard-spins-remaining-decision";
import { SlingoTicketCompletedDecision } from "slingo/states/decisions/slingo-ticket-completed-decision";
import { SlingoBigWinState } from "slingo/states/slingo-big-win-state";
import { SlingoIdleState } from "slingo/states/slingo-idle-state";
import { IsRecoveryDecision } from "slotworks/state-machine/standard/decisions/is-recovery-decision";
import { IsWinDecision } from "slotworks/state-machine/standard/decisions/is-win-decision";
import { DelayState } from "slotworks/state-machine/standard/states/delay-state";
import { EJHasSlotBonusDecision } from "states/ej-has-slot-bonus-decision";
import { EJHasWheelBonusDecision } from "states/ej-has-wheel-bonus-decision";
import { EJPickJokerState } from "states/ej-pick-joker-state";
import { EJSpinState } from "states/ej-spin-state";
import { EJSlotBonusExpandWildsState } from "states/slotbonus/ej-slot-bonus-expand-wilds-state";
import { EJSlotBonusIdleState } from "states/slotbonus/ej-slot-bonus-idle-state";
import { EJSlotBonusLoopWinsState } from "states/slotbonus/ej-slot-bonus-loop-wins-state";
import { EJSlotBonusShowWinsState } from "states/slotbonus/ej-slot-bonus-show-wins-state";
import { EJSlotBonusSpinState } from "states/slotbonus/ej-slot-bonus-spin-state";
import { EJSlotBonusSpinsRemainingDecision } from "states/slotbonus/ej-slot-bonus-spins-remaining-decision";
import { EJExitWheelBonusDecision } from "states/wheelbonus/ej-exit-wheel-bonus-decision";
import { EJWheelBonusState } from "states/wheelbonus/ej-wheel-bonus-state";

/**
 * State Diagram:
 * https://drive.google.com/file/d/1u5mBjr5bWFSlyrGkD5f_SUNT2m1PgWz6/view?usp=sharing
 */

export function EJStateSetup() {
    // States
    const initState = new SocialInitState();
    const idleState = new SlingoIdleState();
    const recoveryState = new SocialRecoveryState();
    const purchaseEntryState = new SocialPurchaseEntryState();
    const requestSpinState = new SocialRequestSpinState();
    const spinState = new EJSpinState();
    const pickJokerState = new EJPickJokerState();
    const prePurchaseOrCollectDelayState = new DelayState(500); // Dan asked for a delay before showing collect options
    const purchaseOrCollectState = new SocialPurchaseSpinOrCollectState();
    const gameOverState = new SocialGameOverState();
    const preBigWinState = new DelayState(500);
    const bigWinState = new SlingoBigWinState();

    const enterSlotBonusState = new SocialEnterSlotBonusState();
    const slotBonusIdleState = new EJSlotBonusIdleState();
    const slotBonusSpinState = new EJSlotBonusSpinState({ enableSafetyCheck: false });
    const slotBonusExpandWildsState = new EJSlotBonusExpandWildsState();
    const slotBonusShowWinsState = new EJSlotBonusShowWinsState();
    const exitSlotBonusState = new SocialExitSlotBonusState();
    const slotBonusLoopWins = new EJSlotBonusLoopWinsState();

    const enterWheelBonusState = new SocialEnterWheelBonusState();
    const wheelBonusState = new EJWheelBonusState();
    const exitWheelBonusState = new SocialExitWheelBonusState();

    // Decisions
    const isRecoveryDecision = new IsRecoveryDecision();
    const gameInProgressDecision = new SlingoGameInProgressDecision();
    const hasJokerDecision = new SlingoHasJokerDecision();
    const standardSpinsRemainingDecision = new SlingoStandardSpinsRemainingDecision();
    const freeSpinsRemainingDecision = new SlingoFreeSpinsRemainingDecision();
    const ticketCompletedDecision = new SlingoTicketCompletedDecision();
    const spinPurchasedDecision = new SlingoSpinPurchasedDecision();
    const recoveryBeforeFirstSpinDecision = new SlingoRecoveryIsBeforeFirstSpinDecision();

    const hasSlotBonusDecision = new EJHasSlotBonusDecision();
    const slotBonusSpinsRemainingDecision = new EJSlotBonusSpinsRemainingDecision();
    const slotBonusSpinHasWinsDecision = new IsWinDecision();

    const hasWheelBonusDecision = new EJHasWheelBonusDecision();
    const exitWheelBonusDecision = new EJExitWheelBonusDecision();

    stateMachine.addStates([
        { state: initState, entryPoint: true, links: { complete: isRecoveryDecision } },
        { state: recoveryState, links: { complete: recoveryBeforeFirstSpinDecision } },

        { state: idleState, links: { complete: gameInProgressDecision } },
        { state: purchaseEntryState, links: { complete: requestSpinState, error: idleState } },
        { state: requestSpinState, links: { complete: spinState, error: purchaseOrCollectState } },
        { state: spinState, links: { complete: hasWheelBonusDecision } },
        { state: pickJokerState, links: { complete: hasWheelBonusDecision } },
        { state: prePurchaseOrCollectDelayState, links: { complete: purchaseOrCollectState } },
        { state: purchaseOrCollectState, links: { complete: spinPurchasedDecision } },
        { state: preBigWinState, links: {complete: bigWinState } },
        { state: bigWinState, links: { complete: gameOverState } },
        { state: gameOverState, links: { complete: idleState } },

        { state: enterSlotBonusState, links: { complete: slotBonusIdleState } },
        { state: slotBonusIdleState, links: { complete: slotBonusSpinState } },
        { state: slotBonusSpinState, links: { complete: slotBonusExpandWildsState } }, ,
        { state: slotBonusExpandWildsState, links: { complete: slotBonusShowWinsState } },
        { state: slotBonusLoopWins, links: { complete: slotBonusLoopWins, skip: slotBonusSpinState } },
        { state: slotBonusShowWinsState, links: { complete: slotBonusSpinsRemainingDecision } },
        { state: exitSlotBonusState, links: { complete: preBigWinState } },

        { state: enterWheelBonusState, links: { complete: wheelBonusState } },
        { state: wheelBonusState, links: { complete: exitWheelBonusDecision } },
        { state: exitWheelBonusState, links: { complete: ticketCompletedDecision } }
    ]);

    stateMachine.addDecisions([
        { decision: isRecoveryDecision, links: { true: recoveryState, false: idleState } },
        { decision: recoveryBeforeFirstSpinDecision, links: { true: requestSpinState, false: spinState } },
        { decision: gameInProgressDecision, links: { true: requestSpinState, false: purchaseEntryState } },
        { decision: hasJokerDecision, links: { true: pickJokerState, false: standardSpinsRemainingDecision } },
        { decision: standardSpinsRemainingDecision, links: { true: requestSpinState, false: freeSpinsRemainingDecision } },
        { decision: freeSpinsRemainingDecision, links: { true: requestSpinState, false: prePurchaseOrCollectDelayState } },
        { decision: spinPurchasedDecision, links: { true: requestSpinState, false: hasSlotBonusDecision } },
        { decision: hasSlotBonusDecision, links: { true: enterSlotBonusState, false: bigWinState } },
        { decision: ticketCompletedDecision, links: { true: hasSlotBonusDecision, false: hasJokerDecision } },
        { decision: slotBonusSpinsRemainingDecision, links: { true: slotBonusSpinHasWinsDecision, false: exitSlotBonusState } },
        { decision: slotBonusSpinHasWinsDecision, links: { true: slotBonusLoopWins, false: slotBonusIdleState } },
        { decision: hasWheelBonusDecision, links: { true: enterWheelBonusState, false: ticketCompletedDecision } },
        { decision: exitWheelBonusDecision, links: { true: exitWheelBonusState, false: wheelBonusState } }
    ]);
}
