import { ForceRequestPayload } from "appworks/model/gameplay/requests/force-request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { HTTPConnector } from "appworks/server/connectors/http-connector";
import { Contract } from "appworks/utils/contracts/contract";
import { ForceList } from "slotworks/debug/menu/force-list";

/**
 * Use this for simple forcing, for example forces which just set forceData on HTTPConnector
 */
export class SimpleForceList implements ForceList {
    protected results: Map<string, any> = new Map<string, any>();

    public setResults(results: Map<string, any>) {
        this.results = results;
    }

    public getList(): Contract<string[]> {
        return new Contract<string[]>((resolve) => {
            resolve(Array.from(this.results.keys()));
        });
    }

    public force(id: string): Contract<void> {
        const force: string = this.results.has(id) ? this.results.get(id) : id;
        if (commsManager.connector instanceof HTTPConnector) {
            commsManager.connector.setForce(force);
        }
        return commsManager.request(new ForceRequestPayload(force));
    }
}
