import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { EJBonusWheelComponent } from "components/ej-bonus-wheel-component";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";

export class EJBonusWheelResultBuilder implements ResultBuilder {
    public filter(data: any, record?: Record, additionalData?: any): boolean {
        return Boolean(data.chanceGames);
    }

    public build(record: Record, data: any, additionalData?: any): Result[] {
        const results = [];

        for (const bonus of data.chanceGames) {
            const result = new EJBonusWheelResult(bonus.id);
            result.prizeSequence = [];

            let game = bonus;
            while (game) {
                result.prizeSequence.push(
                    EJBonusWheelComponent.PRIZES[result.prizeSequence.length]
                        .find((prz) => prz.id === game.payout[0].id).prize
                );

                game = game.games ? game.games[0] : null;
            }

            result.cashWon = bonus.totalWin * 100;
            const prize = result.prizeSequence[result.prizeSequence.length - 1];
            if (prize.includes("slots_")) {
                result.slotSpinsWon = parseInt(prize.replace("slots_", ""));
            }

            results.push(result);
        }

        return results;
    }
}
