import { GraphicsService } from "appworks/graphics/graphics-service";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { gameLayers } from "game-layers";
import { BLEND_MODES } from "pixi.js";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";

export class EJTicketMatrixComponent extends SlingoTicketMatrixComponent {
    protected getHighlightSprite(highlightType: SlingoHighlightType): Sprite {
        const sprite = Services.get(GraphicsService).createAnimation(`highlight_${highlightType}`);
        sprite.blendMode = BLEND_MODES.ADD;

        const master = gameLayers.TicketMatrix.getAnimatedSprite("master_highlight");
        sprite.gotoAndPlay(master.currentFrame);

        return sprite;
    }
}
