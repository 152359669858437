import { AbstractComponent } from "appworks/components/abstract-component";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { EJSoundEvent } from "ej-sound-events";
import { gameLayers } from "game-layers";

export class EJExpandingWildComponent extends AbstractComponent {
    public init(): void {
        gameLayers.SymbolAnimations.onSceneEnter.add((scene: string) => {
            if (scene === "slotbonus") {
                this.hideAll(0).execute();
            }
        });
    }

    public showWild(reelIndex: number): Contract {
        const spines = [
            gameLayers.MatrixForeground.getSpine("wild_bg_" + reelIndex),
            gameLayers.SymbolAnimations.getSpine("wild_fg_" + reelIndex)
        ];

        return new Parallel([
            () => fadeIn(spines, 150),
            () => Contract.wrap(() => Services.get(SoundService).customEvent(EJSoundEvent.expand_wild)),
            ...spines.map((spine) => () => spine.playOnce())
        ]);
    }

    public winAnim(reelIndex: number): Contract {
        const spine = gameLayers.SymbolAnimations.getSpine("wild_fg_" + reelIndex);
        if (spine.alpha === 0) { return Contract.empty(); }
        return spine.playOnce("loop");
    }

    public hideAll(duration: number = 0, delay: number = 0): Contract {
        const spinesToFade = this.getSpineElements().filter((spine) => spine.alpha > 0);

        if (spinesToFade.length === 0) {
            return Contract.empty();
        }

        return new Sequence([
            () => Contract.getTimeoutContract(delay),
            () => fadeOut(spinesToFade, duration)
        ]);
    }

    protected getSpineElements(): SpineContainer[] {
        return [
            gameLayers.MatrixForeground.scene.slotbonus.contents.wild_bg_0,
            gameLayers.MatrixForeground.scene.slotbonus.contents.wild_bg_1,
            gameLayers.MatrixForeground.scene.slotbonus.contents.wild_bg_2,
            gameLayers.MatrixForeground.scene.slotbonus.contents.wild_bg_3,
            gameLayers.MatrixForeground.scene.slotbonus.contents.wild_bg_4,

            gameLayers.SymbolAnimations.scene.slotbonus.contents.wild_fg_0,
            gameLayers.SymbolAnimations.scene.slotbonus.contents.wild_fg_1,
            gameLayers.SymbolAnimations.scene.slotbonus.contents.wild_fg_2,
            gameLayers.SymbolAnimations.scene.slotbonus.contents.wild_fg_3,
            gameLayers.SymbolAnimations.scene.slotbonus.contents.wild_fg_4
        ];
    }
}
