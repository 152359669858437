import { AbstractComponent } from "appworks/components/abstract-component";
import { Layers } from "appworks/graphics/layers/layers";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { pulse } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Timer } from "appworks/utils/timer";
import { Easing, Tween } from "appworks/utils/tween";
import { EJSoundEvent } from "ej-sound-events";
import { KawaseBlurFilter } from "pixi-filters";

export class EJDiceComponent extends AbstractComponent {
    protected layer: Layers;

    protected sprites: Sprite[];
    protected current: number;

    protected filter: KawaseBlurFilter;

    constructor(protected layerName: string) { super(); }

    public init() {
        this.layer = Layers.get(this.layerName);

        this.sprites = [
            CenterPivot(this.layer.getSprite("dice_1")) as Sprite,
            CenterPivot(this.layer.getSprite("dice_2")) as Sprite,
            CenterPivot(this.layer.getSprite("dice_3")) as Sprite,
            CenterPivot(this.layer.getSprite("dice_4")) as Sprite,
            CenterPivot(this.layer.getSprite("dice_5")) as Sprite,
            CenterPivot(this.layer.getSprite("dice_6")) as Sprite
        ];

        this.filter = new KawaseBlurFilter(0.5, 10);
        this.sprites.forEach((sprite) => sprite.filters = [this.filter]);
        this.filter.enabled = false;
    }

    public roll(value: number, duration: number = 500): Contract {
        if (value === null) {
            return Contract.empty();
        }

        if (value < 1 || value > 6) {
            throw new Error("Invalid dice value: " + value);
        }

        const tweens: Tween[] = [];
        this.sprites.forEach((sprite, index) => {
            sprite.rotation = 0;
            this.layer.add(sprite); // bring to front

            tweens.push(
                new Tween(sprite)
                    .to({ rotation: (Math.PI * 2) * 5 }, duration)
                    .easing(Easing.Cubic.InOut)
                    .onStart(() => {
                        this.filter.enabled = true;
                        Timer.setTimeout(() => {
                            sprite.visible = value === (index + 1);
                        }, duration / 2);
                    })
                    .onComplete(() => {
                        this.filter.enabled = false;
                        if (sprite.visible) {
                            new Sequence([
                                () => pulse(sprite, { x: 1.3, y: 1.3 }, 100),
                                () => pulse(sprite, { x: 1.3, y: 1.3 }, 100),
                                () => pulse(sprite, { x: 1.3, y: 1.3 }, 100)
                            ]).execute();
                        }
                    })
            );
        });

        Services.get(SoundService).customEvent(EJSoundEvent.dice_roll);

        return new Parallel(tweens.map((tween) => () => TweenContract.wrapTween(tween)));
    }
}
