import { DebugTab } from "slotworks/debug/menu/tabs/debug-tab";
import { ForceList } from "../force-list";

export class ForceTab extends DebugTab {
    protected useStopsForcer: boolean;

    constructor(name: string, resultList?: ForceList, useStopsForcer: boolean = true) {
        super(name, resultList);
        this.useStopsForcer = useStopsForcer;
    }

    public render(root: HTMLElement, hide: Function) {
        this.resultList.getList().then((list: string[]) => {

            // Generate option groupings based on name
            const groupedResults: Map<string, string[]> = new Map();
            const defaultGroup = [];
            groupedResults.set("default", defaultGroup);

            list.forEach((force) => {
                if (force.charAt(0) === "[" && force.indexOf("]") > -1) {
                    const group = force.slice(force.indexOf("[") + 1, force.indexOf("]"));
                    const label = force.slice(force.indexOf("]") + 1);

                    if (!groupedResults.has(group)) {
                        groupedResults.set(group, []);
                    }
                    groupedResults.get(group).push(label);
                } else {
                    defaultGroup.push(force);
                }
            });

            if (this.useStopsForcer) {
                this.addStopsForcer(root, hide);
            }

            if (groupedResults.get("default").length > 0) {
                this.addSeparator(root);

                const header = this.createHeader();
                header.innerText = "Preset Forces";

                root.appendChild(header);
            }

            // Add buttons
            groupedResults.forEach((forces, group) => {
                let groupElement = root;
                if (group !== "default") {
                    groupElement = this.addForceGroup(root, group);
                }

                for (const force of forces) {
                    let forceId = force;
                    if (groupElement !== root) {
                        forceId = `[${group}]${force}`;
                    }

                    this.addResultButton(groupElement, hide, forceId, force);
                }
            });
        });
    }

    protected formatForceData(inputText: string): any {
        const stops = inputText.split(",").map((value) => parseInt(value));
        return { stops };
    }

    protected addForceGroup(container: HTMLElement, name: string) {
        const headerElement = this.createHeader();
        const topRuleElement = document.createElement("hr");
        const bottomRuleElement = document.createElement("hr");
        const groupElement = document.createElement("div");

        headerElement.innerText = name;

        container.appendChild(topRuleElement);
        container.appendChild(headerElement);
        container.appendChild(bottomRuleElement);
        container.appendChild(groupElement);

        return groupElement;
    }

    protected addStopsForcer(container: HTMLElement, hide: Function) {
        // Add input text
        const inputText = document.createElement("input");
        inputText.type = "text";
        inputText.style.fontSize = "1.5vw";
        inputText.style.margin = "1vmax 1vw";
        inputText.style.borderRadius = "10px";
        inputText.style.width = "30vw";
        inputText.style.height = "10vmax";
        inputText.style.backgroundColor = "#ffffff";

        const inputBtn = document.createElement("button");

        inputBtn.style.fontSize = "1.5vw";
        inputBtn.style.margin = "1vmax 1vw";
        inputBtn.style.borderRadius = "10px";
        inputBtn.style.width = "35vw";
        inputBtn.style.height = "10vmax";
        inputBtn.style.backgroundColor = "#ffffff";
        inputBtn.style.outline = "none";
        inputBtn.style.cursor = "pointer";
        inputBtn.innerText = "SET";

        inputBtn.onclick = () => {
            this.resultList.force(this.formatForceData(inputText.value)).execute();
            hide();
        };

        container.appendChild(inputText);
        container.appendChild(inputBtn);
    }

    protected createHeader() {
        const header = document.createElement("h1");
        header.style.fontSize = "2vw";
        header.style.width = "76vw";
        header.style.color = "white";
        header.style.textAlign = "center";

        return header;
    }
}
