export function RGBArrayFromHex(hex: number): [number, number, number] {
    return [hex >> 16 & 0xff, hex >> 8 & 0xff, hex & 0xff];
}

export function RGBToHex(rgb: { r: number, g: number, b: number }) {
    return rgb.r << 16 | rgb.g << 8 | rgb.b;
}

export function HexToRGB(color: number) {
    return { r: color >> 16, g: color >> 8 & 0xff, b: color & 0xff };
}

export function StringToHex(color: string): number {
    if (/^0x[0-9A-Fa-f]+$/.test(color)) {
        return Number(color);
    } else if (/^#[0-9A-Fa-f]+$/.test(color)) {
        return Number(color.replace(/^#/, "0x"));
    } else if (/^[0-9A-Fa-f]+$/.test(color)) {
        return Number(`0x${color}`);
    }
}

export function HexGradient(from: number = 0x000000, to: number = 0xffffff, steps: number = 3): number[] {
    const colors: number[] = [];
    for (let i: number = 0; i < steps; i++) {
        const perc: number = Math.round((i / (steps - 1)) * 100) / 100;
        let color: number = Math.round(from + ((to - from) * perc));
        color = Math.min(Math.max(color, 0), 0xffffff);
        colors.push(color);
    }
    return colors;
}
