import { Layers } from "appworks/graphics/layers/layers";
import { LayerNames } from "game-layers";

export function EJLayerSetup() {
    Layers.setLayerOrderConfig({
        default: [
            LayerNames.Background,
            LayerNames.Onboard,
            LayerNames.OnboardPages,
            LayerNames.MatrixBackground,
            LayerNames.MatrixContent,
            LayerNames.MatrixForeground,
            LayerNames.TicketMatrixBacking,
            LayerNames.TicketMatrix,
            LayerNames.Logo,
            LayerNames.BetBar,
            LayerNames.MenuButton,
            LayerNames.BoardGame,
            LayerNames.SymbolAnimations,
            LayerNames.Paylines,
            LayerNames.SpinButton,
            LayerNames.Skip,
            LayerNames.SpinCounter,
            LayerNames.Footer,
            LayerNames.Collect,
            LayerNames.CollectBonusValues,
            LayerNames.WheelBonus,
            LayerNames.SlingoLadder,
            LayerNames.CelebrationContent,
            LayerNames.Prompts,
            LayerNames.Menus,
            LayerNames.ScreenFade
        ],
        wheelbonus: [
            LayerNames.Onboard,
            LayerNames.OnboardPages,
            LayerNames.MatrixBackground,
            LayerNames.MatrixContent,
            LayerNames.MatrixForeground,
            LayerNames.TicketMatrixBacking,
            LayerNames.TicketMatrix,
            LayerNames.BoardGame,
            LayerNames.SymbolAnimations,
            LayerNames.Paylines,
            LayerNames.SpinButton,
            LayerNames.Skip,
            LayerNames.SpinCounter,
            LayerNames.Footer,
            LayerNames.SlingoLadder,
            LayerNames.CelebrationContent,
            LayerNames.Collect,
            LayerNames.CollectBonusValues,
            LayerNames.MenuButton,
            LayerNames.Background,
            LayerNames.Logo,
            LayerNames.BetBar,
            LayerNames.WheelBonus,
            LayerNames.Prompts,
            LayerNames.Menus,
            LayerNames.ScreenFade
        ]
    }, LayerNames);

    Layers.get("Prompts").onSceneEnter.add(() => {
        const cover = Layers.get("Prompts").getShape("cover");
        if (cover) {
            Layers.get("Prompts").add(cover, false, true);
        }
    });
}
