import { AbstractComponent } from "appworks/components/abstract-component";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { scaleIn } from "appworks/utils/animation/scale";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Point } from "appworks/utils/geom/point";
import { RandomFromArray } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { EJSoundEvent } from "ej-sound-events";
import { gameLayers } from "game-layers";
import { DropShadowFilter } from "pixi-filters";

enum Direction {
    LEFT = "left",
    RIGHT = "right"
}

enum JokerAnimations {
    IDLE = "idle_{dir}",
    STAND_TO_WALK = "stand_to_walk_{dir}",
    // TURN_WALK_TO = "turn_walk_to_{dir}_1", // not used, too subtle at this small scale
    // TURN_WALK_TO_2 = "turn_walk_to_{dir}_2", // not used, too subtle at this small scale
    WALK_CYCLE = "walk_cycle_{dir}",
    WALK_CYCLE_BLINK = "walk_cycle_{dir}_blink",
    WALK_CYCLE_EYEMOVE = "walk_cycle_{dir}_eyemove",
    WALK_TO_STAND = "walk_to_stand_{dir}",
    WIN = "celebrating_{dir}"
}

export class EJBoardGameComponent extends AbstractComponent {
    public static NUM_POSITIONS = 24;

    protected layer = gameLayers.BoardGame;

    protected tweenCancelGroup = new CancelGroup();

    protected joker: SpineContainer;
    protected jokerAnchor = {
        left: new Point(0.7, 0.78),
        right: new Point(0.5, 0.78)
    };
    protected footstepSoundInterval: number;

    protected isMoving: boolean = false;
    protected currentPosition = -1;
    protected targetPosition = -1;
    protected currentDirection = Direction.RIGHT;

    protected prizePositions: Array<{ id: number, position: number }>;

    protected jokerSpeedMultiplier: number = 1.5;

    public init(): void {
        this.joker = this.layer.getSpine("joker");
        this.joker.onComplete.add(() => this.playNextJokerAnim());
        this.playNextJokerAnim();

        this.joker.filters = [new DropShadowFilter({ alpha: 1, quality: 5, rotation: 180 })];
    }

    public resetJoker(): Contract {
        return new Sequence([
            () => fadeOut(this.joker, 250),
            () => this.moveJokerToPosition(0, 0),
            () => fadeIn(this.joker, 250)
        ]);
    }

    public moveJokerToPosition(position: number, moveTimePerPos: number = 416): Contract {
        this.tweenCancelGroup.skip();

        if (position === this.targetPosition) {
            return Contract.empty();
        }
        if (position < 0 || position >= EJBoardGameComponent.NUM_POSITIONS ||
            position === undefined || position === null) {
            throw new Error("Invalid board position: " + position);
        }

        const positions: DualPosition[] = [];
        while (this.targetPosition !== position) {
            if (++this.targetPosition >= EJBoardGameComponent.NUM_POSITIONS) {
                this.targetPosition = 0;
            }
            positions.push(this.getPositionFromIndex(this.targetPosition, true));
        }

        let interval = 0;
        const tweenLandscape = this.tweenCancelGroup.tween(this.joker.landscape)
            .to({
                x: positions.map((pos) => pos.landscape.x),
                y: positions.map((pos) => pos.landscape.y)
            }, moveTimePerPos * positions.length / this.jokerSpeedMultiplier);
        const tweenPortrait = this.tweenCancelGroup.tween(this.joker.portrait)
            .to({
                x: positions.map((pos) => pos.portrait.x),
                y: positions.map((pos) => pos.portrait.y)
            }, moveTimePerPos * positions.length / this.jokerSpeedMultiplier)
            .onStart(() => {
                interval = Timer.setInterval(() => this.updateJokerPosition(), moveTimePerPos / this.jokerSpeedMultiplier);
            });

        return this.tweenCancelGroup.sequence([
            () => Contract.wrap(() => {
                this.isMoving = true;
                this.playNextJokerAnim("start");
            }),
            () => Contract.getTimeoutContract(Math.min(moveTimePerPos, 250) / this.jokerSpeedMultiplier), // while he plays start anim
            () => this.tweenCancelGroup.parallel([
                () => this.tweenCancelGroup.tweenCancellableContract(tweenLandscape),
                () => this.tweenCancelGroup.tweenCancellableContract(tweenPortrait)
            ]),
            () => Contract.wrap(() => {
                this.isMoving = false;
                this.playNextJokerAnim("stop");
                this.updateJokerPosition();
                Timer.clearInterval(interval);

                if (!moveTimePerPos) {
                    this.playNextJokerAnim();
                }
            })
        ]);
    }

    public getCurrentJokerPosition(): number {
        return this.currentPosition;
    }

    public win(amount: number) {
        if (!amount) { return Contract.empty(); }

        Services.get(SoundService).customEvent(EJSoundEvent.trail_win);

        this.playNextJokerAnim("win");

        const text = this.layer.getText("win_value");
        const winAmount = Services.get(CurrencyService).format(amount);

        const startPos = this.getPositionFromIndex(this.getCurrentJokerPosition());
        const targetPos = this.layer.getPosition("win_value_target");

        text.landscape.x = startPos.landscape.x - (text.landscape.width / 2);
        text.landscape.y = startPos.landscape.y - text.landscape.height;
        text.portrait.x = startPos.portrait.x - (text.portrait.width / 2);
        text.portrait.y = startPos.portrait.y - text.portrait.height;

        text.text = winAmount;

        text.landscape.scale.set(0);
        text.portrait.scale.set(0);

        const tweenLandscape = new Tween(text.landscape)
            .to({ x: targetPos.landscape.x, y: targetPos.landscape.y }, 500)
            .easing(Easing.Cubic.In);
        const tweenPortrait = new Tween(text.portrait)
            .to({ x: targetPos.portrait.x, y: targetPos.portrait.y }, 500)
            .easing(Easing.Cubic.In);

        return new Sequence([
            () => Contract.wrap(() => text.visible = true),
            () => Contract.wrap(() => this.layer.add(text)),
            () => new Parallel([
                () => scaleIn(text, 500, Easing.Back.Out),
                () => fadeIn(text, 250)
            ]),
            () => Contract.getTimeoutContract(500),
            () => new Parallel([
                () => TweenContract.wrapTween(tweenLandscape),
                () => TweenContract.wrapTween(tweenPortrait),
                () => fadeOut(text, 500, Easing.Cubic.In)
            ])
        ]);
    }

    public updatePrizePositions(newPrizePositions: Array<{ id: number, position: number }>): Contract {
        let changed = false;
        newPrizePositions.forEach((newPrize) => {
            const oldPrize = this.prizePositions?.find((val) => val.id === newPrize.id);
            if (!oldPrize || oldPrize.position !== newPrize.position) {
                changed = true;
            }
        });
        if (!changed) { return Contract.empty(); }

        const contracts: Array<() => Contract> = [];

        for (const prize of newPrizePositions) {
            const sprite = CenterPivot(this.layer.getSprite("prize_" + prize.id));
            const position = this.getPositionFromIndex(prize.position);

            sprite.landscape.x = position.landscape.x;
            sprite.landscape.y = position.landscape.y;
            sprite.portrait.x = position.portrait.x;
            sprite.portrait.y = position.portrait.y;

            contracts.push(() => fadeIn(sprite, 500));
        }

        return new Sequence([
            () => this.clearPrizes(),
            () => Contract.wrap(() => { this.prizePositions = newPrizePositions; }),
            () => new Parallel(contracts)
        ]);
    }

    public setDefaultPrizePositions() {
        // Prize positions should never change now but already got the stuff in to handle them dynamically,
        // I'll leave it and just use these defaults
        this.updatePrizePositions([
            {
                id: 0,
                position: 21
            },
            {
                id: 1,
                position: 9
            },
            {
                id: 2,
                position: 12
            },
            {
                id: 3,
                position: 3
            },
            {
                id: 4,
                position: 15
            }
        ]).execute();
    }

    protected clearPrizes(): Contract {
        if (!this.prizePositions) { return Contract.empty(); }

        const sprites = this.prizePositions?.map((prize) => this.layer.getSprite("prize_" + prize.id));
        this.prizePositions = undefined;
        return fadeOut(sprites, 250);
    }

    protected updateJokerPosition() {
        this.currentPosition = this.getJokerCurrentClosestPositionIndex();

        const oldDirection = this.currentDirection;
        this.currentDirection = this.getJokerDirectionFromIndex(this.currentPosition);
        if (oldDirection !== this.currentDirection) {
            this.playNextJokerAnim();
        }

        this.layer.add(this.joker); // ensure on top
    }

    protected getPositionFromIndex(index: number, offsetForJoker: boolean = false): DualPosition {
        const position = this.layer.getPosition(`pos_${index}`).getCenterPos();

        if (offsetForJoker) {
            const spinePosition = this.joker.getSpinePosition();
            const anchor = this.jokerAnchor[this.getJokerDirectionFromIndex(index)];

            position.landscape.x -= (spinePosition.landscape.width * anchor.x);
            position.landscape.y -= (spinePosition.landscape.height * anchor.y);

            position.portrait.x -= (spinePosition.portrait.width * anchor.x);
            position.portrait.y -= (spinePosition.portrait.height * anchor.y);
        }

        return position;
    }

    protected playNextJokerAnim(action?: "start" | "stop" | "win") {
        let animationName: string;
        let timeScale = this.jokerSpeedMultiplier;

        if (action === "win") {
            timeScale = 1;

            animationName = JokerAnimations.WIN;

            const sparkle = CenterPivot(this.layer.getAnimatedSprite("joker_sparkle")) as AnimatedSprite;
            const position = this.getPositionFromIndex(this.currentPosition);

            sparkle.landscape.x = position.landscape.x;
            sparkle.landscape.y = position.landscape.y;
            sparkle.portrait.x = position.portrait.x;
            sparkle.portrait.y = position.portrait.y;

            sparkle.visible = true;
            sparkle.gotoAndPlayOnce(0).then(() => sparkle.visible = false);
        } else if (action === "start") {
            this.startFootstepSounds();
            animationName = JokerAnimations.STAND_TO_WALK;
        } else if (action === "stop") {
            this.stopFootstepSounds();
            animationName = JokerAnimations.WALK_TO_STAND;
        } else if (this.isMoving) {
            animationName = RandomFromArray([
                JokerAnimations.WALK_CYCLE, JokerAnimations.WALK_CYCLE, JokerAnimations.WALK_CYCLE,
                JokerAnimations.WALK_CYCLE_BLINK,
                JokerAnimations.WALK_CYCLE_EYEMOVE
            ]);
        } else {
            timeScale = 1;
            animationName = JokerAnimations.IDLE;
        }

        animationName = animationName.replace("{dir}", this.currentDirection);

        // logger.log("joker anim: " + animationName);

        this.joker.playOnce(animationName, false, timeScale).execute();
    }

    protected getJokerCurrentClosestPositionIndex(): number {
        let closestIndex: number = 0;
        let closestDistance: number = Number.MAX_SAFE_INTEGER;

        const jokerPoint = this.joker.landscape.coordsToPoint();

        for (let i = 0; i < EJBoardGameComponent.NUM_POSITIONS; i++) {
            const pos = this.getPositionFromIndex(i, true).landscape.coordsToPoint();
            const distance = pos.distanceTo(jokerPoint);
            if (distance < closestDistance) {
                closestIndex = i;
                closestDistance = distance;
            }
        }

        return closestIndex;
    }

    protected getJokerDirectionFromIndex(index: number): Direction {
        if (index >= 18 || index < 6) {
            return Direction.RIGHT;
        }
        return Direction.LEFT;
    }

    protected startFootstepSounds(): void {
        this.stopFootstepSounds();

        const sound = Services.get(SoundService);
        this.footstepSoundInterval = Timer.setInterval(() => {
            const step: number = Math.ceil(Math.random() * 4);
            sound.customEvent(`footstep_${step}`);
        }, 200);
    }

    protected stopFootstepSounds(): void {
        if (this.footstepSoundInterval != null) {
            Timer.clearInterval(this.footstepSoundInterval);
            this.footstepSoundInterval = null;
        }
    }
}
