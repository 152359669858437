import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ButtonElement } from "appworks/graphics/elements/button-element";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { Scene } from "appworks/graphics/layers/scene";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { SoundNames } from "sounds-enum";

class GameLayers {
    public ScreenFade: Layers & {
        scene: {
             black: Scene & {
                contents: {
                    fade?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    fade?: SmartShape
                };
            };
        };
    };
    public MenuButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    ui_settings_menu?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ui_settings_menu?: ButtonElement
                };
            };
             slotbonus: Scene & {
                contents: {
                    ui_settings_menu?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Menus: Layers & {
        scene: {
             settings: Scene & {
                contents: {
                    cover?: SmartShape
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cover?: SmartShape
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    arrow_forward?: ButtonElement
                    arrow_back?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    close?: ButtonElement
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    save?: ButtonElement
                    cancel?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                    preloader?: SpineContainer
                };
            };
             stake: Scene & {
                contents: {
                    arrow_forward?: ButtonElement
                    arrow_back?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    cover?: SmartShape
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             rules: Scene & {
                contents: {
                    close?: ButtonElement
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             play_controls: Scene & {
                contents: {
                    cover?: SmartShape
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    save?: ButtonElement
                    cancel?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             preloader: Scene & {
                contents: {
                    preloader?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CelebrationContent: Layers & {
        scene: {
             bigwin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    bigwin_coin?: Sprite
                    cover?: SmartShape
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    celebrations?: SpineContainer
                    bigwin_coin?: Sprite
                    cover?: SmartShape
                    value?: Text
                    slingo_spine?: SpineContainer
                };
            };
             slingo: Scene & {
                contents: {
                    slingo_spine?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             line_row_0_win: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             line_row_1_win: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             line_row_2_win: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public BoardGame: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    joker_sparkle?: AnimatedSprite
                    dice_1?: Sprite
                    dice_2?: Sprite
                    dice_3?: Sprite
                    dice_4?: Sprite
                    dice_5?: Sprite
                    dice_6?: Sprite
                    joker?: SpineContainer
                    prize_4?: Sprite
                    prize_3?: Sprite
                    prize_2?: Sprite
                    prize_1?: Sprite
                    prize_0?: Sprite
                    win_value?: Text
                    positions: {
                        [key: string]: DualPosition
                        win_value_target?: DualPosition
                        pos_23?: DualPosition
                        pos_22?: DualPosition
                        pos_21?: DualPosition
                        pos_20?: DualPosition
                        pos_19?: DualPosition
                        pos_18?: DualPosition
                        pos_17?: DualPosition
                        pos_16?: DualPosition
                        pos_15?: DualPosition
                        pos_14?: DualPosition
                        pos_13?: DualPosition
                        pos_12?: DualPosition
                        pos_11?: DualPosition
                        pos_10?: DualPosition
                        pos_9?: DualPosition
                        pos_8?: DualPosition
                        pos_7?: DualPosition
                        pos_6?: DualPosition
                        pos_5?: DualPosition
                        pos_4?: DualPosition
                        pos_3?: DualPosition
                        pos_2?: DualPosition
                        pos_1?: DualPosition
                        pos_0?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        win_value_target?: DualPosition
                        pos_23?: DualPosition
                        pos_22?: DualPosition
                        pos_21?: DualPosition
                        pos_20?: DualPosition
                        pos_19?: DualPosition
                        pos_18?: DualPosition
                        pos_17?: DualPosition
                        pos_16?: DualPosition
                        pos_15?: DualPosition
                        pos_14?: DualPosition
                        pos_13?: DualPosition
                        pos_12?: DualPosition
                        pos_11?: DualPosition
                        pos_10?: DualPosition
                        pos_9?: DualPosition
                        pos_8?: DualPosition
                        pos_7?: DualPosition
                        pos_6?: DualPosition
                        pos_5?: DualPosition
                        pos_4?: DualPosition
                        pos_3?: DualPosition
                        pos_2?: DualPosition
                        pos_1?: DualPosition
                        pos_0?: DualPosition
                    };
                    joker_sparkle?: AnimatedSprite
                    dice_1?: Sprite
                    dice_2?: Sprite
                    dice_3?: Sprite
                    dice_4?: Sprite
                    dice_5?: Sprite
                    dice_6?: Sprite
                    joker?: SpineContainer
                    prize_4?: Sprite
                    prize_3?: Sprite
                    prize_2?: Sprite
                    prize_1?: Sprite
                    prize_0?: Sprite
                    win_value?: Text
                };
            };
        };
    };
    public BetBar: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    ui_stake_menu?: ButtonElement
                    slot_icon?: Sprite
                    total_win_bg?: Sprite
                    stripe?: Sprite
                    bg_gameid?: Sprite
                    stripe3?: Sprite
                    stripe2?: Sprite
                    bg_time?: Sprite
                    slot_spins_value?: Text
                    total_win_value?: Text
                    total_win_label?: Text
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ui_stake_menu?: ButtonElement
                    slot_icon?: Sprite
                    total_win_bg?: Sprite
                    stripe?: Sprite
                    bg_gameid?: Sprite
                    stripe3?: Sprite
                    stripe2?: Sprite
                    bg_time?: Sprite
                    slot_spins_value?: Text
                    total_win_value?: Text
                    total_win_label?: Text
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    bg0?: Sprite
                    bg2?: Sprite
                    bg3?: Sprite
                    bg4?: Sprite
                    line_stake_bg?: Sprite
                    lines_bg?: Sprite
                    current_win_bg?: Sprite
                    lines_label?: Text
                    lines_value?: Text
                    line_stake_label?: Text
                    line_stake_value?: Text
                    current_win_label?: Text
                    current_win_value?: Text
                    slot_win_label?: Text
                    slot_win_value?: Text
                    slingo_stake_label?: Text
                    slingo_stake_value?: Text
                };
            };
             slotbonus: Scene & {
                contents: {
                    bg0?: Sprite
                    bg2?: Sprite
                    bg3?: Sprite
                    bg4?: Sprite
                    stripe?: Sprite
                    total_win_bg?: Sprite
                    line_stake_bg?: Sprite
                    lines_bg?: Sprite
                    current_win_bg?: Sprite
                    bg_gameid?: Sprite
                    stripe3?: Sprite
                    stripe2?: Sprite
                    bg_time?: Sprite
                    lines_label?: Text
                    lines_value?: Text
                    line_stake_label?: Text
                    line_stake_value?: Text
                    current_win_label?: Text
                    current_win_value?: Text
                    slot_win_label?: Text
                    slot_win_value?: Text
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             wheelbonus: Scene & {
                contents: {
                    stripe?: Sprite
                    bg_gameid?: Sprite
                    stripe3?: Sprite
                    stripe2?: Sprite
                    bg_time?: Sprite
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake_label?: Text
                    slingo_stake_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CollectBonusValues: Layers & {
        scene: {
             wheel_and_slot: Scene & {
                contents: {
                    pw_wheel_icon?: Sprite
                    pw_slot_icon?: Sprite
                    pw_wheel_value?: Text
                    pw_slot_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    pw_wheel_icon?: Sprite
                    pw_slot_icon?: Sprite
                    pw_wheel_value?: Text
                    pw_slot_value?: Text
                };
            };
             slot: Scene & {
                contents: {
                    pw_slot_icon?: Sprite
                    pw_slot_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Collect: Layers & {
        scene: {
             collect: Scene & {
                contents: {
                    purchase?: ButtonElement
                    slot_icon?: Sprite
                    collect_slot?: ButtonElement
                    collect?: ButtonElement
                    potential_win_frame?: Sprite
                    slot_spins_value?: Text
                    potential_win_body_bonus?: Text
                    potential_win_body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    purchase?: ButtonElement
                    slot_icon?: Sprite
                    collect_slot?: ButtonElement
                    collect?: ButtonElement
                    potential_win_frame?: Sprite
                    slot_spins_value?: Text
                    potential_win_body_bonus?: Text
                    potential_win_body?: Text
                };
            };
        };
    };
    public SpinCounter: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                        sparkStart_0?: DualPosition
                        sparkStart_1?: DualPosition
                        sparkStart_2?: DualPosition
                        sparkStart_3?: DualPosition
                        sparkStart_4?: DualPosition
                        sparkMidPoint?: DualPosition
                        sparkTarget?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        sparkStart_0?: DualPosition
                        sparkStart_1?: DualPosition
                        sparkStart_2?: DualPosition
                        sparkStart_3?: DualPosition
                        sparkStart_4?: DualPosition
                        sparkMidPoint?: DualPosition
                        sparkTarget?: DualPosition
                    };
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    bg1?: Sprite
                    bg?: Sprite
                    spins_label?: Text
                };
            };
             slotbonus: Scene & {
                contents: {
                    bg1?: Sprite
                    bg?: Sprite
                    spins_label?: Text
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Logo: Layers & {
        scene: {
             slotbonus: Scene & {
                contents: {
                    logo?: Sprite
                    logo4Lewis?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    logo?: Sprite
                    logo4Lewis?: Sprite
                };
            };
             default: Scene & {
                contents: {
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Footer: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    value?: Text
                };
            };
        };
    };
    public SpinButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    spin?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    spin?: ButtonElement
                };
            };
             slotbonus: Scene & {
                contents: {
                    spin?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public SlingoLadder: Layers & {
        scene: {
             paytable: Scene & {
                contents: {
                    ladder_celebration?: AnimatedSprite
                    ladder?: Sprite
                    level_12?: Sprite
                    level_10?: Sprite
                    level_9?: Sprite
                    level_8?: Sprite
                    level_7?: Sprite
                    level_6?: Sprite
                    level_5?: Sprite
                    level_4?: Sprite
                    level_3?: Sprite
                    level_2?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    active_12?: Sprite
                    active_10?: Sprite
                    active_9?: Sprite
                    active_8?: Sprite
                    active_7?: Sprite
                    active_6?: Sprite
                    active_5?: Sprite
                    active_4?: Sprite
                    active_3?: Sprite
                    active_2?: Sprite
                    active_1?: Sprite
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    bg?: Sprite
                    cover?: SmartShape
                    level_1?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ladder_celebration?: AnimatedSprite
                    ladder?: Sprite
                    level_12?: Sprite
                    level_10?: Sprite
                    level_9?: Sprite
                    level_8?: Sprite
                    level_7?: Sprite
                    level_6?: Sprite
                    level_5?: Sprite
                    level_4?: Sprite
                    level_3?: Sprite
                    level_2?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    active_12?: Sprite
                    active_10?: Sprite
                    active_9?: Sprite
                    active_8?: Sprite
                    active_7?: Sprite
                    active_6?: Sprite
                    active_5?: Sprite
                    active_4?: Sprite
                    active_3?: Sprite
                    active_2?: Sprite
                    active_1?: Sprite
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    bg?: Sprite
                    cover?: SmartShape
                    level_1?: Text
                    ladder_celebration_portrait?: AnimatedSprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_active_12?: Sprite
                    p_active_10?: Sprite
                    p_active_9?: Sprite
                    p_active_8?: Sprite
                    p_active_7?: Sprite
                    p_active_6?: Sprite
                    p_active_5?: Sprite
                    p_active_4?: Sprite
                    p_active_3?: Sprite
                    p_active_2?: Sprite
                    p_active_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_ladder?: Sprite
                    ui_paytable?: ButtonElement
                    p_label_12?: Text
                    p_label_10?: Text
                    p_label_9?: Text
                    p_label_8?: Text
                    p_label_7?: Text
                    p_label_6?: Text
                    p_label_5?: Text
                    p_label_4?: Text
                    p_label_3?: Text
                    p_label_2?: Text
                    p_label_1?: Text
                };
            };
             base: Scene & {
                contents: {
                    ladder_celebration_portrait?: AnimatedSprite
                    p_highlight_12?: Sprite
                    p_highlight_10?: Sprite
                    p_highlight_9?: Sprite
                    p_highlight_8?: Sprite
                    p_highlight_7?: Sprite
                    p_highlight_6?: Sprite
                    p_highlight_5?: Sprite
                    p_highlight_4?: Sprite
                    p_highlight_3?: Sprite
                    p_highlight_2?: Sprite
                    p_highlight_1?: Sprite
                    p_active_12?: Sprite
                    p_active_10?: Sprite
                    p_active_9?: Sprite
                    p_active_8?: Sprite
                    p_active_7?: Sprite
                    p_active_6?: Sprite
                    p_active_5?: Sprite
                    p_active_4?: Sprite
                    p_active_3?: Sprite
                    p_active_2?: Sprite
                    p_active_1?: Sprite
                    p_fill_12?: Sprite
                    p_fill_10?: Sprite
                    p_fill_9?: Sprite
                    p_fill_8?: Sprite
                    p_fill_7?: Sprite
                    p_fill_6?: Sprite
                    p_fill_5?: Sprite
                    p_fill_4?: Sprite
                    p_fill_3?: Sprite
                    p_fill_2?: Sprite
                    p_fill_1?: Sprite
                    p_ladder?: Sprite
                    ui_paytable?: ButtonElement
                    ladder_celebration?: AnimatedSprite
                    ladder?: Sprite
                    level_12?: Sprite
                    level_10?: Sprite
                    level_9?: Sprite
                    level_8?: Sprite
                    level_7?: Sprite
                    level_6?: Sprite
                    level_5?: Sprite
                    level_4?: Sprite
                    level_3?: Sprite
                    level_2?: Sprite
                    highlight_12?: Sprite
                    highlight_10?: Sprite
                    highlight_9?: Sprite
                    highlight_8?: Sprite
                    highlight_7?: Sprite
                    highlight_6?: Sprite
                    highlight_5?: Sprite
                    highlight_4?: Sprite
                    highlight_3?: Sprite
                    highlight_2?: Sprite
                    highlight_1?: Sprite
                    active_12?: Sprite
                    active_10?: Sprite
                    active_9?: Sprite
                    active_8?: Sprite
                    active_7?: Sprite
                    active_6?: Sprite
                    active_5?: Sprite
                    active_4?: Sprite
                    active_3?: Sprite
                    active_2?: Sprite
                    active_1?: Sprite
                    fill_12?: Sprite
                    fill_10?: Sprite
                    fill_9?: Sprite
                    fill_8?: Sprite
                    fill_7?: Sprite
                    fill_6?: Sprite
                    fill_5?: Sprite
                    fill_4?: Sprite
                    fill_3?: Sprite
                    fill_2?: Sprite
                    fill_1?: Sprite
                    bg?: Sprite
                    p_label_12?: Text
                    p_label_10?: Text
                    p_label_9?: Text
                    p_label_8?: Text
                    p_label_7?: Text
                    p_label_6?: Text
                    p_label_5?: Text
                    p_label_4?: Text
                    p_label_3?: Text
                    p_label_2?: Text
                    p_label_1?: Text
                    level_1?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public TicketMatrix: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    master_highlight?: AnimatedSprite
                    positions: {
                        [key: string]: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                    master_highlight?: AnimatedSprite
                };
            };
        };
    };
    public TicketMatrixBacking: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    grid?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    grid?: Sprite
                };
            };
        };
    };
    public MatrixContent: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        J?: DualPosition
                        CL?: DualPosition
                        S?: DualPosition
                        SJ?: DualPosition
                        FS?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        J?: DualPosition
                        CL?: DualPosition
                        S?: DualPosition
                        SJ?: DualPosition
                        FS?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                        H1?: DualPosition
                        H2?: DualPosition
                        L1?: DualPosition
                        L2?: DualPosition
                        L3?: DualPosition
                        L4?: DualPosition
                        M1?: DualPosition
                        M2?: DualPosition
                        SC?: DualPosition
                        WI?: DualPosition
                        symbol_x_y?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                    };
                };
            };
             slotbonus: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        H1?: DualPosition
                        H2?: DualPosition
                        L1?: DualPosition
                        L2?: DualPosition
                        L3?: DualPosition
                        L4?: DualPosition
                        M1?: DualPosition
                        M2?: DualPosition
                        SC?: DualPosition
                        WI?: DualPosition
                        static?: DualPosition
                        symbol_x_y?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                    };
                };
            };
        };
    };
    public SymbolAnimations: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    wild_fg_4?: SpineContainer
                    wild_fg_3?: SpineContainer
                    wild_fg_2?: SpineContainer
                    wild_fg_1?: SpineContainer
                    wild_fg_0?: SpineContainer
                };
            };
             slotbonus: Scene & {
                contents: {
                    wild_fg_4?: SpineContainer
                    wild_fg_3?: SpineContainer
                    wild_fg_2?: SpineContainer
                    wild_fg_1?: SpineContainer
                    wild_fg_0?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MatrixBackground: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    anticipation?: AnimatedSprite
                    bottom_strip?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    anticipation?: AnimatedSprite
                    bottom_strip?: Sprite
                    reel_bg?: Sprite
                };
            };
             slotbonus: Scene & {
                contents: {
                    reel_bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Background: Layers & {
        scene: {
             main: Scene & {
                contents: {
                    background_portrait?: SpineContainer
                    background_landscape?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    background_portrait?: SpineContainer
                    background_landscape?: SpineContainer
                    portrait_bg_anima?: Sprite
                    game_bg_anima?: Sprite
                };
            };
             preloader: Scene & {
                contents: {
                    portrait_bg_anima?: Sprite
                    game_bg_anima?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             default: Scene & {
                contents: {
                    portrait_bg_anima?: Sprite
                    game_bg_anima?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             onboard: Scene & {
                contents: {
                    portrait_bg_anima?: Sprite
                    game_bg_anima?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public OnboardPages: Layers & {
        scene: {
             page_4: Scene & {
                contents: {
                    dab_4?: Sprite
                    img?: Sprite
                    total_win_value?: Text
                    total_win_label?: Text
                    txt_spin?: Text
                    txt_collect?: Text
                    text?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    dab_4?: Sprite
                    img?: Sprite
                    total_win_value?: Text
                    total_win_label?: Text
                    txt_spin?: Text
                    txt_collect?: Text
                    text?: Text
                    dab_3?: Sprite
                    FRAME?: Sprite
                    dab_2?: Sprite
                    centre?: Sprite
                    dab_1?: Sprite
                    board?: Sprite
                };
            };
             page_3: Scene & {
                contents: {
                    dab_3?: Sprite
                    FRAME?: Sprite
                    text?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             page_2: Scene & {
                contents: {
                    dab_2?: Sprite
                    centre?: Sprite
                    text?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             page_1: Scene & {
                contents: {
                    dab_1?: Sprite
                    board?: Sprite
                    text?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Onboard: Layers & {
        scene: {
             onboard: Scene & {
                contents: {
                    page_1?: ButtonElement
                    page_2?: ButtonElement
                    page_3?: ButtonElement
                    page_4?: ButtonElement
                    frame?: Sprite
                    continue?: ButtonElement
                    logo?: Sprite
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    version?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    page_1?: ButtonElement
                    page_2?: ButtonElement
                    page_3?: ButtonElement
                    page_4?: ButtonElement
                    frame?: Sprite
                    continue?: ButtonElement
                    logo?: Sprite
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    version?: Text
                };
            };
        };
    };
    public Prompts: Layers & {
        scene: {
             bonusmoney: Scene & {
                contents: {
                    cover?: SmartShape
                    tc?: ButtonElement
                    ok?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cover?: SmartShape
                    tc?: ButtonElement
                    ok?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    fh_frame?: Sprite
                    rainbow?: Sprite
                    win_breakdown?: Text
                    total_win?: Text
                    no?: ButtonElement
                    yes?: ButtonElement
                    continuenooptions?: ButtonElement
                    continue?: ButtonElement
                    history?: ButtonElement
                    logout?: ButtonElement
                };
            };
             gamesummary_nowin: Scene & {
                contents: {
                    cover?: SmartShape
                    frame?: Sprite
                    title?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             gamesummary_win: Scene & {
                contents: {
                    cover?: SmartShape
                    fh_frame?: Sprite
                    frame?: Sprite
                    rainbow?: Sprite
                    title?: Text
                    win_breakdown?: Text
                    total_win?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             yes_no: Scene & {
                contents: {
                    cover?: SmartShape
                    no?: ButtonElement
                    yes?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             realitycheck: Scene & {
                contents: {
                    cover?: SmartShape
                    continuenooptions?: ButtonElement
                    continue?: ButtonElement
                    history?: ButtonElement
                    logout?: ButtonElement
                    frame?: Sprite
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             info: Scene & {
                contents: {
                    cover?: SmartShape
                    continue?: ButtonElement
                    frame?: Sprite
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Skip: Layers & {
        scene: {
             skip: Scene & {
                contents: {
                    skip?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    skip?: ButtonElement
                };
            };
        };
    };
    public Paylines: Layers & {
        scene: {
             slotbonus: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MatrixForeground: Layers & {
        scene: {
             slotbonus: Scene & {
                contents: {
                    frame?: Sprite
                    wild_bg_4?: SpineContainer
                    wild_bg_3?: SpineContainer
                    wild_bg_2?: SpineContainer
                    wild_bg_1?: SpineContainer
                    wild_bg_0?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    frame?: Sprite
                    wild_bg_4?: SpineContainer
                    wild_bg_3?: SpineContainer
                    wild_bg_2?: SpineContainer
                    wild_bg_1?: SpineContainer
                    wild_bg_0?: SpineContainer
                };
            };
        };
    };
    public WheelBonus: Layers & {
        scene: {
             wheelbonus: Scene & {
                contents: {
                    slot_icon?: Sprite
                    bg3?: Sprite
                    bg2?: Sprite
                    freespin_spark?: Sprite
                    current_win_bg?: Sprite
                    bg?: Sprite
                    centre?: Sprite
                    indicator_0?: Sprite
                    wheel0_multiplier_2?: Sprite
                    wheel0_multiplier_1?: Sprite
                    wheel0_slots_3?: Sprite
                    wheel0_slots_2?: Sprite
                    wheel0_multiplier_05?: Sprite
                    wheel0_multiplier_02?: Sprite
                    wheel0_next_tier?: Sprite
                    dim_0?: Sprite
                    wheel0_overlay?: Sprite
                    wheel0?: Sprite
                    indicator_1?: Sprite
                    dim_1?: Sprite
                    wheel1_multiplier_10?: Sprite
                    wheel1_multiplier_5?: Sprite
                    wheel1_multiplier_3?: Sprite
                    wheel1_multiplier_2?: Sprite
                    wheel1_next_tier?: Sprite
                    wheel1_slots_8?: Sprite
                    wheel1_slots_6?: Sprite
                    wheel1_overlay?: Sprite
                    wheel1?: Sprite
                    indicator_2?: Sprite
                    dim_2?: Sprite
                    wheel2_slots_20?: Sprite
                    wheel2_slots_10?: Sprite
                    wheel2_multiplier_250?: Sprite
                    wheel2_multiplier_100?: Sprite
                    wheel2_multiplier_50?: Sprite
                    wheel2_multiplier_25?: Sprite
                    wheel2_multiplier_10?: Sprite
                    wheel2_overlay?: Sprite
                    wheel2?: Sprite
                    current_win_label?: Text
                    slot_spins_value?: Text
                    current_win_value_small?: Text
                    current_win_value?: Text
                    spins_label?: Text
                    spins_remaining_value?: Text
                    spin_start_text?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    slot_icon?: Sprite
                    bg3?: Sprite
                    bg2?: Sprite
                    freespin_spark?: Sprite
                    current_win_bg?: Sprite
                    bg?: Sprite
                    centre?: Sprite
                    indicator_0?: Sprite
                    wheel0_multiplier_2?: Sprite
                    wheel0_multiplier_1?: Sprite
                    wheel0_slots_3?: Sprite
                    wheel0_slots_2?: Sprite
                    wheel0_multiplier_05?: Sprite
                    wheel0_multiplier_02?: Sprite
                    wheel0_next_tier?: Sprite
                    dim_0?: Sprite
                    wheel0_overlay?: Sprite
                    wheel0?: Sprite
                    indicator_1?: Sprite
                    dim_1?: Sprite
                    wheel1_multiplier_10?: Sprite
                    wheel1_multiplier_5?: Sprite
                    wheel1_multiplier_3?: Sprite
                    wheel1_multiplier_2?: Sprite
                    wheel1_next_tier?: Sprite
                    wheel1_slots_8?: Sprite
                    wheel1_slots_6?: Sprite
                    wheel1_overlay?: Sprite
                    wheel1?: Sprite
                    indicator_2?: Sprite
                    dim_2?: Sprite
                    wheel2_slots_20?: Sprite
                    wheel2_slots_10?: Sprite
                    wheel2_multiplier_250?: Sprite
                    wheel2_multiplier_100?: Sprite
                    wheel2_multiplier_50?: Sprite
                    wheel2_multiplier_25?: Sprite
                    wheel2_multiplier_10?: Sprite
                    wheel2_overlay?: Sprite
                    wheel2?: Sprite
                    current_win_label?: Text
                    slot_spins_value?: Text
                    current_win_value_small?: Text
                    current_win_value?: Text
                    spins_label?: Text
                    spins_remaining_value?: Text
                    spin_start_text?: Text
                };
            };
        };
    };
}

export enum LayerNames {
    ScreenFade = "screenfade",
    MenuButton = "menubutton",
    Menus = "menus",
    CelebrationContent = "celebrationcontent",
    BoardGame = "boardgame",
    BetBar = "betbar",
    CollectBonusValues = "collectbonusvalues",
    Collect = "collect",
    SpinCounter = "spincounter",
    Logo = "logo",
    Footer = "footer",
    SpinButton = "spinbutton",
    SlingoLadder = "slingoladder",
    TicketMatrix = "ticketmatrix",
    TicketMatrixBacking = "ticketmatrixbacking",
    MatrixContent = "matrixcontent",
    SymbolAnimations = "symbolanimations",
    MatrixBackground = "matrixbackground",
    Background = "background",
    OnboardPages = "onboardpages",
    Onboard = "onboard",
    Prompts = "prompts",
    Skip = "skip",
    Paylines = "paylines",
    MatrixForeground = "matrixforeground",
    WheelBonus = "wheelbonus"
}

export const gameLayers = new GameLayers();
export const assetworksData = {gameLayers, LayerNames, SoundNames};
