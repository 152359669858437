import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { flashWhite, winSanity } from "ej-utils";
import { gameLayers } from "game-layers";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoPickJokerState } from "slingo/states/slingo-pick-joker-state";

export class EJPickJokerState extends SlingoPickJokerState {
    public onExit(): void {
        const record = gameState.getCurrentGame().getCurrentRecord();
        let slotSpinsToBeWonOnWheel = 0;
        for (const result of record.getResultsOfType(EJBonusWheelResult)) {
            if (!result.played) { slotSpinsToBeWonOnWheel += result.slotSpinsWon; }
        }
        Components.get(EJUICounterComponent).updateSlotSpinsCounter(
            (gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult) as any).prizes - slotSpinsToBeWonOnWheel
        );

        winSanity();
    }

    protected updateTotalWin(): Contract<void> {
        const winValueText = gameLayers.BetBar.getText("total_win_value");

        const record = gameState.getCurrentGame().getCurrentRecord();
        const ladderWin = record.getFirstResultOfType(SlingoLadderResult).cashWon;

        if (ladderWin === 0) { return super.updateTotalWin(); }

        return new Parallel([
            () => super.updateTotalWin(),
            () => flashWhite(winValueText, 500),
            () => Contract.getTimeoutContract(1250)
        ]);
    }
}
