import { gameLoop } from "appworks/core/game-loop";
import { State } from "appworks/state-machine/states/state";
import { Timer } from "appworks/utils/timer";

export class SpeedSkipState extends State {
    protected allowSkipDelay: number = 250;
    protected skipSpeed: number = 2;

    private allowSkip: boolean;
    private delayTimer: number;

    public onEnter() {
        if (this.allowSkipDelay > 0) {
            this.allowSkip = false;
            this.delayTimer = Timer.setTimeout(() => {
                this.allowSkip = true;
            }, this.allowSkipDelay);
        } else {
            this.allowSkip = true;
        }

        super.onEnter();
    }

    public onSkip() {
        if (this.allowSkip) {
            gameLoop.setSpeed(this.skipSpeed);
        }
    }

    public onExit() {
        Timer.clearTimeout(this.delayTimer);
        gameLoop.setSpeed(1);
    }
}
