import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { Services } from "appworks/services/services";
import { State } from "appworks/state-machine/states/state";
import { reject } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { HideAllLinesCommand } from "slotworks/commands/lines/hide-all-lines-command";
import { ShowLinesCommand } from "slotworks/commands/lines/show-lines-command";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { AbstractMatrixComponent, DimType } from "slotworks/components/matrix/abstract-matrix-component";
import { LineResult } from "slotworks/model/gameplay/records/results/line-result";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { WaysResult } from "slotworks/model/gameplay/records/results/ways-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export enum SortMode {
    NONE,
    ASCENDING,
    DESCENDING
}

export interface LoopWinsConfig {
    minWinTime: number;
    maxWinTime: number;
    delay: number;
    dimSymbols: boolean;
    sortMode: SortMode;
    resetSymbols: boolean;
    /** Change this if using this state with a secondary matrix component */
    matrixComponentType: { new (...args: any[]): AbstractMatrixComponent };
}

export class LoopWinsState extends State {

    protected config: LoopWinsConfig = {
        minWinTime: 0,
        maxWinTime: Infinity,
        delay: 0,
        dimSymbols: true,
        sortMode: SortMode.NONE,
        resetSymbols: true,
        matrixComponentType: AbstractMatrixComponent
    };

    protected recordCycleCount: Map<Record, number>;

    protected wins: SymbolWinResult[];
    protected currentWin: number;

    constructor(config?: Partial<LoopWinsConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter() {
        if (!this.recordCycleCount) {
            this.recordCycleCount = new Map<Record, number>();
            gameState.onNewGame.add(() => this.recordCycleCount.clear());
        }

        this.startLoop().then(() => this.complete());
    }

    public onSkip() {
        this.skip();
    }

    public onExit() {
        this.cancelGroup.cancel();

        this.wins = null;
        if (this.config.resetSymbols) {
            Components.get(this.config.matrixComponentType).resetSymbols();
        }
        HideAllLinesCommand().execute();
        Components.get(CelebrateWinComponent).hide();
    }

    public startLoop(): Contract<void> {
        if (gameState.currentRecordHasSymbolWinResults()) {

            const record = gameState.getCurrentGame().getCurrentRecord() as SpinRecord;

            if (this.recordCycleCount.has(record)) {
                this.recordCycleCount.set(record, this.recordCycleCount.get(record) + 1);
            } else {
                this.recordCycleCount.set(record, 0);
            }

            this.wins = this.getWinsToShow(record);

            this.currentWin = 0;

            return this.cancelGroup.contract((resolve) => {
                this.nextWin(resolve);
            });
        } else {
            return Contract.empty();
        }
    }

    protected getWinsToShow(record: Record): SymbolWinResult[] {
        let wins = record.getResultsOfType(SymbolWinResult);
        if (this.config.sortMode === SortMode.DESCENDING) {
            wins = wins.sort((a, b) => b.cashWon - a.cashWon);
        } else if (this.config.sortMode === SortMode.ASCENDING) {
            wins = wins.sort((a, b) => a.cashWon - b.cashWon);
        }
        return wins;
    }

    protected nextWin(onComplete: Function) {

        const raceFuncs: Array<() => Contract<any>> = [];

        const win = this.wins[this.currentWin];
        const symbol = slotDefinition.getSymbolDefinition(win.symbolType);
        const symbolName = symbol ? symbol.name : "";

        if (win instanceof LineResult) {
            const lineDef = slotDefinition.lines.values[win.line - 1];
            const middleSymbolRow = lineDef[Math.floor(lineDef.length * 0.5)].y;

            ShowLinesCommand([win.line]).execute();
            raceFuncs.push(() => Components.get(CelebrateWinComponent).lineWin(win, symbolName, middleSymbolRow, false));
        } else if (win instanceof WaysResult) {
            raceFuncs.push(() => Components.get(CelebrateWinComponent).waysWin(win, symbolName));
        } else if (win.cashWon > 0) {
            raceFuncs.push(() => Components.get(CelebrateWinComponent).lineWin(win as LineResult, symbolName, null, false));
        }

        raceFuncs.push(() => this.showWin(win));

        this.cancelGroup.parallel(raceFuncs).then(
            () => {
                const resetAndNext = () => {
                    if (!this.wins) {
                        return;
                    }

                    if (this.config.resetSymbols) {
                        Components.get(this.config.matrixComponentType).resetSymbols();
                    }
                    HideAllLinesCommand().execute();

                    this.currentWin++;
                    if (this.currentWin < this.wins.length) {
                        if (this.nextWin) {
                            this.nextWin(onComplete);
                        }
                    } else {
                        onComplete();
                    }
                }
                if (this.config.delay > 0) {
                    this.cancelGroup.timeout(resetAndNext, this.config.delay);
                } else {
                    resetAndNext();
                }
            }
        );
    }

    protected showWin(win: SymbolWinResult): Contract<void> {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SpinRecord;

        const winningSymbols = Components.get(this.config.matrixComponentType).getSymbolsFromPositions(win.positions);

        const currentCycle = this.recordCycleCount.get(record);

        if (record.cashWon / Services.get(SlotBetService).getTotalStake() >= Components.get(CelebrateWinComponent).config.totalWinThreshold) {
            // TODO: Refactor sound logic into CelebrateWinComponent
            Components.get(this.config.matrixComponentType).playSymbolSound(win.symbolType, win.matches.toString(), currentCycle.toString());
        }

        const remainingSymbols = reject(Components.get(this.config.matrixComponentType).getBaseGridSymbols(), winningSymbols);

        if (this.config.dimSymbols) {
            const hiddenSymbols = Components.get(this.config.matrixComponentType).getHiddenSymbols();
            Components.get(this.config.matrixComponentType).dimSymbols(remainingSymbols.concat(hiddenSymbols), DimType.Cycle, win).execute();
        }

        if (!winningSymbols.length) {
            return Contract.getTimeoutContract(1000);
        }

        const contract = this.cancelGroup.parallel([
            () => Components.get(this.config.matrixComponentType).showWinningSymbols(winningSymbols, win, currentCycle > 0),
            () => Contract.getTimeoutContract(this.config.minWinTime)
        ]);

        if (this.config.maxWinTime < Infinity) {
            Timer.setTimeout(() => {
                contract.forceResolve();
            }, this.config.maxWinTime);
        }

        return contract;
    }
}
