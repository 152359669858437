
export enum SoundNames {
    LYJ_MUS_BaseGame_Loop = "LYJ_MUS_BaseGame_Loop",
    LYJ_MUS_BonusWheel_Loop = "LYJ_MUS_BonusWheel_Loop",
    LYJ_MUS_SlotBonus_Loop = "LYJ_MUS_SlotBonus_Loop",
    LYJ_SFX_BaseGame_Anticipation_Loop = "LYJ_SFX_BaseGame_Anticipation_Loop",
    LYJ_SFX_BaseGame_Attract_Popup = "LYJ_SFX_BaseGame_Attract_Popup",
    LYJ_SFX_BaseGame_BonusSymbol_Land = "LYJ_SFX_BaseGame_BonusSymbol_Land",
    LYJ_SFX_BaseGame_CashPrize_Win = "LYJ_SFX_BaseGame_CashPrize_Win",
    LYJ_SFX_BaseGame_Countup_End = "LYJ_SFX_BaseGame_Countup_End",
    LYJ_SFX_BaseGame_Countup_Loop = "LYJ_SFX_BaseGame_Countup_Loop",
    LYJ_SFX_BaseGame_DiceRoll = "LYJ_SFX_BaseGame_DiceRoll",
    LYJ_SFX_BaseGame_EndGame_NoWin = "LYJ_SFX_BaseGame_EndGame_NoWin",
    LYJ_SFX_BaseGame_EndGame_Win = "LYJ_SFX_BaseGame_EndGame_Win",
    LYJ_SFX_BaseGame_FreeSpin_Collect = "LYJ_SFX_BaseGame_FreeSpin_Collect",
    LYJ_SFX_BaseGame_FreeSpin_End = "LYJ_SFX_BaseGame_FreeSpin_End",
    LYJ_SFX_BaseGame_FreeSpin_Land = "LYJ_SFX_BaseGame_FreeSpin_Land",
    LYJ_SFX_BaseGame_Leprechaun_Footstep01 = "LYJ_SFX_BaseGame_Leprechaun_Footstep01",
    LYJ_SFX_BaseGame_Leprechaun_Footstep02 = "LYJ_SFX_BaseGame_Leprechaun_Footstep02",
    LYJ_SFX_BaseGame_Leprechaun_Footstep03 = "LYJ_SFX_BaseGame_Leprechaun_Footstep03",
    LYJ_SFX_BaseGame_Leprechaun_Footstep04 = "LYJ_SFX_BaseGame_Leprechaun_Footstep04",
    LYJ_SFX_BaseGame_NumberDab = "LYJ_SFX_BaseGame_NumberDab",
    LYJ_SFX_BaseGame_ReelSpin_Land = "LYJ_SFX_BaseGame_ReelSpin_Land",
    LYJ_SFX_BaseGame_ReelSpin_Loop = "LYJ_SFX_BaseGame_ReelSpin_Loop",
    LYJ_SFX_BaseGame_Slingo_Celebration = "LYJ_SFX_BaseGame_Slingo_Celebration",
    LYJ_SFX_BaseGame_UI_Click = "LYJ_SFX_BaseGame_UI_Click",
    LYJ_SFX_BaseGame_WildLand = "LYJ_SFX_BaseGame_WildLand",
    LYJ_SFX_BaseGame_WildLandSuper = "LYJ_SFX_BaseGame_WildLandSuper",
    LYJ_SFX_BigWin_Epic_Loop = "LYJ_SFX_BigWin_Epic_Loop",
    LYJ_SFX_BigWin_Epic_Start = "LYJ_SFX_BigWin_Epic_Start",
    LYJ_SFX_BigWin_SuperEpic_Loop = "LYJ_SFX_BigWin_SuperEpic_Loop",
    LYJ_SFX_BigWin_SuperEpic_Start = "LYJ_SFX_BigWin_SuperEpic_Start",
    LYJ_SFX_BigWin_Terminator = "LYJ_SFX_BigWin_Terminator",
    LYJ_SFX_BigWin_Win_Loop = "LYJ_SFX_BigWin_Win_Loop",
    LYJ_SFX_BigWin_Win_Start = "LYJ_SFX_BigWin_Win_Start",
    LYJ_SFX_BonusWheel_Pulse = "LYJ_SFX_BonusWheel_Pulse",
    LYJ_SFX_BonusWheel_Spin = "LYJ_SFX_BonusWheel_Spin",
    LYJ_SFX_SlotBonus_Wild_Appear = "LYJ_SFX_SlotBonus_Wild_Appear",
    LYJ_SFX_SlotBonus_WinLine = "LYJ_SFX_SlotBonus_WinLine",
    LYJ_SFX_SlotBonus_WinLines = "LYJ_SFX_SlotBonus_WinLines"
}
