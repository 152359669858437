import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { EJBoardGameComponent } from "components/ej-board-game-component";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { winSanity } from "ej-utils";
import { EJBoardGameResult } from "model/results/ej-board-game-result";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoRecoveryState } from "slingo/states/slingo-recovery-state";

export class EJRecoveryState extends SlingoRecoveryState {
    public onEnter(): void {
        super.onEnter();

        const boardResult = gameState.getCurrentGame().getLatestResultOfType(EJBoardGameResult);
        Components.get(EJBoardGameComponent).moveJokerToPosition(boardResult.jokerPosition, 0).execute();
        Components.get(EJBoardGameComponent).updatePrizePositions(boardResult.prizePositions).execute();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        let slotSpinsToBeWonOnWheel = 0;
        for (const result of record.getResultsOfType(EJBonusWheelResult)) {
            if (!result.played) { slotSpinsToBeWonOnWheel += result.slotSpinsWon; }
        }

        const progressResult = gameplay.getLatestResultOfType(SlingoGameProgressResult);
        const ladderResult = gameplay.getLatestResultOfType(SlingoLadderResult);
        const ladderPrizes = ladderResult.otherPrizes ?? {};
        const slotSpinsWonFromLadder = parseInt(ladderPrizes["slots-luckyjoker"] ?? 0);
        Components.get(EJUICounterComponent).updateSlotSpinsCounter((progressResult as any).prizes - slotSpinsToBeWonOnWheel - slotSpinsWonFromLadder);

        winSanity();
    }
}
