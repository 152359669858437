import { SlingoReelMatrixFactory } from "slingo/components/factories/slingo-reel-matrix-factory";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { SpinStageEvents } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { AnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/animate-symbol-behaviour";
import { ZoomSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/zoom-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class EJReelMatrixFactory extends SlingoReelMatrixFactory {
    constructor() {
        super();

        this.spinStages.find((stage) => stage.name === "stop").events[0].delay = 125;

        this.skipStages = [
            {
                name: "stop",
                s: -this.calculateMinStopDistance(),
                t: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 100
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "complete",
                s: 0,
                t: 0
            }
        ];
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        super.addSymbolBehaviours(matrix);

        matrix.addDefaultSymbolBehaviour((symbol: SymbolSubcomponent) => new AnimateSymbolBehaviour(symbol));
        matrix.addDefaultSymbolBehaviour((symbol: SymbolSubcomponent) => new ZoomSymbolBehaviour(symbol, 0, 125, false, 1.25), ["CL", "S"]);
    }

    protected calculateMinStopDistance(): number {
        return super.calculateMinStopDistance() + 2;
    }
}
