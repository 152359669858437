import { AbstractComponent } from "appworks/components/abstract-component";
import { Layers } from "appworks/graphics/layers/layers";
import { TrailEmitter } from "appworks/graphics/particles/trail-emitter";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { RandomRangeInt } from "appworks/utils/math/random";
import { Easing } from "appworks/utils/tween";
import { EJSoundEvent } from "ej-sound-events";

export class EJFreespinSparkComponent extends AbstractComponent {
    public sparksActive = 0;

    public fireSpark(reelIndex: number): Contract {
        const layer = Layers.get("SpinCounter");

        const particle = new TrailEmitter(layer, "freespin_spark", { duration: 750, easing: Easing.Quartic.InOut });

        const posStart = layer.getPosition("sparkStart_" + reelIndex);
        const posMid = layer.getPosition("sparkMidPoint");
        const posEnd = layer.getPosition("sparkTarget");

        const rndRange = 200;
        posMid.landscape.x += RandomRangeInt(-rndRange, rndRange);
        posMid.landscape.y += RandomRangeInt(-rndRange, rndRange);
        posMid.portrait.x += RandomRangeInt(-rndRange, rndRange);
        posMid.portrait.y += RandomRangeInt(-rndRange, rndRange);

        return new Sequence([
            () => Contract.wrap(() => this.sparksActive++),
            () => particle.play([posStart, posMid, posEnd]),
            () => Contract.wrap(() => {
                Services.get(SoundService).customEvent(EJSoundEvent.freespin_collect);
                this.sparksActive--;
            })
        ]);
    }
}
