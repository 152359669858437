import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { BlastworksClientEvent } from "appworks/state-machine/ClientController";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { EJBoardGameComponent } from "components/ej-board-game-component";
import { EJDiceComponent } from "components/ej-dice-component";
import { EJFreespinSparkComponent } from "components/ej-freespin-spark-component";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { EJSoundEvent } from "ej-sound-events";
import { flashWhite, winSanity } from "ej-utils";
import { gameLayers } from "game-layers";
import { EJBoardGameResult } from "model/results/ej-board-game-result";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";
import { SignalBinding } from "signals";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoRecoveryWinResult } from "slingo/model/results/slingo-recovery-win-result";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoSpinState } from "slingo/states/slingo-spin-state";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class EJSpinState extends SlingoSpinState {
    protected anticipationBinding: SignalBinding;
    protected winningSymbols = { S: [], CL: [] };

    protected sparkEndDelay: number;

    public onEnter(cascadeSkip?: boolean): void {
        this.sparkEndDelay = 0;

        Services.get(SoundService).customEvent(EJSoundEvent.game_started);

        this.winningSymbols.S = [];
        this.winningSymbols.CL = [];

        this.anticipationBinding = Components.get(MatrixComponent).onAnticipationStart.addOnce(() => {
            const anim = gameLayers.MatrixBackground.getAnimatedSprite("anticipation");
            if (anim) {
                anim.play();
                anim.visible = true;
                fadeIn(anim, 250).execute();
            }
        });

        this.rollDiceAndMoveJoker().then(() => super.onEnter());
    }

    public complete(): void {
        if (Components.get(EJFreespinSparkComponent).sparksActive > 0) {
            Timer.setTimeout(() => this.complete(), 250);
            return;
        }

        const winningSymbols: SymbolSubcomponent[] = [];
        if (this.winningSymbols.CL.length >= 3) {
            winningSymbols.push(...this.winningSymbols.CL.map((index) => Components.get(MatrixComponent).getSymbol(index, 0)));
        }
        if (this.winningSymbols.S.length >= 3) {
            winningSymbols.push(...this.winningSymbols.S.map((index) => Components.get(MatrixComponent).getSymbol(index, 0)));
        }

        new Sequence([
            () => Contract.getTimeoutContract(this.sparkEndDelay),
            () => new Parallel([...winningSymbols.map((sym) => () => sym.win())]),
            () => new Parallel([...winningSymbols.map((sym) => () => sym.win())]),
            () => new Parallel([...winningSymbols.map((sym) => () => sym.win())])
        ]).then(() => super.complete());
    }

    public onExit(): void {
        super.onExit();

        const record = gameState.getCurrentGame().getCurrentRecord();
        let slotSpinsToBeWonOnWheel = 0;
        for (const result of record.getResultsOfType(EJBonusWheelResult)) {
            if (!result.played) { slotSpinsToBeWonOnWheel += result.slotSpinsWon; }
        }
        Components.get(EJUICounterComponent).updateSlotSpinsCounter(
            (gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult) as any).prizes - slotSpinsToBeWonOnWheel
        );

        this.anticipationBinding.detach();

        const anim = gameLayers.MatrixBackground.getAnimatedSprite("anticipation");
        fadeOut(anim, 125).then(() => {
            anim.stop();
            anim.visible = false;
        });

        winSanity();
    }

    protected onReelLand(reelIndex: number, match?: { matchedValue: number; reelIndex: number; }): void {
        super.onReelLand(reelIndex, match);

        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoReelSpinResult);
        const landedSymbol = result.symbols[reelIndex];

        if (landedSymbol === "FS") {
            this.stateMachine.dispatchClientEvent(BlastworksClientEvent.REEL_STOP_FREE_SPIN);
            this.sparkEndDelay = 750;
            Components.get(EJFreespinSparkComponent).fireSpark(reelIndex).then(() => {
                Components.get(SlingoSpinsCounterComponent).increment().execute();
            });
        }

        if (landedSymbol === "J") {
            this.stateMachine.dispatchClientEvent(BlastworksClientEvent.REEL_STOP_JOKER);
        }

        if (landedSymbol === "SJ") {
            this.stateMachine.dispatchClientEvent(BlastworksClientEvent.REEL_STOP_SUPER_JOKER);
        }

        if (["J", "SJ", "FS"].indexOf(landedSymbol) !== -1) {
            Components.get(MatrixComponent).getSymbol(reelIndex, 0).win().execute();
        }

        if (landedSymbol === "CL") { this.winningSymbols.CL.push(reelIndex); }
        if (landedSymbol === "S") { this.winningSymbols.S.push(reelIndex); }
    }

    protected rollDiceAndMoveJoker(): Contract {
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const boardGameResult = gameplay.getLatestResultOfType(EJBoardGameResult);
        const boardGameComponent = Components.get(EJBoardGameComponent);

        let diceRollValue = boardGameResult.jokerPosition - boardGameComponent.getCurrentJokerPosition();
        if (diceRollValue === 0) {
            diceRollValue = null; // tells dice component to skip
        } else if (diceRollValue < 0) {
            diceRollValue += EJBoardGameComponent.NUM_POSITIONS;
        }

        return new Sequence([
            () => Components.get(EJDiceComponent).roll(diceRollValue),
            () => boardGameComponent.moveJokerToPosition(boardGameResult.jokerPosition),
            () => boardGameComponent.win(boardGameResult.cashWon),
            () => {
                const recoveryWin = record.getFirstResultOfType(SlingoRecoveryWinResult)?.cashWon || 0;
                const win = gameplay.getTotalWin() - (record.cashWon - recoveryWin) + boardGameResult.cashWon;
                const winValueText = gameLayers.BetBar.getText("total_win_value");

                const contracts = [
                    () => Services.get(TransactionService).setWinnings(0, win, 500)
                ];

                if (boardGameResult.cashWon) {
                    contracts.push(() => flashWhite(winValueText, 500));
                }

                return new Parallel(contracts);
            }
        ]);
    }

    protected updateTotalWin(): Contract<void> {
        const winValueText = gameLayers.BetBar.getText("total_win_value");

        const record = gameState.getCurrentGame().getCurrentRecord();
        const ladderWin = record.getFirstResultOfType(SlingoLadderResult).cashWon;

        if (ladderWin === 0) { return super.updateTotalWin(); }

        return new Parallel([
            () => super.updateTotalWin(),
            () => flashWhite(winValueText, 500),
            () => Contract.getTimeoutContract(1250)
        ]);
    }
}
