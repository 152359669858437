import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { EJSlotBonusResult } from "model/results/ej-slot-bonus-result";

export class EJHasSlotBonusDecision extends Decision {
    public evaluate(): Exit {
        const record = gameState.getCurrentGame().getCurrentRecord();

        if (record.getResultsOfType(EJSlotBonusResult).find((result) => !result.played)) {
            return Exit.True;
        }

        return Exit.False;
    }
}
