import { EJPurchaseEntryState } from "states/ej-purchase-entry-state";
import { BlastworksClientEvent } from "../ClientController";
import { gameState } from "appworks/model/game-state";

export class SocialPurchaseEntryState extends EJPurchaseEntryState {
    public complete(): void {
        const gameplay = gameState.getCurrentGame();
        gameplay.setToLatestRecord();
        
        // If this is zero then there must be an error
        // i.e. account limits, insufficient funds
        if (gameplay.getTotalWagered() !== 0) {
            this.stateMachine.dispatchClientEvent(BlastworksClientEvent.START_GAME);
        }
        super.complete();
    }
}
