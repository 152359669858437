export enum EJSoundEvent {
    game_started = "game_started",
    enter_bonus_wheel = "enter_bonus_wheel",
    exit_bonus_wheel = "exit_bonus_wheel",
    enter_bonus_slot = "enter_bonus_slot",
    dice_roll = "dice_roll",
    game_ended = "stop_base_music",
    trail_win = "trail_win",
    expand_wild = "expand_wild",
    bonus_wheel_spin = "bonus_wheel_spin",
    bonus_wheel_prize_win_start = "bonus_wheel_prize_win_start",
    bonus_wheel_prize_win = "bonus_wheel_prize_win",
    freespin_collect = "freespin_collect",
    footstep_N = "footstep_{0}"
}
