import { GraphicsService } from "appworks/graphics/graphics-service";
import { Services } from "appworks/services/services";
import { fadeIn } from "appworks/utils/animation/fade";
import { gameLayers } from "game-layers";
import { BLEND_MODES } from "pixi.js";
import { SlingoReelHighlightComponent } from "slingo/components/slingo-reel-highlight-component";

export class EJReelHighlightComponent extends SlingoReelHighlightComponent {
    protected getHighlightSprite() {
        const sprite = Services.get(GraphicsService).createAnimation("highlight_matched");
        sprite.blendMode = BLEND_MODES.ADD;

        const master = gameLayers.TicketMatrix.getAnimatedSprite("master_highlight");
        sprite.gotoAndPlay(master.currentFrame);

        fadeIn(sprite, 250).execute();

        return sprite;
    }
}
