import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { Services } from "appworks/services/services";
import * as MobileDetect from "mobile-detect/mobile-detect";

export interface LayoutMapSchema {
    phone: "mobile" | "desktop";
    tablet: "mobile" | "desktop";
    desktop: "mobile" | "desktop";
}

class DeviceInfo {
    /**
     * This will overwrite pixel density to force high-res assets to be loaded
     */
    public isDesktop: boolean;

    /**
     * This is used to determine the canvas layout / size (phone or tablet version)
     */
    public isPhone: boolean;

    public pixelDensity: number;
    /**
     * Use this for debugging only
     */
    public forcePixelDensity: number;

    public layoutId: "mobile" | "desktop";

    private mobileDetect: MobileDetect;

    constructor() {

        this.mobileDetect = new MobileDetect(window.navigator.userAgent);

        // this.isDesktop = this.mobileDetect.mobile() === null;
        // this.isPhone = this.mobileDetect.phone() !== null;
        const deviceURLParam = this.checkDeviceURLParam();
        this.isDesktop = deviceURLParam === "tablet";
        this.isPhone = !this.isDesktop;

        // iOS 13 iPad report themselves as desktop
        if (this.isIpad()) {
            this.isDesktop = false;
        }

        this.pixelDensity = window.devicePixelRatio;
    }

    private checkDeviceURLParam(): string {
        let pageUrl: string = window.location.href.split('?')[1];
        let value: string = "";
        if (pageUrl) {
            pageUrl = pageUrl.replace(/#[^?]+/, "");
            let urlVariables: string[] = pageUrl.split('&');
            for (let i = 0; i < urlVariables.length; i++) {
                let parameter = urlVariables[i].split('=');
                let parameterName: string = parameter[0].toLowerCase();
                if (parameterName == "device") {
                    value = decodeURIComponent(parameter[1].replace(/\+/g, '%20')).toLowerCase();
                }
            }
        }
        return value;
    }

    public selectLayout(layoutMap: LayoutMapSchema) {
        if (this.isDesktop) {
            this.layoutId = layoutMap.desktop;
        } else if (this.isPhone) {
            this.layoutId = layoutMap.phone;
        } else {
            this.layoutId = layoutMap.tablet;
        }
    }

    public isIOSHomeScreen() {
        return this.isAppleDevice() && window && window.navigator && (window.navigator as any).standalone;
    }

    public isAppleDevice() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) || this.isIpad();
    }

    public isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    public isIpad() {
        return /iPad/.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    }

    // Assuming these devices with this res is this
    public isIphone4() {
        return this.isAppleDevice() && this.checkIfResolutionMatches(960);
    }

    public isIphone5() {
        return this.isAppleDevice() && this.checkIfResolutionMatches(1136);
    }

    public isIphone6() {
        return this.isAppleDevice && screen.height === 667;
    }

    public isIphoneSE() {
        return this.isAppleDevice && screen.height === 568;
    }
    //

    // Aspect ratio is more than 2:1
    public isUltraWide() {
        return this.getScreenAspectRatio() > 2;
    }

    /**
     * Assumes Android if it's mobile and not apple
     */
    public isAndroid() {
        return !this.isDesktop && !this.isAppleDevice();
    }

    public hasiOSTaskBar() {
        return this.isUltraWide();
    }

    public isiOSiFrame() {
        return this.isAppleDevice() && top && top.location !== self.location;
    }

    public isSamsungBrowser() {
        return navigator.userAgent.toLowerCase().indexOf("samsungbrowser") > -1;
    }

    public isFireFox() {
        return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    }

    public isiOSChrome() {
        return /CriOS/i.test(navigator.userAgent) && this.isAppleDevice();
    }

    public isIE11() {
        return this.getIEVersion() === 11;
    }

    public getIEVersion() {
        {
            let ua;
            let rv = -1;
            if (navigator.appName === "Microsoft Internet Explorer") {
                ua = navigator.userAgent;
                const re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null) {
                    rv = parseFloat(RegExp.$1);
                }
            } else if (navigator.appName === "Netscape") {
                ua = navigator.userAgent;
                const re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null) {
                    rv = parseFloat(RegExp.$1);
                }
            }
            return rv;
        }
    }

    /**
     * Returns true if the "chrome" (url bar, etc) around the browser is visible - i.e the device is not in "full screen". iOS only
     */
    public iOSChromeVisible() {
        if (!this.isAppleDevice()) {
            return false;
        }

        let iosFullScreen = this.getWindow().innerHeight >= this.getDocumentElement().clientHeight;
        if (this.getOrientation() === Orientation.PORTRAIT) {
            iosFullScreen = this.getWindow().innerHeight > this.getDocumentElement().clientHeight;
            if (this.isIpad()) {
                iosFullScreen = true;
            }
        }

        return !iosFullScreen;
    }

    public isIOSAddressBarVisible() {
        if (!this.isAppleDevice()) {
            return false;
        }

        if (this.getOrientation() === Orientation.LANDSCAPE) {
            return this.getWindow().innerHeight < this.getWindow().screen.width;
        } else {
            return this.getWindow().innerHeight < this.getWindow().screen.height;
        }
    }

    public getScreenAspectRatio() {
        return Math.max(window.screen.width, window.screen.height) / Math.min(window.screen.width, window.screen.height);
    }

    public getWindowAspectRatio() {
        return Math.max(this.getWindowWidth(), this.getWindowHeight()) / Math.min(this.getWindowWidth(), this.getWindowHeight());
    }

    public getWindow() : Window {
        let windowObj;

        try {
            windowObj = self !== top ? top.parent.window : window;
            if (!windowObj.innerWidth) {
                throw new Error();
            }
        } catch (e) {
            windowObj = window;
        }

        return windowObj;
    }

    public getDocumentElement() {
        let documentElement;

        try {
            documentElement = self !== top ? top.parent.document.documentElement : document.documentElement;
            if (!documentElement.clientWidth) {
                throw new Error();
            }
        } catch (e) {
            documentElement = document.documentElement;
        }

        return documentElement;
    }

    public getWindowWidth() {
        if (this.isiOSiFrame()) {
            switch (this.getOrientation()) {
                case Orientation.LANDSCAPE:
                    const windowWidth: number = Math.max(this.getWindow().innerHeight, this.getWindow().innerWidth);
                    return Math.min(Math.max(screen.width, screen.height), windowWidth);
                case Orientation.PORTRAIT:
                    return Math.min(screen.width, screen.height);
            }
        }

        return this.isIOSHomeScreen() || this.isSamsungBrowser() ? this.getDocumentElement().clientWidth : this.getWindow().innerWidth;
    }

    public getWindowHeight() {
        if (this.isiOSiFrame()) {
            switch (this.getOrientation()) {
                case Orientation.LANDSCAPE:
                    const windowHeight: number = Math.min(this.getWindow().innerHeight, this.getWindow().innerWidth);
                    return Math.min(screen.width, screen.height, windowHeight);
                case Orientation.PORTRAIT:
                    return Math.max(screen.width, screen.height);
            }
        }

        return this.isIOSHomeScreen() || this.isSamsungBrowser() ? this.getDocumentElement().clientHeight : this.getWindow().innerHeight;
    }

    public getOrientation(): Orientation {
        if (Services.get(CanvasService).cssCanvasEnabled) {
            return window.innerWidth > window.innerHeight ? Orientation.LANDSCAPE : Orientation.PORTRAIT;
        }

        if (this.isiOSiFrame()) {
            return ((this.getWindow().orientation as number) % 180 === 0) ? Orientation.PORTRAIT : Orientation.LANDSCAPE;
        } else {
            return (this.getWindowWidth() > this.getWindowHeight()) ? Orientation.LANDSCAPE : Orientation.PORTRAIT;
        }
    }

    private checkIfResolutionMatches(resolution: number) {
        const highestSide = Math.max(window.screen.height, window.screen.width);
        const multiplier = window.devicePixelRatio || 1;

        return highestSide === resolution / multiplier;
    }
}

export const deviceInfo = new DeviceInfo();
