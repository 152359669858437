import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { Scene } from "appworks/graphics/layers/scene";
import { BitmapText } from "appworks/graphics/pixi/bitmap-text";
import { Text } from "appworks/graphics/pixi/text";
import { ClientModel } from "appworks/model/client-model";
import { Result } from "appworks/model/gameplay/records/results/result";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SettingsService } from "appworks/services/settings/settings-service";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Timer } from "appworks/utils/timer";
import * as TweenJS from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { LineResult } from "slotworks/model/gameplay/records/results/line-result";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { WaysResult } from "slotworks/model/gameplay/records/results/ways-result";
import { slotModel } from "slotworks/model/slot-model";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { FooterComponent } from "../footer/footer-component";

/**
 * Describe how long a countup should take in a given range
 * type (default to linear)
 * Linear = time varies between minTime and time depending on win size
 * Discrete = time is always maxTime
 * If no time is set, default time (totalCountupTime) is used
 */
export interface CelebrationWinRange {
    id: string;
    from: number;
    to: number;
    type?: "linear" | "discrete";
    time?: number;
    minTime?: number;
}

export interface CelebrateWinConfig {
    pulseConfig: { magnitude: number, duration: number };
    totalCountupTime: number;
    totalWaitTime: number;
    lineCountupTime: number;
    lineWaitTime: number;
    enableTickup: boolean;
    enablePersistentTotalWin: boolean;
    enableCumulativeTotalWin: boolean;
    totalWinThreshold: number;
    winRanges: CelebrationWinRange[];
    skipDelay: number;
    creditMode: boolean;
    skipWinTickupInTurbo: boolean;
}

export class CelebrateWinComponent extends AbstractComponent {
    public config: CelebrateWinConfig = {
        pulseConfig: { magnitude: 1.1, duration: 100 },
        totalCountupTime: 750,
        totalWaitTime: 750,
        lineCountupTime: 0,
        lineWaitTime: 1250,
        enableTickup: true,
        enablePersistentTotalWin: false,
        enableCumulativeTotalWin: false,
        totalWinThreshold: 0,
        winRanges: [],
        skipDelay: Infinity,
        creditMode: true,
        skipWinTickupInTurbo: false
    };

    protected currentWins: Win[] = [];

    protected allowSkip: boolean;
    protected skipDelayTimeout: number;
    protected tween: TweenJS.Tween;
    protected totalWinTickObj: { value: number } = { value: 0 };

    protected skipGroup: CancelGroup;

    constructor(config?: Partial<CelebrateWinConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }

        this.skipGroup = new CancelGroup();

        document.body.addEventListener(ButtonEvent.POINTER_DOWN.getDOMEventString(), () => this.skip(), true);
    }

    public totalWin(winAmount: number) {
        this.resetSkip();

        const win = new Win(winAmount, ["totalWin"], this.config.totalWinThreshold);

        if (!win.valid) {
            return Contract.empty();
        }

        return new Sequence([
            () => this.celebrateWin(win),
            () => this.completeTotalWin()
        ]);
    }

    public singleTotalWin(winAmount: number, result: SymbolWinResult) {
        this.resetSkip();

        const win = new Win(winAmount, ["totalWin"], this.config.totalWinThreshold, result);

        if (!win.valid) {
            return Contract.empty();
        }

        return new Sequence([
            () => this.celebrateWin(win),
            () => this.completeTotalWin()
        ]);
    }

    public additionalWin(winAmount: number) {
        this.resetSkip();

        const win = new Win(winAmount, ["additionalWin", "totalWin"], this.config.totalWinThreshold);
        return new Sequence([
            () => this.celebrateWin(win),
            () => Contract.getTimeoutContract(this.config.totalWaitTime),
            () => Layers.get("CelebrationContent").defaultScene()
        ]);
    }

    public lineWin(result: LineResult, symbol?: string, row?: number, autoClear: boolean = true) {
        this.resetSkip();

        const scenes = [
            `line_${result.line}_${result.matches}_win`,
            `line_${result.line}_win`,
            `line_row_${row}_win`,
            "line_win",
            "lineWin"
        ];

        const win = new Win(result.cashWon, scenes, this.config.totalWinThreshold, result);

        if (!win.valid) {
            return Contract.empty();
        }

        this.cleanup();

        if (!win.canTickUp()) {
            if (Components.get(FooterComponent)) {
                return Components.get(FooterComponent).showLineWin(result, symbol);
            } else {
                return Contract.empty();
            }
        } else {
            const setup = () => {
                new Parallel([
                    () => Contract.wrap(() => Services.get(SoundService).event(SoundEvent.tickup_linewin_start)),
                    () => (Components.get(FooterComponent)) ? Components.get(FooterComponent).showLineWin(result, symbol) : Contract.empty(),
                    () => this.showLineWin(win.winAmount)
                ]).execute();
            };

            const sequenceContracts = [
                () => Layers.get("CelebrationContent").setScene(win.scene, setup),
                () => this.skipGroup.timeoutContract(this.config.lineCountupTime),
                () => Contract.getTimeoutContract(this.config.lineWaitTime)
            ];

            if (autoClear) {
                sequenceContracts.push(() => Layers.get("CelebrationContent").defaultScene());
            }

            const sequence = new Sequence<void>(sequenceContracts);

            win.contract = sequence;
            this.currentWins.push(win);

            return sequence;
        }
    }

    public miscWin(result: Result, textId?: string) {
        this.resetSkip();

        return Contract.wrap(() => {
            this.cleanup();
            Components.get(FooterComponent)?.showMiscWin(textId, result);
        });
    }

    public waysWin(result: WaysResult, symbol?: string) {
        this.resetSkip();

        const scenes = [
            `ways_${result.ofAKind()}_win`,
        ];

        const win = new Win(result.cashWon, scenes, this.config.totalWinThreshold, result);

        if (win.valid) {
            Services.get(SoundService).event(SoundEvent.wayswin_start);
            Components.get(FooterComponent)?.showWaysWin(result, symbol);
        }

        if (win.scene) {
            return new Sequence([
                () => this.celebrateWin(win),
                () => this.completeTotalWin()
            ]);
        }

        return Contract.empty();
    }

    public cleanup(): void {
        if (this.tween) {
            Services.get(SoundService).event(SoundEvent.tickup_anywin_end);
            this.tween.stop();
        }

        while (this.currentWins.length > 0) {
            const win = this.currentWins.shift();
            win.contract.cancel();
            win.destroy();
        }

        if (!this.config.enablePersistentTotalWin) {
            this.hide();
        }
    }

    public resetTotalWin(): void {
        this.totalWinTickObj = { value: 0 };
    }

    public hide(): void {
        if (Layers.get("CelebrationContent")) {
            Layers.get("CelebrationContent").jumpToScene("default");
            Components.get(FooterComponent)?.clear();
        }
    }

    protected resetSkip() {
        this.allowSkip = false;

        Timer.clearTimeout(this.skipDelayTimeout);
        Timer.setTimeout(() => {
            this.allowSkip = true;
        }, this.config.skipDelay);
    }

    protected skip() {
        if (this.allowSkip) {
            this.skipGroup.skip();
            this.allowSkip = false;
        }
    }

    protected celebrateWin(win: Win) {
        this.cleanup();

        Services.get(SoundService).event(SoundEvent.totalwin_start);

        let countupTime = this.config.totalCountupTime;

        const winRange = this.getWinRangeForWin(win.winAmount);

        if (winRange) {
            win.customSound = Services.get(SoundService).getSoundEventName(SoundEvent.tickup_win_range_start, winRange.id);

            if (winRange.time !== undefined) {
                if (winRange.type === "linear") {
                    const ratio = (this.getWinRatio(win.winAmount) - winRange.from) / (winRange.to - winRange.from);
                    countupTime = winRange.minTime + (winRange.time - winRange.minTime) * ratio;
                } else {
                    countupTime = winRange.time;
                }
            }
        }

        if (this.config.skipWinTickupInTurbo && (Services.get(SettingsService).quickSpinEnabled() || Services.get(SettingsService).turboEnabled())) {
            countupTime = 0;
        }

        if (!win.canTickUp()) {
            if (Components.get(FooterComponent)) {
                return Components.get(FooterComponent).showGameWin(win.winAmount);
            } else {
                return Contract.empty();
            }
        } else {
            const setup = () => {
                this.getValueText().text = "";
                new Parallel([
                    () => Contract.wrap(() => win.playSound()),
                    () => (Components.get(FooterComponent)) ? Components.get(FooterComponent).showGameWin(win.winAmount) : Contract.empty(),
                    () => this.tickupTotalWin(win.winAmount, countupTime)
                ]).execute();
            };

            const sequence = new Sequence<void>([
                () => Layers.get("CelebrationContent").setScene(win.scene, setup),
                () => this.skipGroup.timeoutContract(countupTime),
                () => Contract.getTimeoutContract(this.config.totalWaitTime)

            ]);

            win.contract = sequence;
            this.currentWins.push(win);

            return sequence;
        }
    }

    protected completeTotalWin() {
        if (this.config.enablePersistentTotalWin || !Layers.get("CelebrationContent")) {
            return Contract.empty();
        } else {
            return Layers.get("CelebrationContent").defaultScene();
        }
    }

    protected getValueText() {
        return Layers.get("CelebrationContent").getText("value") ||
            Layers.get("CelebrationContent").getBitmapText("value");
    }

    protected instantUpdateText(text: Text | BitmapText, targetValue: number, wait: number, completeSound?: SoundEvent) {
        Services.get(SoundService).event(SoundEvent.tickup_anywin_start);
        Services.get(SoundService).event(SoundEvent.tickup_anywin_end);
        text.text = Services.get(CurrencyService).format(targetValue, true, this.config.creditMode);

        if (completeSound) {
            Services.get(SoundService).event(completeSound);
        }

        return Contract.getTimeoutContract(wait);
    }

    protected tickupTotalWin(amountWon: number, countupTime: number) {
        let endSound: string = SoundEvent.tickup_totalwin_end;

        const winRange = this.getWinRangeForWin(amountWon);
        if (winRange) {
            endSound = Services.get(SoundService).getSoundEventName(SoundEvent.tickup_win_range_end, winRange.id);
        }
        
        Services.get(SoundService).event(SoundEvent.tickup_totalwin_start);

        return this.tickUp(
            this.getValueText(),
            amountWon,
            countupTime,
            this.config.totalWaitTime,
            endSound
        );
    }

    protected showLineWin(amountWon: number) {
        if (this.config.lineCountupTime === Infinity || this.config.lineCountupTime === 0) {
            return this.instantUpdateText(this.getValueText(), amountWon, this.config.lineWaitTime);
        } else {
            return this.tickUp(this.getValueText(), amountWon, this.config.lineCountupTime, this.config.lineWaitTime);
        }
    }

    protected tickUp(text: Text | BitmapText, targetValue: number, duration: number, wait: number, completeSound?: string) {
        return new Sequence([
            () => this.tickTween(text, targetValue, duration, completeSound),
            () => this.pulse(text),
            () => Contract.getTimeoutContract(wait)
        ]);
    }

    protected tickTween(text: Text | BitmapText, targetValue: number, duration: number, completeSound?: string) {
        return this.skipGroup.contract((resolve) => {
            Services.get(SoundService).event(SoundEvent.tickup_anywin_start);

            if (!this.config.enableCumulativeTotalWin) {
                this.resetTotalWin();
            }

            const onComplete = () => {
                text.text = Services.get(CurrencyService).format(targetValue, true, this.config.creditMode);

                Services.get(SoundService).event(SoundEvent.tickup_anywin_end);
                if (completeSound) {
                    Services.get(SoundService).customEvent(completeSound);
                }
            };

            this.tween = this.skipGroup.tween(this.totalWinTickObj)
                .to({ value: targetValue }, duration)
                .onStart(() => {
                    if (!this.config.enableTickup) {
                        text.text = Services.get(CurrencyService).format(targetValue, true, this.config.creditMode);
                    }
                })
                .onUpdate(() => {
                    if (this.config.enableTickup) {
                        text.text = Services.get(CurrencyService).format(this.totalWinTickObj.value, true, this.config.creditMode);
                    }
                })
                .onStop(onComplete)
                .onComplete(() => {
                    this.tween.onStop(null);
                    onComplete();
                    resolve(null);
                })
                .start();
        });
    }

    protected getWinRatio(win: number): number {
        return win / Services.get(SlotBetService).getTotalStake();
    }

    protected getWinRangeForRatio(ratio: number): CelebrationWinRange {
        for (const winRange of this.config.winRanges) {
            if (ratio >= winRange.from) {
                if (ratio <= winRange.to) {
                    return winRange;
                }
            }
        }
    }

    protected getWinRangeForWin(win: number): CelebrationWinRange {
        const ratio = this.getWinRatio(win);
        return this.getWinRangeForRatio(ratio);
    }

    protected pulse(text: Text | BitmapText) {
        const pulseConfig = this.config.pulseConfig;
        if (pulseConfig) {
            const targetScaleLandscape = { x: text.landscape.scale.x * pulseConfig.magnitude, y: text.landscape.scale.y * pulseConfig.magnitude };
            const targetScalePortrait = { x: text.landscape.scale.x * pulseConfig.magnitude, y: text.landscape.scale.y * pulseConfig.magnitude };

            return new Parallel([
                () => TweenContract.wrapTween(new Tween(text.landscape.scale).to(targetScaleLandscape, pulseConfig.duration).yoyo(true).repeat(1)),
                () => TweenContract.wrapTween(new Tween(text.landscape.scale).to(targetScalePortrait, pulseConfig.duration).yoyo(true).repeat(1))
            ]);
        } else {
            return Contract.empty();
        }
    }
}

export class Win {
    public scene: Scene;
    public result: SymbolWinResult;
    public valid: boolean = true;
    public winAmount: number;
    public contract: Contract<void>;
    public totalWinThreshold: number;
    public customSound: string;

    constructor(winAmount: number, public scenes: string[], totalWinThreshold: number, result?: SymbolWinResult) {
        if (winAmount <= 0) {
            this.valid = false;
            return;
        }

        this.winAmount = winAmount;
        this.totalWinThreshold = totalWinThreshold;

        this.scene = Layers.get("CelebrationContent")?.getFirstValidScene(scenes);

        if (result) {
            this.result = result;
        }
    }

    public isBelowWinThreshold(): boolean {
        return this.winAmount <= 0 || this.winAmount / Services.get(SlotBetService).getTotalStake() < this.totalWinThreshold;
    }

    public canTickUp() {
        return !this.isBelowWinThreshold() && this.scene;
    }

    public playSound() {
        if (this.customSound) {
            Services.get(SoundService).customEvent(this.customSound);
        } else if (this.result && this.result instanceof SymbolWinResult) {
            Components.get(AbstractMatrixComponent).playSymbolSound(this.result.symbolType, this.result.matches.toString(), "1");
        }
    }

    public destroy() {
        this.contract = null;
        this.result = null;
        this.scene = null;
    }
}
