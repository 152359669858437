import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { gameLoop } from "appworks/core/game-loop";
import { Layers } from "appworks/graphics/layers/layers";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { ClientController } from "appworks/state-machine/ClientController";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { AlertComponent } from "slotworks/components/alert/alert-component";

export class GMRAlertComponent extends AlertComponent {

    // Disables the client side popups and allows the wrapper to display them
    public isEnabled() {
        return GamingRealms.isHistory() ? true : GamingRealms.wrapperConfig.getOperatorConfig().isDialogEnabled();
    }

    public recover(): Contract<void> {
        // Force idle on then off to ensure GAME_BUSY is sent to wrapper
        uiFlags.set(UIFlag.IDLE, true);
        uiFlags.set(UIFlag.IDLE, false);

        if (!this.isEnabled()) {
            // We won't get an ERROR_COMPLETE, as it isn't an error
            // Wrapper should instead pause on GameState.RECOVERY, and resume when third-party prompt is dismissed
            return Contract.empty();
        }

        const prompt = new PromptContentSubcomponent(["info"], Infinity, Layers.get("Alerts"));
        prompt.setText("title", Services.get(TranslationsService).get("recovery_title"));
        prompt.setText("body", Services.get(TranslationsService).get("recovery_body"));
        prompt.addContinueButton("continue");

        return Components.get(PromptComponent).showPrompt(prompt);
    }

    public error(messageId: string, fatal?: boolean): Contract<void> {
        ClientController.somethingWentWrong(messageId);
        if (!this.isEnabled()) {
            return this.waitForWrapperErrorComplete();
        }

        Layers.get("Prompts").show(); // If error is early in loading process, layer may not yet be visible

        const titleKey = Services.get(TranslationsService).exists(`${messageId}_title`) ? `${messageId}_title` : `ERROR_TITLE`;
        return super.error(messageId, fatal, titleKey);
    }

    public bonusMoney(): Contract<void> {
        if (!this.isEnabled()) {
            return this.waitForWrapperErrorComplete();
        }

        const prompt = new PromptContentSubcomponent(["bonusmoney"], Infinity, Layers.get("Alerts"));

        prompt.addContinueButton("ok");
        prompt.addButton("tc", () => GamingRealms.wrapperInstance.updateAction(so.GameAction.BONUS));

        return Components.get(PromptComponent).showPrompt(prompt);
    }

    public realitycheck(time: number, wager: number, win: number): Contract<void> {

        const optionsDisabled = !GamingRealms.wrapperConfig.getOperatorConfig().isRealityCheckOptionsEnabled();

        if (!this.isEnabled()) {
            return this.waitForWrapperErrorComplete();
        }

        gameLoop.setPaused("realitycheck", true);

        const prompt = new PromptContentSubcomponent(["realitycheck"], Infinity, Layers.get("Alerts"));

        const result = win - wager;
        if (GamingRealms.wrapperConfig?.getOperatorConfig()?.isWinLossEnabled()) {
            prompt.setText("body", Services.get(TranslationsService).get("reality_check_win_loss", {
                time: Math.floor(time / 1000 / 60),
                wager: Services.get(CurrencyService).format(wager),
                win: Services.get(CurrencyService).format(win),
                result: Services.get(CurrencyService).format(result),
            }));
        } else {
            prompt.setText("body", Services.get(TranslationsService).get("reality_check", {
                time: Math.floor(time / 1000 / 60)
            }));
        }

        let optionSelected = false;

        if (optionsDisabled) {
            prompt.hideButton("history");
            prompt.hideButton("logout");
            prompt.hideButton("continue");
            prompt.addContinueButton("continuenooptions");
        } else {
            prompt.hideButton("continuenooptions");
            prompt.addContinueButton("continue");
        }

        prompt.addButton("logout", () => {
            if (!optionSelected) {
                optionSelected = true;
                GamingRealms.wrapperInstance.updateAction(so.GameAction.EXIT_GAME);
                prompt.disableContinueButton();
            }
        });
        prompt.addButton("history", () => {
            if (!optionSelected) {
                optionSelected = true;
                GamingRealms.wrapperInstance.updateAction(so.GameAction.BET_HISTORY);
                prompt.disableContinueButton();
            }
        });
        return new Contract<void>((resolve) => {
            Components.get(PromptComponent).showPrompt(prompt).then(() => {
                gameLoop.setPaused("realitycheck", false);
                resolve();
            });
        });
    }

    public spinPriceConfirm(spinPrice: number): Contract<boolean> {
        return new Contract<boolean>((resolve) => {
            const prompt = new PromptContentSubcomponent(["yes_no"], Infinity, Layers.get("Alerts"));

            prompt.addButton("yes", () => {
                prompt.disableButtons();
                prompt.hide().then(() => resolve(true))
            });
            prompt.addButton("no", () => {
                prompt.disableButtons();
                prompt.hide().then(() => resolve(false))
            });

            const spinPriceString = Services.get(CurrencyService).format(spinPrice);
            prompt.setText("body", Services.get(TranslationsService).get("spin_price_warning", { value: spinPriceString }));

            Components.get(PromptComponent).showPrompt(prompt).execute();
        });
    }

    public insufficientFunds(): Contract {
        ClientController.getInstance().handleInsufficientFunds();
        if (!this.isEnabled()) {
            return this.waitForWrapperErrorComplete();
        }

        const isGB = GamingRealms.getLocale().toLowerCase().includes("gb");
        const msg = isGB ? "INSUFFICIENT_FUNDS_RESPONSIBLE_GAMING" : "INSUFFICIENT_FUNDS";
        return this.info(msg, "INSUFFICIENT_FUNDS_TITLE");
    }

    protected waitForWrapperErrorComplete() {
        return GamingRealms.waitForErrorComplete();
    }
}