import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { EJSoundEvent } from "ej-sound-events";
import { gameLayers } from "game-layers";
import { SlotSpinRecordBuilder } from "slot/integration/builders/records/slot-spin-record-builder";
import { EJSlotBonusResult } from "model/results/ej-slot-bonus-result";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { FreespinRequestPayload } from "slotworks/model/gameplay/requests/freespin-request-payload";
import { slotDefinition } from "slotworks/model/slot-definition";

export class EJEnterSlotBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        new Sequence([
            () => gameLayers.ScreenFade.setScene("black"),
            () => Contract.wrap(() => {
                uiFlags.set(UIFlag.FREE_SPINS, true);
                uiFlags.set(UIFlag.IDLE, false);

                Components.get(MatrixComponent).change("slotbonus",
                    [3, 3, 3, 3, 3],
                    [0, 0, 0, 3, 0],
                    slotDefinition.reelsets.get("slotbonus")
                );

                gameLayers.MatrixBackground.setScene("slotbonus").execute();
                gameLayers.MatrixForeground.setScene("slotbonus").execute();
                gameLayers.SpinCounter.setScene("slotbonus").execute();
                gameLayers.SymbolAnimations.setScene("slotbonus").execute();
                gameLayers.Paylines.setScene("slotbonus").execute();
                gameLayers.Logo.setScene("slotbonus").execute();

                gameLayers.SlingoLadder.hide();
                gameLayers.Footer.hide();
                gameLayers.TicketMatrix.hide();
                gameLayers.TicketMatrixBacking.hide();
                gameLayers.BoardGame.hide();

                const gameplay = gameState.getCurrentGame();
                const record = gameplay.getCurrentRecord();
                const result = record.getResultsOfType(EJSlotBonusResult).find((res) => !res.played);
                gameLayers.BetBar.getText("line_stake_value").text = Services.get(CurrencyService).format(result.data.stakePerLine);

                Services.get(SoundService).customEvent(EJSoundEvent.enter_bonus_slot);

                Components.get(SlingoSpinsCounterComponent).setValue(result.data.standardSpins, false).execute();
            }),
            () => gameLayers.ScreenFade.defaultScene()
        ]).then(() => {
            this.buildRecords();
            this.complete();
        });
    }

    protected buildRecords() {
        const builder = new SlotSpinRecordBuilder();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const result = record.getResultsOfType(EJSlotBonusResult).find((res) => !res.played);

        const spinRecords: SpinRecord[][] = [];

        for (const spin of result.data.spins) {
            const fakeResponse = {
                data: {
                    spins: [spin]
                }
            };

            const spinRec = builder.build(gameplay, null, fakeResponse)[0];
            spinRec.reelset = slotDefinition.reelsets.get("slotbonus");
            spinRec.lastChild = false;
            spinRec.wager = 0;
            spinRecords.push([spinRec]);
        }
        spinRecords[spinRecords.length - 1][0].lastChild = true;

        commsManager.queueRecords(FreespinRequestPayload, spinRecords);
    }
}
