import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";

export class LineResult extends SymbolWinResult {

    /** For debugging purposes */
    public resultType: string = "LineResult";

    /**
     * NOT 0 indexed. Starts from 1
     */
    public line: number;
}
