import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";

export class EJSlotBonusResult extends BonusResult {
    public resultType = "EJSlotBonusResult";

    public data: {
        freeSpins: number,
        freeSpinsRemaining: number,
        freeSpinsUsed: number,
        spins: Array<{
            freeSpins: number,
            freeSpinsRemaining: number,
            freeSpinsUsed: number,
            payout: {},
            reels: Array<{
                offset: number,
                symbols: number[]
            }>,
            spinType: string,
            standardSpins: number,
            standardSpinsRemaining: number,
            standardSpinUsed: number
        }>,
        stakePerLine: number,
        standardSpins: number,
        standardSpinsRemaining: number,
        standardSpinUsed: number,
        totalWin: number
    };
}
