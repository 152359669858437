import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { slingoModel } from "slingo/model/slingo-model";
import { EJInitState } from "states/ej-init-state";
import { ClientController } from "../ClientController";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { ClientModel } from "../ClientModel";
import { Services } from "appworks/services/services";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { ValueList } from "appworks/utils/value-list";

export class SocialInitState extends EJInitState {
    public onEnter(): void {
        if (window.location.hostname === "localhost") {
            super.onEnter();
        } else {
            //EJInitState.onEnter
            gameLayers.BetBar.onSceneEnter.add(() => {
                const timeBg = gameLayers.BetBar.getSprite("bg_time");
                if (timeBg) {
                    timeBg.visible = GamingRealms.wrapperConfig.getOperatorConfig().isClockEnabled();
                }

                const gameIdBg = gameLayers.BetBar.getSprite("bg_gameid");
                if (gameIdBg) {
                    gameIdBg.visible = Boolean(slingoModel.read().gameInstanceId) && GamingRealms.wrapperConfig.getOperatorConfig().isClockEnabled();
                }
            });

            slingoModel.onUpdate.add(() => {
                const gameIdBg = gameLayers.BetBar.getSprite("bg_gameid");
                if (gameIdBg) {
                    gameIdBg.visible = Boolean(slingoModel.read().gameInstanceId) && GamingRealms.wrapperConfig.getOperatorConfig().isClockEnabled();
                }
            });

            // reworked InitState.onEnter
            this.setupGameInactiveTimer();

            new Sequence([
                () => this.sendInitRequest(),
                () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)),
                () => Contract.wrap(() => ClientController.getInstance().sendGameReady(this))
            ]).execute();
        }
    }

    public onBackOfficeBetsReceived(): void {
        const modelCopy = slingoModel.read();
        modelCopy.stakeConfig.amounts = ClientModel.getStakeFromGameHub().map(stake => stake * 100);
        slingoModel.write(modelCopy);
        const stakeConfig = slingoModel.read().stakeConfig;

        Services.get(SlotBetService).setDefaults(
            new ValueList<number>(
                stakeConfig.amounts,
                stakeConfig.amounts[stakeConfig.defaultIndex],
                stakeConfig.lastStake || stakeConfig.amounts[stakeConfig.defaultIndex]
            ),
            new ValueList<number>([1], 1, 1)
        );

        new Sequence([
            () => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, false)),
            () => this.showOnBoard(),
            () => this.gameLoaded(),
            () => this.startGame()
        ]).then(() => this.complete());
    }
}