import { GMRConfig, GMRGameConfig, GMRResponse } from "gaming-realms/integration/gmr-schema";

export interface SlingoResponse extends GMRResponse {
    balance: SlingoBalance[];
    config: SlingoConfig
    gameInstanceId: number;
    gameProgress: SlingoGame;
    rsp: number;
    spinResult: SlingoSpin;
    state: SlingoState;
    ticket: SlingoTicket;
}

export interface SlingoConfig extends GMRConfig {
    gameConfig: SlingoGameConfig;
    payoutConfig: SlingoPayoutConfig;
    stakeConfig: SlingoStakeConfig;
    symbolConfig: SlingoSymbol[];
    payouts: GMRSlotPayouts[];
    symbols: GMRSlotSymbol[];
}

export interface GMRSlotPayouts {
    id: number;
    multipliers: number[];
    layout: string;
}
export interface GMRSlotSymbol {
    id: number;
    name: string;
    type: string;
}

export interface SlingoBalance {
    type: "CASH" | "BONUS";
    amount: number;
    currencyCode: string;
}

export interface SlingoTicket {
    id: number;
    type: "FIFTY" | "SEVENTY_FIVE" | "NINETY";
    rows: number;
    columns: number;
    cells: number;
    data: number[];
}

export interface SlingoGame {
    completionReason: SlingoCompletionReason;
    standardSpins: number;
    standardSpinsUsed: number;
    standardSpinsRemaining: number;
    purchaseSpins: number;
    purchaseSpinsUsed: number;
    purchaseSpinsRemaining: number;
    hasFirstPurchaseSpin: boolean;
    freeSpins: number;
    freeSpinsUsed: number;
    freeSpinsRemaining: number;
    matchedNumbers: number[];
    matchedPatterns: number;
    patternWin: number;
    symbolWin: number;
    standardWin: number;
    purchaseWin: number;
    jackpotWin: number;
    totalWin: number;
    standardStake: number;
    purchaseStake: number;
    nextPurchaseStake: number;
    totalStake: number;
    currencyCode: string;
}

export interface SlingoSpin {
    spinType: "STANDARD" | "FREE" | "PURCHASE";
    reelSequence: SlingoReel;
    purchaseStake: number;
    symbolWins: SlingoSymbolWin[];
    patternWin: SlingoPatternWin;
    matchedNumbers: number[];
    jokerCells: number[];
    superJokerCells: number[];
    feature: any;
    acknowledged: boolean;
}

export interface SlingoReel {
    symbols: string[];
    jokerIndexes: number[];
    superJokerIndexes: number[];
    coinIndexes: number[];
    freeSpinIndexes: number[];
    devilIndexes: number[];
}

export interface SlingoSymbolWin {
    symbols: string[];
    numberOfSymbols: number;
    amount: number;
}

export interface SlingoPatternWin {
    numberOfPatterns: number;
    amount: number;
}

export interface SlingoGameConfig extends GMRGameConfig {
    id: string;
    checksum: string;
    standardSpins: number;
    purchaseSpins: number;
    rtp: number;
    minimumSpinDuration: number;
    celebrateWinThreshold: number;
    fullHouseThreshold: number;
}

export interface SlingoPayoutConfig {
    symbolPayouts: SlingoSymbolPayout[];
    patternPayouts: number[];
    patterns: string[];
}

export interface SlingoStakeConfig {
    amounts: number[];
    currencyCode: string;
    defaultIndex: number;
    defaultFractionDigits: number;
    lastStake: number;
    maximumStake: number;
    maximumTotalStake: number;
}

export interface SlingoSymbol {
    type: SlingoSymbolType;
    symbol: string;
}

export interface SlingoSymbolPayout {
    symbols: string[];
    multipliers: number[];
}

export enum SlingoSymbolType {
    NUMBER = "NUMBER",
    JOKER = "JOKER",
    SUPER_JOKER = "SUPER_JOKER",
    FREE_SPIN = "FREE_SPIN",
    DEVIL = "DEVIL",
    COIN = "COIN",
    BONUS = "BONUS",
    PRIZE = "PRIZE",
    SPIN = "SPIN"
}

export enum SlingoState {
    PURCHASE_ENTRY = "PURCHASE_ENTRY",
    STANDARD_SPIN = "STANDARD_SPIN",
    FREE_SPIN = "FREE_SPIN",
    PURCHASE_SPIN = "PURCHASE_SPIN",
    CHOOSE_CELL = "CHOOSE_CELL",
    COMPLETE = "COMPLETE"
}

export enum SlingoCompletionReason {
    TICKET_MATCHED = "TICKET_MATCHED",
    NO_PURCHASE_SPIN = "NO_PURCHASE_SPIN",
    NO_PURCHASE_STAKE = "NO_PURCHASE_STAKE",
    MINIMUM_STAKE_LIMIT = "MINIMUM_STAKE_LIMIT",
    MAXIMUM_STAKE_LIMIT = "MAXIMUM_STAKE_LIMIT",
    MAXIMUM_TOTAL_STAKE_LIMIT = "MAXIMUM_TOTAL_STAKE_LIMIT",
    COLLECTED = "COLLECTED"
}