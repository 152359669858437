import { Model } from "appworks/model/model";
import { SettingsModel } from "appworks/model/settings/settings-model";
import { Services } from "appworks/services/services";
import { stateMachine } from "appworks/state-machine/state-machine";
import { Service } from "../service";
import { LocalStorageService } from "../storage/local-storage-service";

export class SettingsService extends Service {

    private static SPACE_TO_SPIN: string = "spaceToSpin";
    private static DISPLAY_CREDITS: string = "displayCredits";
    private static SHOW_INTRO: string = "showIntro";
    private static QUICK_SPIN: string = "quickSpin";

    public saveSettings: boolean = true;

    protected settingsModel: SettingsModel;

    public init(): void {
        Model.onUpdate.add(() => {
            this.settingsModel = Model.read().settings;

            this.save();
            this.updateTurbo();
        });
    }

    public quickSpinEnabled() {
        return this.settingsModel.quickSpin;
    }

    public turboEnabled() {
        return this.settingsModel.turboSpin;
    }

    public load() {

        const displayCredits = Services.get(LocalStorageService).getBoolean(SettingsService.DISPLAY_CREDITS);
        const showIntro = Services.get(LocalStorageService).getBoolean(SettingsService.SHOW_INTRO);
        const spaceToSpin = Services.get(LocalStorageService).getBoolean(SettingsService.SPACE_TO_SPIN);
        const quickSpin = Services.get(LocalStorageService).getBoolean(SettingsService.QUICK_SPIN);

        const settings: Partial<SettingsModel> = {};
        if (spaceToSpin !== null && spaceToSpin !== undefined) {
            settings.spaceToSpin = spaceToSpin;
        }
        if (displayCredits !== null && displayCredits !== undefined) {
            settings.displayCredits = displayCredits;
        }
        if (showIntro !== null && showIntro !== undefined) {
            settings.showIntro = showIntro;
        }
        if (quickSpin !== null && quickSpin !== undefined) {
            settings.quickSpin = quickSpin;
        }

        Model.write({
            settings
        });
    }

    protected save() {
        if (this.saveSettings) {
            Services.get(LocalStorageService).set(SettingsService.DISPLAY_CREDITS, this.settingsModel.displayCredits);
            Services.get(LocalStorageService).set(SettingsService.SHOW_INTRO, this.settingsModel.showIntro);
            Services.get(LocalStorageService).set(SettingsService.SPACE_TO_SPIN, this.settingsModel.spaceToSpin);
            Services.get(LocalStorageService).set(SettingsService.QUICK_SPIN, this.settingsModel.quickSpin);
        }
    }

    protected updateTurbo() {
        stateMachine.turbo = this.settingsModel.turboSpin;
    }
}
