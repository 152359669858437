import { Point } from "appworks/utils/geom/point";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SpinRecord } from "../spin-record";

export class WaysResult extends SymbolWinResult {

    /** For debugging purposes */
    public resultType: string = "WaysResult";

    public waysSymbols: string[][] = [];
    public ways: number;
    /**
     * Cash payout per way
     */
    public perWayPayout: number;
    /**
     * Symbol payout multiplier (as per paytable)
     */
    public multiplier: number;

    /**
     * Array of wild locations used in this ways result and the wild's multiplier for
     * games where wilds double as multipliers
     */
    public wilds?: Array<{
        gridPos: Point;
        multiplier?: number;
    }>;

    // TODO: V6 potentially move these convenience methods to supplements
    /**
     * Takes the WaysResult.positions and the SpinRecord.grid and generates
     * the waysSymbols.
     *
     * @param record The relevant spin record.
     */
    public createWaysSymbols(record: SpinRecord) {
        this.waysSymbols = [];
        this.positions.forEach((position: Point) => {
            if (!this.waysSymbols[position.x]) {
                this.waysSymbols[position.x] = [];
            }
            this.waysSymbols[position.x].push(record.grid[position.x][position.y]);
        });
    }

    /**
     * Takes the WaysResult.waysSymbols and flattens them into a format the rest
     * of the application can easily interpret. In the case where wilds and
     * regular symbols sit within the same reel. We use the more specific
     * symbol.
     *
     * @method flattenWaysSymbols
     */
    public flattenWaysSymbols() {
        this.symbols = [];
        this.waysSymbols.forEach((reel) => {
            if (reel) {
                const nonWild = reel.find((symbol) => !slotDefinition.getSymbolDefinition(symbol).wild);
                const wild = reel.find((symbol) => slotDefinition.getSymbolDefinition(symbol).wild);
                this.symbols.push(nonWild || wild);
            }
        });
    }

    /**
     * If symbol type doesn't come from the server, use this to look through the ways symbols and determine which symbol
     * the win is for (prioritising normal symbols over wilds)
     */
    public calculateSymbolTypeFromWaysSymbols() {
        let symbolId: string = "";

        this.waysSymbols.forEach((reel) => {
            if (reel) {
                const nonWild = reel.find((symbol) => !slotDefinition.getSymbolDefinition(symbol).wild);
                const wild = reel.find((symbol) => slotDefinition.getSymbolDefinition(symbol).wild);
                symbolId = nonWild || (symbolId === "" ? wild : symbolId);
            }
        });

        this.symbolType = symbolId;
    }
}
