import { Components } from "appworks/components/components";
import { gameLoop } from "appworks/core/game-loop";
import { gameState } from "appworks/model/game-state";
import { Model } from "appworks/model/model";
import { Services } from "appworks/services/services";
import { SettingsService } from "appworks/services/settings/settings-service";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { logger } from "appworks/utils/logger";
import { Timer } from "appworks/utils/timer";
import { SignalBinding } from "signals";
import { SkipSpinCommand } from "slotworks/commands/comms/skip-spin-command";
import { AlertComponent } from "slotworks/components/alert/alert-component";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { FreespinRecord } from "slotworks/model/gameplay/records/freespin-record";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SymbolDefinition } from "slotworks/model/symbol-definition";

export interface SpinConfig {
    /** Shows a "you have spun fast, would you like to enable turbo?" prompt */
    enableTurboPrompt: boolean;
    /** How many fast spins does the user need to do before the turbo prompt appears */
    turboPromptCount: number;
    /** Will throw if the landed symbols do not match the current record's grid.
        Disable this if a transformation will be applied to the reels after they stop to make them match the record grid */
    enableSafetyCheck: boolean;
    /** Forces the reels to jump to the record grid, in case something went wrong with the reel spin (backwards is the main culprite for spin errors) */
    enableSafetyForce: boolean;
    /** How long to disable skipping ubtil it's re-enabled (if applicable) */
    delaySkippingLength: number;
    /** How long to delay skipping after an anticipation starts */
    delayAnticipationSkippingLength: number;
    /** Change this if using this state with a secondary matrix component */
    matrixComponentType: { new (...args: any[]): AbstractMatrixComponent };
}
export class SpinState extends State {
    protected config: SpinConfig = {
        enableTurboPrompt: false,
        enableSafetyCheck: true,
        enableSafetyForce: false,
        turboPromptCount: 5,
        delaySkippingLength: 750,
        delayAnticipationSkippingLength: 750,
        matrixComponentType: AbstractMatrixComponent
    };

    protected skipsInARow: number = 0;

    protected skipping: boolean;
    protected delaySkipping: boolean;

    // This blocks skips (trigger by anticipation starting)
    protected anticipationBlockSkipping: boolean;
    // This flags when skips have already been blocked for anticipation, so it doesn't need to be blocked again
    protected anticipationBlockSkippingComplete: boolean;

    protected bonusCount: Map<number, number> = new Map<number, number>();
    protected symbolIdCount: Map<number, number> = new Map<number, number>();
    protected signals: SignalBinding[] = [];

    protected anticipationSignalBinding: SignalBinding;

    protected whenReelsStoppedMethod: () => void;

    constructor(config?: Partial<SpinConfig>) {
        super();

        if (config) {
            this.config = { ...this.config, ...config };
        }
    }

    public onEnter() {
        this.bonusCount = new Map<number, number>();
        this.symbolIdCount = new Map<number, number>();

        this.skipping = false;
        if (this.shouldDelaySkipping()) {
            this.startSkipDelay();
        }

        this.anticipationBlockSkipping = false;
        this.anticipationBlockSkippingComplete = false;

        if (Components.get(this.config.matrixComponentType).getTransition().canSkip()) {
            uiFlags.set(UIFlag.NO_SKIP, false);
        } else {
            uiFlags.set(UIFlag.NO_SKIP, true);
        }

        uiFlags.set(UIFlag.SPINNING, true); // TODO: this should be in request-spin

        this.attachLandSounds();

        const record: SpinRecord = gameState.getCurrentGame().getCurrentRecord() as SpinRecord;

        this.whenReelsStoppedMethod = () => this.complete();
        this.stopSpin(record).then(() => this.onReelsStopped());

        this.anticipationSignalBinding = Components.get(this.config.matrixComponentType).onAnticipationReel.add(() => {
            if (this.shouldDelayAnticipationSkipping() && !this.anticipationBlockSkippingComplete) {
                this.anticipationBlockSkipping = true;
                this.anticipationBlockSkippingComplete = true;
                Timer.setTimeout(() => {
                    this.anticipationBlockSkipping = false;
                }, this.config.delayAnticipationSkippingLength);
            }
            if (Components.get(this.config.matrixComponentType).getTransition().canSkip()) {
                uiFlags.set(UIFlag.NO_SKIP, false);
            } else {
                uiFlags.set(UIFlag.NO_SKIP, true);
            }
        });
    }

    public onSkip() {
        if (!this.delaySkipping && !this.anticipationBlockSkipping) {
            uiFlags.set(UIFlag.NO_SKIP, true);

            if (!this.skipping) {
                this.skipping = true;

                if (!Services.get(SettingsService).turboEnabled() && this.config.enableTurboPrompt) {
                    this.skipsInARow++;
                }

                if (this.skipsInARow > this.config.turboPromptCount) {
                    this.skipsInARow = 0;
                    gameLoop.setPaused("turbo", true);
                    Components.get(AlertComponent).confirm("trb.pro").then((turbo) => {
                        gameLoop.setPaused("turbo", false);
                        Model.write({ settings: { turboSpin: turbo } });
                    });
                }
            }

            if (Components.get(this.config.matrixComponentType).getTransition().canSkip()) {
                const record: SpinRecord = gameState.getCurrentGame().getCurrentRecord() as SpinRecord;
                this.whenReelsStoppedMethod = () => this.skip();
                this.skipSpin(record);
            }
        }
    }

    public onExit() {
        this.delaySkipping = false;

        if (this.anticipationSignalBinding) {
            this.anticipationSignalBinding.detach();
            this.anticipationSignalBinding = null;
        }

        if (!this.skipping) {
            this.skipsInARow = 0;
        }

        uiFlags.set(UIFlag.NO_SKIP, false);
        uiFlags.set(UIFlag.SPINNING, false);

        this.detachLandSounds();

        const record = gameState.getCurrentGame().getCurrentRecord() as SpinRecord;

        if (this.config.enableSafetyCheck || this.config.enableSafetyForce) {
            const brokenSymbols = [];
            for (const symbol of Components.get(this.config.matrixComponentType).getBaseGridSymbols()) {
                if (symbol.gridPosition.y >= 0 && symbol.gridPosition.y < record.grid.length) {
                    const expectedSymbol = record.grid[symbol.gridPosition.x][symbol.gridPosition.y];
                    const actualSymbol = symbol.symbolId;
                    if (expectedSymbol !== actualSymbol) {
                        const diff = "-" + expectedSymbol + " +" + actualSymbol + " " + symbol.gridPosition + " " + record.stops[symbol.gridPosition.x];
                        if (this.config.enableSafetyForce) {
                            logger.warn(diff);
                        } else {
                            logger.error(diff);
                        }
                        brokenSymbols.push(symbol);
                    }
                }
                if (brokenSymbols.length > 0) {
                    if (this.config.enableSafetyForce) {
                        Components.get(this.config.matrixComponentType).jumpToGrid(record.grid);
                    } else {
                        Components.get(AlertComponent).error("Internal Error, please refresh", true).execute();
                        logger.error(brokenSymbols);
                    }
                }
            }
        }
    }

    protected stopSpin(record: SpinRecord) {
        const settings = Model.read().settings;
        const quickSpin = settings.quickSpin || settings.turboSpin;

        return new Contract<void>((resolve) => {
            Components.get(this.config.matrixComponentType).stopTransition(record, quickSpin).then(() => {
                if (record && record instanceof FreespinRecord) {
                    Services.get(SoundService).event(SoundEvent.end_freespin);
                } else {
                    Services.get(SoundService).event(SoundEvent.end_spin);
                }
                resolve(null);
            });
        });
    }

    protected skipSpin(record: SpinRecord) {
        Components.get(this.config.matrixComponentType).skipTransition(record);
    }

    protected attachLandSounds() {
        this.signals = Components.get(this.config.matrixComponentType).getBaseGridSymbols().map((symbol) => {
            return symbol.landSignal.add(() => this.checkForSymbolSounds(symbol));
        });
    }

    protected detachLandSounds() {
        for (const binding of this.signals) {
            binding.detach();
        }
    }

    protected onReelsStopped() {
        this.whenReelsStoppedMethod();
    }

    protected shouldDelaySkipping() {
        return false;
    }

    protected shouldDelayAnticipationSkipping() {
        return this.config.delayAnticipationSkippingLength > 0;
    }

    protected startSkipDelay() {
        this.delaySkipping = true;
        Timer.setTimeout(() => {
            this.delaySkipping = false;
        }, this.config.delaySkippingLength);
    }

    protected checkForSymbolSounds(symbolComponent: SymbolSubcomponent) {
        const symbolDef = slotDefinition.getSymbolDefinition(symbolComponent.symbolId);

        if (symbolDef.isBonusSymbol()) {
            this.checkForBonusSymbolSounds(symbolDef, symbolComponent);
        } else {
            this.playSymbolSounds(symbolDef, symbolComponent);
        }
    }

    protected incrementCounter(counter: Map<number, number>, key: number) {
        if (!counter.has(key)) {
            counter.set(key, 0);
        }

        const currentCount = counter.get(key) + 1;
        counter.set(key, currentCount);

        return currentCount;
    }

    protected playSymbolSounds(symbolDef: SymbolDefinition, symbolComponent: SymbolSubcomponent) {
        const currentCount = this.incrementCounter(this.symbolIdCount, +symbolDef.id);
        Services.get(SoundService).event(SoundEvent.symbol_ID_land_COUNT, symbolDef.id, currentCount.toString());
    }

    protected checkForBonusSymbolSounds(symbolDef: SymbolDefinition, symbolComponent: SymbolSubcomponent) {
        const bonusSymbols = [symbolDef];

        // Check if wild counts towards bonuses, prioritise that sound
        if (symbolDef.wild) {
            slotDefinition.symbolDefinitions.forEach((bonusSymbolDef) => {
                if (bonusSymbolDef.includeWilds && bonusSymbolDef.bonusId) {
                    bonusSymbols.unshift(bonusSymbolDef);
                }
            });
        }

        for (const bonusSymbolDef of bonusSymbols) {
            const currentReel = symbolComponent.gridPosition.x;
            let currentCount = this.bonusCount.get(bonusSymbolDef.bonusId) || 0;

            // Symbols must be sequential in ways pays
            if (bonusSymbolDef.ways && currentCount < currentReel) {
                continue;
            }

            currentCount = this.incrementCounter(this.bonusCount, bonusSymbolDef.bonusId);

            let bonusTriggerPossible = false;

            if (currentCount >= bonusSymbolDef.matchesMin) {
                bonusTriggerPossible = true;
            } else {
                let remainingBonusReels = 0;

                for (let reel = currentReel; reel < slotDefinition.matrixGrid.length; reel++) {
                    if (symbolDef.reels && symbolDef.reels.indexOf(reel + 1) >= 0) {
                        remainingBonusReels++;
                    }
                }

                if (currentCount + remainingBonusReels * bonusSymbolDef.possibleMatchesPerReel >= bonusSymbolDef.matchesMin) {
                    bonusTriggerPossible = true;
                }
            }

            if (bonusTriggerPossible) {
                Services.get(SoundService).event(SoundEvent.bonus_SYMBOL_land, symbolDef.id);
                Services.get(SoundService).event(SoundEvent.bonus_SYMBOL_land_COUNT, symbolDef.id, currentCount.toString());
                Services.get(SoundService).event(SoundEvent.bonus_ID_land, bonusSymbolDef.bonusId.toString());
                Services.get(SoundService).event(SoundEvent.bonus_ID_land_COUNT, bonusSymbolDef.bonusId.toString(), currentCount.toString());
                Services.get(SoundService).event(SoundEvent.bonus_ID_land_reel_INDEX, bonusSymbolDef.bonusId.toString(), symbolComponent.gridPosition.x.toString());
                Services.get(SoundService).event(SoundEvent.bonus_ID_SYMBOL_land, bonusSymbolDef.bonusId.toString(), symbolDef.id);
                Services.get(SoundService).event(SoundEvent.bonus_ID_SYMBOL_land_COUNT, bonusSymbolDef.bonusId.toString(), symbolDef.id, currentCount.toString());
                return;
            }
        }
    }
}
