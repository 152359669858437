import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { EJSlotBonusResult } from "model/results/ej-slot-bonus-result";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { slotDefinition } from "slotworks/model/slot-definition";

export class EJExitSlotBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        new Sequence([
            () => Contract.getTimeoutContract(500),
            () => gameLayers.ScreenFade.setScene("black"),
            () => Contract.wrap(() => {
                uiFlags.set(UIFlag.FREE_SPINS, false);

                Components.get(MatrixComponent).change("default",
                    [1, 1, 1, 1, 1],
                    [0, 0, 0, 0, 0],
                    slotDefinition.reelsets.get("slingo")
                );

                gameLayers.MatrixBackground.defaultScene().execute();
                gameLayers.MatrixForeground.defaultScene().execute();
                gameLayers.SpinCounter.defaultScene().execute();
                gameLayers.SymbolAnimations.defaultScene().execute();
                gameLayers.Paylines.defaultScene().execute();
                gameLayers.Logo.defaultScene().execute();

                gameLayers.SlingoLadder.show();
                gameLayers.Footer.show();
                gameLayers.TicketMatrix.show();
                gameLayers.TicketMatrixBacking.show();
                gameLayers.BoardGame.show();

                Services.get(TransactionService).setTotalWin(gameState.getCurrentGame().getTotalWin());
            }),
            () => gameLayers.ScreenFade.defaultScene()
        ]).then(() => {
            gameState.getCurrentGame().getCurrentRecordParent()
                .getResultsOfType(EJSlotBonusResult)
                .find((res) => !res.played).played = true;

            this.complete();
        });
    }
}
