import { Components } from "appworks/components/components";
import { Filter } from "pixi.js";
import { LinesComponent } from "slotworks/components/lines/lines-component";
import { slotDefinition } from "slotworks/model/slot-definition";

/** @deprecated Move to class function */
export function SetAllLinesEffects(tint: number, filters: Filter[]) {
    for (let i = 1; i <= slotDefinition.lines.values.length; i++) {
        if (filters.length) {
            Components.get(LinesComponent).filter(i, filters);
        } else {
            Components.get(LinesComponent).tint(i, tint);
        }
    }
}
