import { Record } from "appworks/model/gameplay/records/record";
import { Result } from "appworks/model/gameplay/records/results/result";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { Point } from "appworks/utils/geom/point";
import { ScatterResult } from "slotworks/model/gameplay/records/results/scatter-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";

export class SlotScatterWinResultBuilder implements ResultBuilder {
    public filter(data: any, record?: Record, additionalData?: any): boolean {
        return data && additionalData === "scatter";
    }

    public build(record: SpinRecord, data: any, additionalData?: any): Result[] {
        const result = this.createResult();
        result.cashWon = Math.round(data.amount * 100);
        result.matches = data.occurrences;

        result.positions = [];
        for (let x = 0; x < data.payline.length; x++) {
            for (let y = 0; y < data.payline[x].length; y++) {
                if (data.payline[x][y]) {
                    result.positions.push(new Point(x, y));
                }
            }
        }

        result.calculateSymbolsFromGrid(record);
        result.calculateSymbolTypeFromSymbols();

        return [result];
    }

    // Sometimes non-scatter wins come back from the server as scatters, so games that do this can override this to change the result type
    protected createResult() {
        return new ScatterResult();
    }

}