import { Components } from "appworks/components/components";
import { PIXIElement } from "appworks/graphics/pixi/group";
import { gameState } from "appworks/model/game-state";
import { pulse } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import Logger = require("js-logger");
import { EJSlotBonusResult } from "model/results/ej-slot-bonus-result";
import { AdjustmentFilter } from "pixi-filters";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

// export function getWheelPrizeString(prize: {
//     multipliers?,
//     params?,
//     gameConfig?
// }): string {
//     let prizeString = "";

//     if (prize.multipliers) {
//         prizeString = "multiplier_" + prize.multipliers[0];
//     } else if (prize.params && prize.params["slots-luckyjoker"]) {
//         prizeString = "slots_" + prize.params["slots-luckyjoker"];
//     } else if (prize.gameConfig && prize.gameConfig.id.includes("chance-luckyjoker")) {
//         prizeString = "next_tier";
//     } else {
//         throw new Error("Unknown Wheel Bonus prize config: " + prize);
//     }

//     return prizeString;
// }

export function winSanity() {
    const record = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;

    let unplayedSlotWin = 0;
    record.getResultsOfType(EJSlotBonusResult).forEach((r) => unplayedSlotWin += r.played ? 0 : (r.data.totalWin * 100));

    const responseWin = Math.round(gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult).totalWin);
    const gameWin = Math.round(gameState.getCurrentGame().getTotalWin());

    const winDiscrepancy = Math.abs(responseWin - gameWin);

    if (winDiscrepancy !== unplayedSlotWin && record.state !== SlingoState.CHOOSE_CELL) {
        Logger.error(`Win Sanity Check Failed! Game: ${gameWin} Response: ${responseWin} Discrepancy: ${winDiscrepancy}`);
        Components.get(GMRAlertComponent).error("SERVER_ERROR", true).execute();
    } else {
        Logger.info("Win Sanity Check Passed: " + responseWin);
    }
}

export function flashWhite(element: PIXIElement, duration: number): Contract {
    const filter = new AdjustmentFilter();

    return new Parallel([
        () => pulse(element, { x: 1.3, y: 1.3 }, duration, Easing.Cubic.InOut),
        () => TweenContract.wrapTween(
            new Tween(filter)
                .to({ brightness: 2, saturation: 0.5 }, duration)
                .easing(Easing.Sinusoidal.InOut)
                .repeat(1)
                .yoyo(true)
                .onStart(() => { element.filters = [filter]; })
                .onComplete(() => { element.filters = null; })
        )
    ]);
}
