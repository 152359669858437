import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { EJSoundEvent } from "ej-sound-events";
import { winSanity } from "ej-utils";
import { gameLayers } from "game-layers";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";

export class EJExitWheelBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        new Sequence([
            () => gameLayers.ScreenFade.setScene("black"),
            () => gameLayers.WheelBonus.defaultScene(),
            () => Contract.wrap(() => {
                uiFlags.set(UIFlag.BONUS, false);

                Layers.setLayerOrder("default");

                Components.get(EJUICounterComponent).updateSlotSpinsCounter(
                    (gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult) as any).prizes
                );

                Services.get(SoundService).customEvent(EJSoundEvent.exit_bonus_wheel);
            }),
            () => gameLayers.ScreenFade.defaultScene()
        ]).then(() => {
            this.complete();
        });
    }

    public complete(): void {
        // Update total win, but exclude wins from bonuses that haven't been played yet
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const bonusResults = record.getResultsOfType(BonusResult);
        let pendingBonusWin = 0;
        bonusResults.forEach((result) => pendingBonusWin += result.played ? 0 : result.cashWon);
        Services.get(TransactionService).setTotalWin(gameplay.getTotalWin() - pendingBonusWin);
        // Services.get(TransactionService).setBalance(gameplay.balance);

        winSanity();
        super.complete();
    }
}
