import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { EJSlotBonusResult } from "model/results/ej-slot-bonus-result";

export class EJSlotBonusSpinsRemainingDecision extends Decision {
    public evaluate(): Exit {
        const parent = gameState.getCurrentGame().getCurrentRecordParent();
        const result = parent.getResultsOfType(EJSlotBonusResult).find((r) => !r.played);

        let previouslyUsed = 0;
        parent.getResultsOfType(EJSlotBonusResult).forEach((r) => {
            if (r.played) {
                previouslyUsed += result.data.standardSpins;
            }
        })
;
        const total = result.data.standardSpins;
        const used = parent.children.length - previouslyUsed;

        if (used >= total) {
            return Exit.False;
        }

        return Exit.True;
    }
}
