import { AbstractComponent } from "appworks/components/abstract-component";
import { ComponentFactory } from "appworks/components/factories/component-factory";
import { Point } from "appworks/utils/geom/point";
import { LinesComponent, LinesComponentConfig } from "../lines/lines-component";

export class LinesComponentFactory implements ComponentFactory {
    constructor(protected lineDefinitions: Point[][], protected config?: LinesComponentConfig) {
    }

    public build(): AbstractComponent {
        return new LinesComponent(this.lineDefinitions, this.config);
    }
}
