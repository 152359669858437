import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { ClientController } from "appworks/state-machine/ClientController";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { Timer } from "appworks/utils/timer";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoFooterComponent } from "slingo/components/slingo-footer-component";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoSpinsCounterComponent } from "slingo/components/slingo-spins-counter-component";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoRecoveryWinResult } from "slingo/model/results/slingo-recovery-win-result";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { slingoModel } from "slingo/model/slingo-model";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { RecoverState } from "slotworks/state-machine/standard/states/recover-state";

export class SlingoRecoveryState extends RecoverState {
    public onEnter(): void {
        super.onEnter();
        
        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord() as SlingoRecord;
        const progressResult = record.getFirstResultOfType(SlingoGameProgressResult);
        const ladderResult = record.getFirstResultOfType(SlingoLadderResult);
        const spinResult = record.getFirstResultOfType(SlingoReelSpinResult);

        const previousMatches = progressResult.matchedNumbers.filter(value => {
            return !spinResult?.matches.find(match => match.matchedValue === value);
        });

        const ticketMatrix = Components.get(SlingoTicketMatrixComponent);
        previousMatches.forEach(value => {
            ticketMatrix.dabSymbol(value, true).execute();
        });
        ticketMatrix.checkWinlines(false).execute();

        const freespinsThisSpin = spinResult?.symbols.filter(sym => sym === "FS").length || 0;
        if (progressResult.hasFirstPurchaseSpin) {
            Components.get(SlingoSpinsCounterComponent).setValue(
                progressResult.standardSpinsRemaining + progressResult.freeSpinsRemaining - freespinsThisSpin, false
            ).execute();
        } else {
            const rgSpinLimit = slingoModel.read().responsibleGaming.extraSpins.currentValue;

            Components.get(SlingoSpinsCounterComponent).setValue(
                ((rgSpinLimit ?? progressResult.purchaseSpins) - progressResult.purchaseSpinsUsed) + progressResult.freeSpinsRemaining - freespinsThisSpin, false
            ).execute();
        }

        Components.get(SlingoLadderComponent).stepToLevel(
            ladderResult.total - (ladderResult?.increase || 0), 0
        ).execute();

        Components.get(SlingoFooterComponent)?.showSpinStart(0, false);

        Services.get(TransactionService).setTotalWin(gameplay.getLatestResultOfType(SlingoRecoveryWinResult).cashWon);

        uiFlags.set(UIFlag.SPINNING, true);

        GamingRealms.setWrapperGameState(so.GameState.RECOVERY);
    }

    public complete(): void {
        Services.get(SoundService).customEvent(SlingoSoundEvent.play_game);

        // Short timeout to give the wrapper chance to pause the game, if showing a third-party recovery prompt (i.e. Sky)        
        Timer.setTimeout(() => {
            GamingRealms.setWrapperGameState(so.GameState.GAME_BUSY);
            Components.get(MatrixComponent).startTransition().then(() => super.complete());
        }, 500);
    }
}