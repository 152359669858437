import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { AdjustmentFilter } from "pixi-filters";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";

const portraitSparkleScalePerLevel: number[] = [
    0, 0.5, 0.5, 0.6, 0.6, 0.6, 0.7, 0.7, 0.7, 0.85, 1, 1, 1.2
];

export class EJLadderComponent extends SlingoLadderComponent {
    public stepToLevel(level: number, stepTime: number = 200): Contract<void> {
        const showSparkle = level > this.currentLevel && level > 1 && stepTime > 0;
        const lvlDiff = level - this.currentLevel;

        return new Parallel([
            () => super.stepToLevel(level, stepTime),
            () => new Contract((resolve) => {
                if (showSparkle) {
                    Timer.setTimeout(() => {
                        const animL = this.layer.getAnimatedSprite("ladder_celebration");
                        const animP = this.layer.getAnimatedSprite("ladder_celebration_portrait");

                        const ladderPosL = this.layer.getSprite("fill_" + level).landscape.getCenterPoint();
                        const ladderPosP = this.layer.getSprite("p_fill_" + level).portrait.getCenterPoint();

                        animL.anchor.set(0.5, 0.5);
                        animL.landscape.x = ladderPosL.x;
                        animL.landscape.y = ladderPosL.y;
                        animL.landscape.scale.set(level > 5 ? 1 : 0.8);

                        animP.anchor.set(0.5, 0);
                        animP.portrait.x = ladderPosP.x; // y won't change from PSD
                        animP.portrait.scale.set(portraitSparkleScalePerLevel[level]);

                        animL.visible = true;
                        animP.visible = true;
                        new Parallel([
                            () => animL.gotoAndPlayOnce(0),
                            () => animP.gotoAndPlayOnce(0)
                        ]).then(() => {
                            animL.visible = false;
                            animP.visible = false;
                            resolve();
                        });
                    }, stepTime * lvlDiff);
                } else {
                    resolve();
                }
            })
        ]);
    }

    protected setValue(level: number): Contract<void> {
        const disabled = { saturation: 1, brightness: 1, alpha: 1 };
        const greyedHighlight = { saturation: 0, brightness: 1, alpha: 1 };
        const greyedOut = { saturation: 0, brightness: 0.3, alpha: 0.9 };

        for (let i = 0; i <= 12; i++) {
            let graphic = this.layer.getText("level_" + i) || this.layer.getSprite("level_" + i);
            if (!graphic) { continue; }

            if (!graphic.filters || graphic.filters.length === 0) {
                graphic.filters = [new AdjustmentFilter()];
            }

            let state = disabled;
            if (i === level) { state = greyedHighlight; }
            else if (i < level) { state = greyedOut; }

            new Tween(graphic.filters[0])
                .to(state, 500)
                .easing(Easing.Sinusoidal.InOut)
                .start();
        }

        return super.setValue(level);
    }
}
