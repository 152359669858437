import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { State } from "appworks/state-machine/states/state";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { EJBonusWheelComponent } from "components/ej-bonus-wheel-component";
import { EJUICounterComponent } from "components/ej-ui-counter-component";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";

export class EJWheelBonusState extends State {
    public onEnter(cascadeSkip?: boolean): void {
        const wheelComponent = Components.get(EJBonusWheelComponent);
        const wheelResults = gameState.getCurrentGame().getCurrentRecord().getResultsOfType(EJBonusWheelResult);
        const result = wheelResults.find((res) => !res.played);
        const contracts: Array<() => Contract> = [];

        const isFirst = wheelResults.every((res) => res.played === false);
        result.played = true;
        if (!isFirst) {
            contracts.push(
                () => wheelComponent.reset(),
                () => Contract.getTimeoutContract(1000)
            );
        }

        contracts.push(() => Contract.wrap(() => {
            Components.get(EJUICounterComponent).updateWheelSpinsCounter();
        }));

        result.prizeSequence.forEach((prize, index) => {
            if (index > 0) {
                contracts.push(() => wheelComponent.switchWheel(index));
            }
            contracts.push(() => wheelComponent.spinToPrize(prize));
        });

        contracts.push(() => this.updateWin());
        contracts.push(() => Contract.getTimeoutContract(500));

        new Sequence(contracts).then(() => this.complete());
    }

    protected updateWin(): Contract {
        let cashWonSoFar = 0;
        let spinsWonSoFar = 0;
        const wheelResults = gameState.getCurrentGame().getCurrentRecord().getResultsOfType(EJBonusWheelResult);
        wheelResults.forEach((result) => {
            if (result.played) {
                cashWonSoFar += result.cashWon;
                spinsWonSoFar += result.slotSpinsWon;
            }
        });

        return Components.get(EJBonusWheelComponent).updateWinMeters(cashWonSoFar, spinsWonSoFar);
    }
}
