import { gameState } from "appworks/model/game-state";
import { ClientController } from "appworks/state-machine/ClientController";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";
import { EJBonusWheelResult } from "model/results/ej-bonus-wheel-result";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";

export class EJHasWheelBonusDecision extends Decision {
    public evaluate(): Exit {
        const record = gameState.getCurrentGame().getCurrentRecord();

        if (record.getResultsOfType(EJBonusWheelResult).find((result) => !result.played)) {
            const record = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
            if (record.state === SlingoState.COMPLETE) ClientController.getInstance().notifyFullHouse();
            return Exit.True;
        }

        return Exit.False;
    }
}
