import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { AnimationService } from "appworks/graphics/animation/animation-service";
import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Services } from "appworks/services/services";
import { arrayOfValues } from "appworks/utils/collection-utils";
import { Point } from "appworks/utils/geom/point";
import { Interpolation } from "appworks/utils/tween";
import { Filter, SimpleRope } from "pixi.js";
import { LinesComponent, LinesComponentConfig } from "slotworks/components/lines/lines-component";
import { SignalBinding } from "signals";

export interface RopeLinesComponentConfig extends LinesComponentConfig {
    // Interpolation function for rope
    interpolation?: (v: number[], k: number) => number;
    // Include an extra point for the center of the first and last symbol
    centerOnEachSymbol?: boolean;
    // Start and end position modifiers for having the rope start/end at a relative offset point
    startOffset?: Point;
    endOffset?: Point;
}

/**
 * Takes an animation called "line", which is straight, and generates "rope-warped" versions for each win line
 */
export class RopeLinesComponent extends LinesComponent {

    protected config: RopeLinesComponentConfig = {
        startOffset: new Point(0, 0),
        endOffset: new Point(0, 0)
    };

    protected ropes: SimpleRope[];
    protected tints: number[];
    protected filters: Filter[][];

    protected onCompleteBindings: SignalBinding[];

    constructor(protected lineDefinitions: Point[][], config?: RopeLinesComponentConfig) {
        super(lineDefinitions, config);

        if (config) {
            this.config = {
                ...this.config,
                ...config
            };
        }

        this.tints = [];
        this.filters = [];
        this.ropes = [];

        this.onCompleteBindings = []
    }

    public draw(lineDefinitions: Point[][]) {
        // Don't draw anything, draw lines JIT
    }

    public win(id: number, reverse: boolean = false) {
        this.lines[id - 1] = this.drawRopeLine(this.lineDefinitions[id - 1], id - 1);

        super.win(id, reverse);
    }

    public highlight(id: number, reverse: boolean = false) {
        this.highlightLines[id - 1] = this.drawRopeLine(this.lineDefinitions[id - 1], id - 1);

        super.highlight(id, reverse);
    }

    public hideAllLines() {
        this.onCompleteBindings.forEach((binding: SignalBinding) => {
            binding.detach();
        });
        this.onCompleteBindings = [];

        this.lines.forEach((line) => {
            if (line) { line.destroy(); }
        });
        this.lines = [];

        this.highlightLines.forEach((line) => {
            if (line) { line.destroy(); }
        });
        this.highlightLines = [];

        this.ropes.forEach((rope) => {
            if (rope) { rope.destroy(); }
        });
        this.ropes = [];

        super.hideAllLines();
    }

    public tint(id: number, color: number) {
        this.tints[id - 1] = color;

        const line = this.lines[id - 1];
        if (line && line instanceof Sprite) {
            line.tint = color;
        }
    }

    public filter(id: number, filters: Filter[]) {
        this.filters[id - 1] = filters;

        const line = this.lines[id - 1];
        if (line) {
            line.filters = filters;
        }
    }

    protected drawRopeLine(lineDefinition: Point[], index: number) {
        const layer = Layers.get("MatrixContent");
        const offsetStart = new Point(
            this.config.startOffset.x,
            (layer.getPosition("static").landscape.height * 0.5) + this.config.startOffset.y
        );
        const offsetMid = new Point(layer.getPosition("static").landscape.width * 0.5, layer.getPosition("static").landscape.height * 0.5);
        const offsetEnd = new Point(
            layer.getPosition("static").landscape.width + this.config.endOffset.x,
            (layer.getPosition("static").landscape.height * 0.5) + this.config.endOffset.y
        );

        const length = lineDefinition.length;

        const keyPoints = (arrayOfValues(length)).map((x: number) => {
            let offset = offsetMid;
            if (!this.config.centerOnEachSymbol) {
                if (x === 0) {
                    offset = offsetStart;
                } else if (x === length - 1) {
                    offset = offsetEnd;
                }
            }
            return layer.getPosition(`symbol_${x}_${lineDefinition[x].y}`).landscape.coordsToPoint().add(offset);
        });

        if (this.config.centerOnEachSymbol) {
            keyPoints.unshift(layer.getPosition(`symbol_${0}_${lineDefinition[0].y}`).landscape.coordsToPoint().add(offsetStart));
            keyPoints.push(layer.getPosition(`symbol_${length - 1}_${lineDefinition[length - 1].y}`).landscape.coordsToPoint().add(offsetEnd));
        }

        const resolution = 10;
        const xPoints = keyPoints.map((point) => point.x);
        const yPoints = keyPoints.map((point) => point.y);
        const width = (keyPoints[keyPoints.length - 1].x - keyPoints[0].x) / resolution;

        const points = [];

        const interpolation = this.config.interpolation || Interpolation.CatmullRom;

        for (let x = 0; x < width; x++) {
            const point = new Point(interpolation(xPoints, x / width), interpolation(yPoints, x / width));
            points.push(point);
        }

        const animConfig = Services.get(AnimationService).getConfig("line");
        const ropeFrames = [];
        Services.get(AnimationService).getFrames(["line"]).forEach((texture) => {
            const frameRope = new SimpleRope(texture, points as any);
            ropeFrames.push(frameRope);
        });

        const line = Services.get(AnimationService).generateSpriteFrameAnimation(ropeFrames);
        line.loop = animConfig.loop;
        line.animationSpeed = animConfig.fps / 60;
        line.loopFrame = animConfig.loopFrame || 0;

        line.portrait.scale.x = layer.bounds.portrait.width / layer.bounds.landscape.width;
        line.portrait.scale.y = layer.bounds.portrait.height / layer.bounds.landscape.height;

        this.onCompleteBindings.push(
            line.onComplete.addOnce(() => {
                const lineIndex = this.lines.indexOf(line);
                if (lineIndex > -1) {
                    this.lines.splice(lineIndex, 1);
                }
                line.destroy();

                ropeFrames.forEach((rope) => {
                    const ropeIndex = this.ropes.indexOf(rope);
                    if (ropeIndex > -1) {
                        this.ropes.splice(ropeIndex, 1);
                    }
                    rope.destroy();
                });
            }));

        this.ropes.push(...ropeFrames);
        this.lines[index] = line;
        this.highlightLines[index] = line;

        line.filters = this.filters[index];
        line.tint = this.tints[index];

        Layers.get("Paylines").add(line);

        return line;
    }
}
