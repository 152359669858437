import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Text } from "appworks/graphics/pixi/text";
import { gameState } from "appworks/model/game-state";
import { fadeIn, fadeOut } from "appworks/utils/animation/fade";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Easing } from "appworks/utils/tween";
import { SlingoCompletionReason } from "slingo/integration/slingo-schema";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";

export class EJBigWinTransition extends AbstractSceneTransition {
    private spine: SpineContainer;
    private value: Text;

    public in(): Contract<any> {
        this.spine = this.layer.getSpine("celebrations");
        this.value = this.layer.getText("value");

        this.value.alpha = 0;

        const animName = this.isFullHouse() ? "full_house_" : "big_win_";

        return new Sequence([
            () => new Parallel([
                () => this.spine.playOnce(animName + "in"),
                () => Contract.getDelayedContract(500, () => fadeIn(this.value, 1000, Easing.Sinusoidal.In))
            ]),
            () => Contract.wrap(() => this.spine.play(animName + "loop"))
        ]);
    }

    public out(): Contract<any> {
        const animName = this.isFullHouse() ? "full_house_" : "big_win_";

        return new Contract((resolve) => {
            this.spine.onComplete.addOnce(() => {
                new Parallel([
                    () => this.spine.playOnce(animName + "out"),
                    () => fadeOut(this.value, 500)]
                ).then(resolve);
            });
        });
    }

    protected isFullHouse(): boolean {
        const result = gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult);
        return result?.completionReason === SlingoCompletionReason.TICKET_MATCHED;
    }
}
