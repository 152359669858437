
import { State } from "appworks/state-machine/states/state";
import { Timer } from "appworks/utils/timer";

export class DelayState extends State {

    protected wait: number = 0;
    protected waitTimer?: number;
    protected skippable: boolean = false;

    constructor(wait: number, skippable: boolean = false) {
        super();
        this.wait = wait;
        this.skippable = skippable;
    }

    public onEnter() {
        if (this.wait > 0) {
            this.waitTimer = Timer.setTimeout(() => this.complete(), this.wait);
        } else {
            this.complete();
        }
    }

    public onSkip() {
        if (this.skippable && this.waitTimer) {
            Timer.clearTimeout(this.waitTimer);
            this.skip();
        }
    }
}
