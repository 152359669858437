import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { CenterPivot, PIXIElement } from "appworks/graphics/pixi/group";
import { gameState } from "appworks/model/game-state";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Timer } from "appworks/utils/timer";
import { winSanity } from "ej-utils";
import { gameLayers } from "game-layers";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoPurchaseSpinOrCollectState } from "slingo/states/slingo-purchase-spin-or-collect-state";
import { SlingoPotentialWin } from "slingo/util/slingo-get-potential-wins";

const extraLadderPrizes = {
    12: { slot: 5, wheel: 1 },
    10: { slot: 5, wheel: 1 },
    9: { slot: 4, wheel: 1 },
    8: { slot: 2, wheel: 1 },
    7: { slot: 1, wheel: 1 },
    6: { slot: 1, wheel: 1 },
    5: { slot: 1, wheel: 0 },
    4: { slot: 1, wheel: 0 }
};

export class EJPurchaseSpinOrCollectState extends SlingoPurchaseSpinOrCollectState {
    protected bonusValuesTimeout: number;

    public onEnter(cascadeSkip?: boolean): void {
        super.onEnter();

        const slotSpins = (gameState.getCurrentGame().getLatestResultOfType(SlingoGameProgressResult) as any).prizes;
        const collectButton = this.layer.getButton("collect");
        const slotCollectButton = this.layer.getButton("collect_slot");
        const slotSpinsValueText = this.layer.getText("slot_spins_value");

        if (collectButton) {
            gameLayers.SpinButton.hide();

            if (slotSpins && slotSpins > 0) {
                collectButton.setVisible(false);

                slotSpinsValueText.text = slotSpins;

                slotCollectButton.setLabelText(collectButton.getLabels()[0].text);
                slotCollectButton.on(ButtonEvent.CLICK, () => this.collect());
            } else {
                slotCollectButton.setVisible(false);
                this.layer.getSprite("slot_icon").visible = false;
                slotSpinsValueText.visible = false;
            }
        }

        winSanity();
    }

    protected purchaseSpin(): void {
        Timer.clearTimeout(this.bonusValuesTimeout);
        gameLayers.CollectBonusValues.jumpToScene("default");
        super.purchaseSpin();
    }

    protected collect(): void {
        Timer.clearTimeout(this.bonusValuesTimeout);
        gameLayers.CollectBonusValues.jumpToScene("default");
        super.collect();
    }

    protected disableButtons() {
        this.layer.getButton("collect_slot")?.setEnabled(false);
        this.layer.onSceneExit.addOnce(() => { gameLayers.SpinButton.show(); });
        return super.disableButtons();
    }

    protected animatePotentialWinDisplay() {
        return new Parallel([
            () => super.animatePotentialWinDisplay(),
            () => Contract.getDelayedContract(2500, () => gameLayers.CollectBonusValues.defaultScene())
        ]);
    }

    protected showPotentialWin(win: SlingoPotentialWin): void {
        super.showPotentialWin(win);

        Timer.clearTimeout(this.bonusValuesTimeout);
        gameLayers.CollectBonusValues.jumpToScene("default");

        const currentLevel = Components.get(SlingoLadderComponent).getCurrentLevel();
        let slotSpins = 0;
        let wheelSpins = 0;
        for (let i = currentLevel + 1; i <= win.lines; i++) {
            slotSpins += extraLadderPrizes[i]?.slot || 0;
            wheelSpins += extraLadderPrizes[i]?.wheel || 0;
        }

        const bodyText = this.layer.getText("potential_win_body");
        const bodyTextBonus = this.layer.getText("potential_win_body_bonus");

        bodyTextBonus.text = bodyText.text;
        bodyTextBonus.visible = slotSpins > 0;
        bodyText.visible = !bodyTextBonus.visible;

        this.bonusValuesTimeout = Timer.setTimeout(() => {
            const bonusValuesLayer = gameLayers.CollectBonusValues;
            gameLayers.CollectBonusValues.jumpToScene("default");
            
            if (slotSpins > 0 && wheelSpins > 0) {
                bonusValuesLayer.setScene("wheel_and_slot").execute();
                bonusValuesLayer.getText("pw_slot_value").text = slotSpins.toString();
                bonusValuesLayer.getText("pw_wheel_value").text = wheelSpins.toString();
            } else if (slotSpins > 0) {
                bonusValuesLayer.setScene("slot").execute();
                bonusValuesLayer.getText("pw_slot_value").text = slotSpins.toString();
            }
        }, 250);
    }

    protected getPotentialWinDisplayElements(): PIXIElement[] {
        return [
            ...super.getPotentialWinDisplayElements(),
            CenterPivot(this.layer.getText("potential_win_body_bonus"))
        ];
    }
}
