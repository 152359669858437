import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { unique } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { EJExpandingWildComponent } from "components/ej-expanding-wild-component";
import { gameLayers } from "game-layers";
import { SymbolWinResult } from "slotworks/model/gameplay/records/results/symbol-win-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { ShowWinLinesState } from "slotworks/state-machine/standard/states/show-win-lines-state";

export class EJSlotBonusShowWinsState extends ShowWinLinesState {
    public onExit(): void {
        super.onExit();
        gameLayers.Skip.defaultScene().execute();
        gameLayers.SpinButton.show();

        this.updateWin();
    }

    protected winSequence(): Contract<void> {
        gameLayers.SpinButton.hide();
        gameLayers.Skip.setScene("skip").then(() => {
            const skipBtn = gameLayers.Skip.getButton("skip");
            skipBtn.once(ButtonEvent.CLICK.getPIXIEventString(), () => {
                skipBtn.setEnabled(false);
                this.onSkip();
            });
        });

        return super.winSequence();
    }

    protected highlightWin(result: SymbolWinResult, color: number): Contract<any> {
        const reels = unique(result.positions.map((pos) => pos.x));

        return new Parallel([
            () => super.highlightWin(result, color),
            ...reels.map((reelIndex) => () => Components.get(EJExpandingWildComponent).winAnim(reelIndex))
        ]);
    }

    protected updateWin() {
        const gameplay = gameState.getCurrentGame();
        const text = gameLayers.BetBar.getText("slot_win_value");

        let win = 0;
        gameplay.getFamily(gameplay.getCurrentRecordParent()).forEach((record) => {
            if (record instanceof SpinRecord) { win += record.cashWon; }
        });

        if (win) {
            text.text = Services.get(CurrencyService).format(win);
        }
    }

    protected onDisplayComplete(): void {
        const skipBtn = gameLayers.Skip.getButton("skip");
        skipBtn.setEnabled(false);

        super.onDisplayComplete();
    }
}
