import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";

export class IsWinDecision extends Decision {
    public evaluate() {
        const isWin = gameState.currentRecordHasSymbolWinResults() || gameState.currentRecordHasFreespinWinResults() || gameState.currentRecordHasBonusWinResults();

        return isWin ? Exit.True : Exit.False;
    }
}
