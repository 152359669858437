import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { gameState } from "appworks/model/game-state";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { EJExpandingWildComponent } from "components/ej-expanding-wild-component";
import { gameLayers } from "game-layers";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { SpeedSkipState } from "slotworks/state-machine/standard/states/speed-skip-state";

export class EJSlotBonusExpandWildsState extends SpeedSkipState {
    protected allowSkipDelay: number = 0;
    protected skipSpeed: number = 3;

    public onEnter(cascadeSkip?: boolean): void {
        super.onEnter();

        const record = gameState.getCurrentGame().getCurrentRecord() as SpinRecord;
        const contracts: Array<() => Contract> = [];

        record.grid.forEach((reel: string[], reelIndex: number) => {
            const hasWild = reel.some((sym: string) => sym === "WI");
            if (hasWild) {
                contracts.push(() => Components.get(EJExpandingWildComponent).showWild(reelIndex));

                Components.get(MatrixComponent).getBaseGridSymbols().forEach((symbol) => {
                    if (symbol.gridPosition.x === reelIndex) {
                        symbol.setSymbol("blank");
                    }
                });
            }
        });

        if (contracts.length) {
            gameLayers.SpinButton.hide();
            gameLayers.Skip.setScene("skip").then(() => {
                const skipBtn = gameLayers.Skip.getButton("skip");
                skipBtn.once(ButtonEvent.CLICK.getPIXIEventString(), () => {
                    skipBtn.setEnabled(false);
                    this.onSkip();
                });
            });
        }

        new Parallel(contracts).then(() => this.complete());
    }

    public onExit(): void {
        gameLayers.Skip.defaultScene().execute();
        gameLayers.SpinButton.show();
        super.onExit();
    }

    public onSkip(): void {
        const skipBtn = gameLayers.Skip.getButton("skip");
        skipBtn.setEnabled(false);
        super.onSkip();
    }
}
