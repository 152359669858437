import { AbstractComponent } from "appworks/components/abstract-component";
import { Point } from "appworks/utils/geom/point";
import { RopeLinesComponent, RopeLinesComponentConfig } from "../lines/rope-lines-component";
import { LinesComponentFactory } from "./lines-component-factory";

export class RopeLinesComponentFactory extends LinesComponentFactory {

    constructor(protected lineDefinitions: Point[][], protected config?: RopeLinesComponentConfig) {
        super(lineDefinitions, config);
    }

    public build(): AbstractComponent {
        return new RopeLinesComponent(this.lineDefinitions, this.config);
    }
}
